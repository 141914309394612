export var MOCK_DATA = {
  e_sim: {
    local: [{
      "countryId": 8596,
      "callingCode": "93",
      "iso3": "AFG",
      "iso2": "AF",
      "name": "Afghanistan"
    }, {
      "countryId": 8624,
      "callingCode": "855",
      "iso3": "KHM",
      "iso2": "KH",
      "name": "Cambodia"
    }, {
      "countryId": 8625,
      "callingCode": "237",
      "iso3": "CMR",
      "iso2": "CM",
      "name": "Cameroon"
    }, {
      "countryId": 8626,
      "callingCode": "1",
      "iso3": "CAN",
      "iso2": "CA",
      "name": "Canada"
    }, {
      "countryId": 8627,
      "callingCode": "238",
      "iso3": "CPV",
      "iso2": "CV",
      "name": "Cape Verde"
    }, {
      "countryId": 8629,
      "callingCode": "1-345",
      "iso3": "CYM",
      "iso2": "KY",
      "name": "Cayman Islands"
    }, {
      "countryId": 8630,
      "callingCode": "236",
      "iso3": "CAF",
      "iso2": "CF",
      "name": "Central African Republic"
    }, {
      "countryId": 8631,
      "callingCode": "235",
      "iso3": "TCD",
      "iso2": "TD",
      "name": "Chad"
    }, {
      "countryId": 8632,
      "callingCode": "56",
      "iso3": "CHL",
      "iso2": "CL",
      "name": "Chile"
    }, {
      "countryId": 8633,
      "callingCode": "86",
      "iso3": "CHN",
      "iso2": "CN",
      "name": "China"
    }, {
      "countryId": 8722,
      "callingCode": "242",
      "iso3": "COG",
      "iso2": "CG",
      "name": "Congo"
    }, {
      "countryId": 8636,
      "callingCode": "506",
      "iso3": "CRI",
      "iso2": "CR",
      "name": "Costa Rica"
    }, {
      "countryId": 8675,
      "callingCode": "225",
      "iso3": "CIV",
      "iso2": "CI",
      "name": "Cote d Ivoire"
    }, {
      "countryId": 8638,
      "callingCode": "+599",
      "iso3": "CUW",
      "iso2": "CW",
      "name": "Curacao"
    }, {
      "countryId": 8641,
      "callingCode": "243",
      "iso3": "COD",
      "iso2": "CD",
      "name": "Democratic Republic of the Congo"
    }, {
      "countryId": 8643,
      "callingCode": "1-767",
      "iso3": "DMA",
      "iso2": "DM",
      "name": "Dominica"
    }, {
      "countryId": 8644,
      "callingCode": "593",
      "iso3": "ECU",
      "iso2": "EC",
      "name": "Ecuador"
    }, {
      "countryId": 8645,
      "callingCode": "20",
      "iso3": "EGY",
      "iso2": "EG",
      "name": "Egypt"
    }, {
      "countryId": 8646,
      "callingCode": "503",
      "iso3": "SLV",
      "iso2": "SV",
      "name": "El Salvador"
    }, {
      "countryId": 26162,
      "callingCode": "298",
      "iso3": "FRO",
      "iso2": "FO",
      "name": "Faroe Islands"
    }, {
      "countryId": 26163,
      "callingCode": "358",
      "iso3": "FIN",
      "iso2": "FI",
      "name": "Finland"
    }, {
      "countryId": 8653,
      "callingCode": "33",
      "iso3": "FRA",
      "iso2": "FR",
      "name": "France"
    }, {
      "countryId": 8655,
      "callingCode": "241",
      "iso3": "GAB",
      "iso2": "GA",
      "name": "Gabon"
    }, {
      "countryId": 8657,
      "callingCode": "995",
      "iso3": "GEO",
      "iso2": "GE",
      "name": "Georgia"
    }, {
      "countryId": 8658,
      "callingCode": "49",
      "iso3": "DEU",
      "iso2": "DE",
      "name": "Germany"
    }, {
      "countryId": 8659,
      "callingCode": "233",
      "iso3": "GHA",
      "iso2": "GH",
      "name": "Ghana"
    }, {
      "countryId": 26164,
      "callingCode": "350",
      "iso3": "GIB",
      "iso2": "GI",
      "name": "Gibraltar"
    }, {
      "countryId": 8660,
      "callingCode": "30",
      "iso3": "GRC",
      "iso2": "GR",
      "name": "Greece"
    }, {
      "countryId": 26165,
      "callingCode": "299",
      "iso3": "GRL",
      "iso2": "GL",
      "name": "Greenland"
    }, {
      "countryId": 8661,
      "callingCode": "1-473",
      "iso3": "GRD",
      "iso2": "GD",
      "name": "Grenada"
    }, {
      "countryId": 26166,
      "callingCode": "590",
      "iso3": "GLP",
      "iso2": "GP",
      "name": "Guadeloupe"
    }, {
      "countryId": 8662,
      "callingCode": "502",
      "iso3": "GTM",
      "iso2": "GT",
      "name": "Guatemala"
    }, {
      "countryId": 8663,
      "callingCode": "224",
      "iso3": "GIN",
      "iso2": "GN",
      "name": "Guinea"
    }, {
      "countryId": 8668,
      "callingCode": "852",
      "iso3": "HKG",
      "iso2": "HK",
      "name": "Hong Kong"
    }, {
      "countryId": 26167,
      "callingCode": "36",
      "iso3": "HUN",
      "iso2": "HU",
      "name": "Hungary"
    }, {
      "countryId": 26168,
      "callingCode": "354",
      "iso3": "ISL",
      "iso2": "IS",
      "name": "Iceland"
    }, {
      "countryId": 8672,
      "callingCode": "353",
      "iso3": "IRL",
      "iso2": "IE",
      "name": "Ireland"
    }, {
      "countryId": 8678,
      "callingCode": "962",
      "iso3": "JOR",
      "iso2": "JO",
      "name": "Jordan"
    }, {
      "countryId": 8679,
      "callingCode": "7",
      "iso3": "KAZ",
      "iso2": "KZ",
      "name": "Kazakhstan"
    }, {
      "countryId": 8681,
      "callingCode": "383",
      "iso3": "UNK",
      "iso2": "XK",
      "name": "Kosovo"
    }, {
      "countryId": 26170,
      "callingCode": "371",
      "iso3": "LVA",
      "iso2": "LV",
      "name": "Latvia"
    }, {
      "countryId": 8686,
      "callingCode": "266",
      "iso3": "LSO",
      "iso2": "LS",
      "name": "Lesotho"
    }, {
      "countryId": 8687,
      "callingCode": "231",
      "iso3": "LBR",
      "iso2": "LR",
      "name": "Liberia"
    }, {
      "countryId": 8688,
      "callingCode": "370",
      "iso3": "LTU",
      "iso2": "LT",
      "name": "Lithuania"
    }, {
      "countryId": 8689,
      "callingCode": "352",
      "iso3": "LUX",
      "iso2": "LU",
      "name": "Luxembourg"
    }, {
      "countryId": 26172,
      "callingCode": "853",
      "iso3": "MAC",
      "iso2": "MO",
      "name": "Macao"
    }, {
      "countryId": 26177,
      "callingCode": "389",
      "iso3": "MKD",
      "iso2": "MK",
      "name": "Macedonia"
    }, {
      "countryId": 8692,
      "callingCode": "60",
      "iso3": "MYS",
      "iso2": "MY",
      "name": "Malaysia"
    }, {
      "countryId": 26173,
      "callingCode": "356",
      "iso3": "MLT",
      "iso2": "MT",
      "name": "Malta"
    }, {
      "countryId": 8697,
      "callingCode": "52",
      "iso3": "MEX",
      "iso2": "MX",
      "name": "Mexico"
    }, {
      "countryId": 8698,
      "callingCode": "373",
      "iso3": "MDA",
      "iso2": "MD",
      "name": "Moldova"
    }, {
      "countryId": 26174,
      "callingCode": "976",
      "iso3": "MNG",
      "iso2": "MN",
      "name": "Mongolia"
    }, {
      "countryId": 26175,
      "callingCode": "382",
      "iso3": "MNE",
      "iso2": "ME",
      "name": "Montenegro"
    }, {
      "countryId": 8699,
      "callingCode": "1-664",
      "iso3": "MSR",
      "iso2": "MS",
      "name": "Montserrat"
    }, {
      "countryId": 8700,
      "callingCode": "212",
      "iso3": "MAR",
      "iso2": "MA",
      "name": "Morocco"
    }, {
      "countryId": 8701,
      "callingCode": "258",
      "iso3": "MOZ",
      "iso2": "MZ",
      "name": "Mozambique"
    }, {
      "countryId": 8702,
      "callingCode": "95",
      "iso3": "MMR",
      "iso2": "MM",
      "name": "Myanmar"
    }, {
      "countryId": 8703,
      "callingCode": "264",
      "iso3": "NAM",
      "iso2": "NA",
      "name": "Namibia"
    }, {
      "countryId": 8705,
      "callingCode": "977",
      "iso3": "NPL",
      "iso2": "NP",
      "name": "Nepal"
    }, {
      "countryId": 8706,
      "callingCode": "31",
      "iso3": "NLD",
      "iso2": "NL",
      "name": "Netherlands"
    }, {
      "countryId": 26176,
      "callingCode": "64",
      "iso3": "NZL",
      "iso2": "NZ",
      "name": "New Zealand"
    }, {
      "countryId": 8707,
      "callingCode": "505",
      "iso3": "NIC",
      "iso2": "NI",
      "name": "Nicaragua"
    }, {
      "countryId": 8708,
      "callingCode": "227",
      "iso3": "NER",
      "iso2": "NE",
      "name": "Niger"
    }, {
      "countryId": 8709,
      "callingCode": "234",
      "iso3": "NGA",
      "iso2": "NG",
      "name": "Nigeria"
    }, {
      "countryId": 26178,
      "callingCode": "47",
      "iso3": "NOR",
      "iso2": "NO",
      "name": "Norway"
    }, {
      "countryId": 8710,
      "callingCode": "968",
      "iso3": "OMN",
      "iso2": "OM",
      "name": "Oman"
    }, {
      "countryId": 8711,
      "callingCode": "92",
      "iso3": "PAK",
      "iso2": "PK",
      "name": "Pakistan"
    }, {
      "countryId": 8712,
      "callingCode": "970",
      "iso3": "PSE",
      "iso2": "PS",
      "name": "Palestine"
    }, {
      "countryId": 8713,
      "callingCode": "507",
      "iso3": "PAN",
      "iso2": "PA",
      "name": "Panama"
    }, {
      "countryId": 8714,
      "callingCode": "675",
      "iso3": "PNG",
      "iso2": "PG",
      "name": "Papua New Guinea"
    }, {
      "countryId": 8715,
      "callingCode": "595",
      "iso3": "PRY",
      "iso2": "PY",
      "name": "Paraguay"
    }, {
      "countryId": 8716,
      "callingCode": "51",
      "iso3": "PER",
      "iso2": "PE",
      "name": "Peru"
    }, {
      "countryId": 8717,
      "callingCode": "63",
      "iso3": "PHL",
      "iso2": "PH",
      "name": "Philippines"
    }, {
      "countryId": 8718,
      "callingCode": "48",
      "iso3": "POL",
      "iso2": "PL",
      "name": "Poland"
    }, {
      "countryId": 8719,
      "callingCode": "351",
      "iso3": "PRT",
      "iso2": "PT",
      "name": "Portugal"
    }, {
      "countryId": 8720,
      "callingCode": "1",
      "iso3": "PRI",
      "iso2": "PR",
      "name": "Puerto Rico"
    }, {
      "countryId": 8721,
      "callingCode": "974",
      "iso3": "QAT",
      "iso2": "QA",
      "name": "Qatar"
    }, {
      "countryId": 8723,
      "callingCode": "262",
      "iso3": "REU",
      "iso2": "RE",
      "name": "Reunion"
    }, {
      "countryId": 8724,
      "callingCode": "40",
      "iso3": "ROU",
      "iso2": "RO",
      "name": "Romania"
    }, {
      "countryId": 8726,
      "callingCode": "250",
      "iso3": "RWA",
      "iso2": "RW",
      "name": "Rwanda"
    }, {
      "countryId": 8727,
      "callingCode": "1-869",
      "iso3": "KNA",
      "iso2": "KN",
      "name": "Saint Kitts and Nevis"
    }, {
      "countryId": 8728,
      "callingCode": "1-758",
      "iso3": "LCA",
      "iso2": "LC",
      "name": "Saint Lucia"
    }, {
      "countryId": 8730,
      "callingCode": "1-784",
      "iso3": "VCT",
      "iso2": "VC",
      "name": "Saint Vincent and the Grenadines"
    }, {
      "countryId": 8731,
      "callingCode": "685",
      "iso3": "WSM",
      "iso2": "WS",
      "name": "Samoa"
    }, {
      "countryId": 8732,
      "callingCode": "966",
      "iso3": "SAU",
      "iso2": "SA",
      "name": "Saudi Arabia"
    }, {
      "countryId": 8733,
      "callingCode": "221",
      "iso3": "SEN",
      "iso2": "SN",
      "name": "Senegal"
    }, {
      "countryId": 26179,
      "callingCode": "381 p",
      "iso3": "SRB",
      "iso2": "RS",
      "name": "Serbia"
    }, {
      "countryId": 8734,
      "callingCode": "248",
      "iso3": "SYC",
      "iso2": "SC",
      "name": "Seychelles"
    }, {
      "countryId": 8735,
      "callingCode": "232",
      "iso3": "SLE",
      "iso2": "SL",
      "name": "Sierra Leone"
    }, {
      "countryId": 8736,
      "callingCode": "65",
      "iso3": "SGP",
      "iso2": "SG",
      "name": "Singapore"
    }, {
      "countryId": 8738,
      "callingCode": "421",
      "iso3": "SVK",
      "iso2": "SK",
      "name": "Slovakia"
    }, {
      "countryId": 26180,
      "callingCode": "386",
      "iso3": "SVN",
      "iso2": "SI",
      "name": "Slovenia"
    }, {
      "countryId": 26181,
      "callingCode": "677",
      "iso3": "SLB",
      "iso2": "SB",
      "name": "Solomon Islands"
    }, {
      "countryId": 8740,
      "callingCode": "27",
      "iso3": "ZAF",
      "iso2": "ZA",
      "name": "South Africa"
    }, {
      "countryId": 26182,
      "callingCode": "82",
      "iso3": "KOR",
      "iso2": "KR",
      "name": "South Korea"
    }, {
      "countryId": 8741,
      "callingCode": "34",
      "iso3": "ESP",
      "iso2": "ES",
      "name": "Spain"
    }, {
      "countryId": 8742,
      "callingCode": "94",
      "iso3": "LKA",
      "iso2": "LK",
      "name": "Sri Lanka"
    }, {
      "countryId": 8743,
      "callingCode": "249",
      "iso3": "SDN",
      "iso2": "SD",
      "name": "Sudan"
    }, {
      "countryId": 8744,
      "callingCode": "597",
      "iso3": "SUR",
      "iso2": "SR",
      "name": "Suriname"
    }, {
      "countryId": 8745,
      "callingCode": "46",
      "iso3": "SWE",
      "iso2": "SE",
      "name": "Sweden"
    }, {
      "countryId": 8746,
      "callingCode": "41",
      "iso3": "CHE",
      "iso2": "CH",
      "name": "Switzerland"
    }, {
      "countryId": 26183,
      "callingCode": "886",
      "iso3": "TWN",
      "iso2": "TW",
      "name": "Taiwan"
    }, {
      "countryId": 8747,
      "callingCode": "992",
      "iso3": "TJK",
      "iso2": "TJ",
      "name": "Tajikistan"
    }, {
      "countryId": 8748,
      "callingCode": "255",
      "iso3": "TZA",
      "iso2": "TZ",
      "name": "Tanzania"
    }, {
      "countryId": 8749,
      "callingCode": "66",
      "iso3": "THA",
      "iso2": "TH",
      "name": "Thailand"
    }, {
      "countryId": 8751,
      "callingCode": "676",
      "iso3": "TON",
      "iso2": "TO",
      "name": "Tonga"
    }, {
      "countryId": 8752,
      "callingCode": "1-868",
      "iso3": "TTO",
      "iso2": "TT",
      "name": "Trinidad and Tobago"
    }, {
      "countryId": 8753,
      "callingCode": "216",
      "iso3": "TUN",
      "iso2": "TN",
      "name": "Tunisia"
    }, {
      "countryId": 8755,
      "callingCode": "1-649",
      "iso3": "TCA",
      "iso2": "TC",
      "name": "Turks and Caicos Islands"
    }, {
      "countryId": 8756,
      "callingCode": "256",
      "iso3": "UGA",
      "iso2": "UG",
      "name": "Uganda"
    }, {
      "countryId": 8758,
      "callingCode": "971",
      "iso3": "ARE",
      "iso2": "AE",
      "name": "United Arab Emirates"
    }, {
      "countryId": 8759,
      "callingCode": "44",
      "iso3": "GBR",
      "iso2": "GB",
      "name": "United Kingdom"
    }, {
      "countryId": 8760,
      "callingCode": "1",
      "iso3": "USA",
      "iso2": "US",
      "name": "United States"
    }, {
      "countryId": 8761,
      "callingCode": "598",
      "iso3": "URY",
      "iso2": "UY",
      "name": "Uruguay"
    }, {
      "countryId": 8762,
      "callingCode": "998",
      "iso3": "UZB",
      "iso2": "UZ",
      "name": "Uzbekistan"
    }, {
      "countryId": 8763,
      "callingCode": "678",
      "iso3": "VUT",
      "iso2": "VU",
      "name": "Vanuatu"
    }, {
      "countryId": 8765,
      "callingCode": "84",
      "iso3": "VNM",
      "iso2": "VN",
      "name": "Vietnam"
    }],
    regional: [{
      "countryId": 27569,
      "callingCode": "1",
      "iso3": "EXX",
      "iso2": "EX",
      "name": "Europe"
    }],
    global: [{
      "country": {
        "iso2": "GX",
        "name": "Global"
      },
      "transactionRequiredFields": [],
      "description": "1 GB 7 days Global",
      "destinationAmount": 8,
      "destinationAmountIncrement": null,
      "destinationAmountMax": null,
      "destinationAmountMin": null,
      "retailAmount": 10.29,
      "retailAmountUnit": "CAD",
      "destinationUnit": "USD",
      "id": 50698,
      "name": "1 GB 7 days Global",
      "type": "FIXED_VALUE_PIN_PURCHASE",
      "validityQuantity": 7,
      "validityUnit": "DAY",
      "pinTerms": null,
      "pinValidityUnit": "DAY",
      "pinValidityQuantity": 365,
      "pinUsageInfo": ["After buying, you'll get a QR Code. Scan it to install the eSIM profile. When you arrive at your destination, remember to turn on data roaming in your device settings."],
      "paymentPostingPeriodQuantity": null,
      "paymentPostingPeriodType": null,
      "paymentPostingPeriodUnit": null,
      "operator": {
        "imageUrl": "https://operator-logo.dtone.com/logo-4918-3.png",
        "label": "eSIM Global",
        "name": "eSIM Global",
        "regions": [{
          "name": "Afghanistan",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/AF.svg"
        }, {
          "name": "Albania",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/AL.svg"
        }, {
          "name": "Algeria",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/DZ.svg"
        }, {
          "name": "Argentina",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/AR.svg"
        }, {
          "name": "Armenia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/AM.svg"
        }, {
          "name": "Australia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/AU.svg"
        }, {
          "name": "Austria",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/AT.svg"
        }, {
          "name": "Azerbaijan",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/AZ.svg"
        }, {
          "name": "Bangladesh",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/BD.svg"
        }, {
          "name": "Belarus",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/BY.svg"
        }, {
          "name": "Belgium",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/BE.svg"
        }, {
          "name": "Bosnia and Herzegovina",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/BA.svg"
        }, {
          "name": "Brazil",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/BR.svg"
        }, {
          "name": "Bulgaria",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/BG.svg"
        }, {
          "name": "Burkina Faso",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/BF.svg"
        }, {
          "name": "Canada",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/CA.svg"
        }, {
          "name": "Chad",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/TD.svg"
        }, {
          "name": "Chile",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/CL.svg"
        }, {
          "name": "China",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/CN.svg"
        }, {
          "name": "Colombia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/CO.svg"
        }, {
          "name": "Costa Rica",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/CR.svg"
        }, {
          "name": "Croatia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/HR.svg"
        }, {
          "name": "Cyprus",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/CY.svg"
        }, {
          "name": "Czech Republic",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/CZ.svg"
        }, {
          "name": "Denmark",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/DK.svg"
        }, {
          "name": "Dominican Republic",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/DO.svg"
        }, {
          "name": "Democratic Republic of the Congo",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/CD.svg"
        }, {
          "name": "Ecuador",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/EC.svg"
        }, {
          "name": "Egypt",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/EG.svg"
        }, {
          "name": "El Salvador",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/SV.svg"
        }, {
          "name": "Estonia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/EE.svg"
        }, {
          "name": "Swaziland",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/SZ.svg"
        }, {
          "name": "Faroe Islands",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/FO.svg"
        }, {
          "name": "Finland",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/FI.svg"
        }, {
          "name": "France",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/FR.svg"
        }, {
          "name": "Gabon",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/GA.svg"
        }, {
          "name": "Georgia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/GE.svg"
        }, {
          "name": "Germany",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/DE.svg"
        }, {
          "name": "Ghana",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/GH.svg"
        }, {
          "name": "Gibraltar",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/GI.svg"
        }, {
          "name": "Greece",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/GR.svg"
        }, {
          "name": "Guadeloupe",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/GP.svg"
        }, {
          "name": "Guatemala",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/GT.svg"
        }, {
          "name": "Honduras",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/HN.svg"
        }, {
          "name": "Hong Kong",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/HK.svg"
        }, {
          "name": "Hungary",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/HU.svg"
        }, {
          "name": "Iceland",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/IS.svg"
        }, {
          "name": "India",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/IN.svg"
        }, {
          "name": "Indonesia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/ID.svg"
        }, {
          "name": "Iran",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/IR.svg"
        }, {
          "name": "Ireland",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/IE.svg"
        }, {
          "name": "Israel",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/IL.svg"
        }, {
          "name": "Italy",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/IT.svg"
        }, {
          "name": "Japan",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/JP.svg"
        }, {
          "name": "Jordan",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/JO.svg"
        }, {
          "name": "Kazakhstan",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/KZ.svg"
        }, {
          "name": "Kenya",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/KE.svg"
        }, {
          "name": "Kuwait",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/KW.svg"
        }, {
          "name": "Kyrgyzstan",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/KG.svg"
        }, {
          "name": "Laos",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/LA.svg"
        }, {
          "name": "Latvia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/LV.svg"
        }, {
          "name": "Lesotho",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/LS.svg"
        }, {
          "name": "Liechtenstein",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/LI.svg"
        }, {
          "name": "Lithuania",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/LT.svg"
        }, {
          "name": "Luxembourg",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/LU.svg"
        }, {
          "name": "Macao",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/MO.svg"
        }, {
          "name": "Madagascar",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/MG.svg"
        }, {
          "name": "Malawi",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/MW.svg"
        }, {
          "name": "Malaysia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/MY.svg"
        }, {
          "name": "Malta",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/MT.svg"
        }, {
          "name": "Mexico",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/MX.svg"
        }, {
          "name": "Moldova",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/MD.svg"
        }, {
          "name": "Montenegro",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/ME.svg"
        }, {
          "name": "Morocco",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/MA.svg"
        }, {
          "name": "Mozambique",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/MZ.svg"
        }, {
          "name": "Myanmar",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/MM.svg"
        }, {
          "name": "Netherlands",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/NL.svg"
        }, {
          "name": "New Zealand",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/NZ.svg"
        }, {
          "name": "Nicaragua",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/NI.svg"
        }, {
          "name": "Niger",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/NE.svg"
        }, {
          "name": "Nigeria",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/NG.svg"
        }, {
          "name": "Macedonia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/MK.svg"
        }, {
          "name": "Norway",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/NO.svg"
        }, {
          "name": "Pakistan",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/PK.svg"
        }, {
          "name": "Palestine",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/PS.svg"
        }, {
          "name": "Panama",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/PA.svg"
        }, {
          "name": "Paraguay",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/PY.svg"
        }, {
          "name": "Peru",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/PE.svg"
        }, {
          "name": "Philippines",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/PH.svg"
        }, {
          "name": "Poland",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/PL.svg"
        }, {
          "name": "Portugal",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/PT.svg"
        }, {
          "name": "Puerto Rico",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/PR.svg"
        }, {
          "name": "Qatar",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/QA.svg"
        }, {
          "name": "Congo",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/CG.svg"
        }, {
          "name": "Reunion",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/RE.svg"
        }, {
          "name": "Romania",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/RO.svg"
        }, {
          "name": "Rwanda",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/RW.svg"
        }, {
          "name": "Senegal",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/SN.svg"
        }, {
          "name": "Serbia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/RS.svg"
        }, {
          "name": "Singapore",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/SG.svg"
        }, {
          "name": "Slovakia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/SK.svg"
        }, {
          "name": "Slovenia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/SI.svg"
        }, {
          "name": "South Africa",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/ZA.svg"
        }, {
          "name": "South Korea",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/KR.svg"
        }, {
          "name": "Spain",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/ES.svg"
        }, {
          "name": "Sri Lanka",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/LK.svg"
        }, {
          "name": "Sudan",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/SD.svg"
        }, {
          "name": "Sweden",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/SE.svg"
        }, {
          "name": "Switzerland",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/CH.svg"
        }, {
          "name": "Taiwan",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/TW.svg"
        }, {
          "name": "Tajikistan",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/TJ.svg"
        }, {
          "name": "Tanzania",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/TZ.svg"
        }, {
          "name": "Thailand",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/TH.svg"
        }, {
          "name": "Tunisia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/TN.svg"
        }, {
          "name": "Turkey",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/TR.svg"
        }, {
          "name": "Uganda",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/UG.svg"
        }, {
          "name": "Ukraine",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/UA.svg"
        }, {
          "name": "United Arab Emirates",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/AE.svg"
        }, {
          "name": "United Kingdom",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/GB.svg"
        }, {
          "name": "United States",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/US.svg"
        }, {
          "name": "Uruguay",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/UY.svg"
        }, {
          "name": "Uzbekistan",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/UZ.svg"
        }, {
          "name": "Vietnam",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/VN.svg"
        }, {
          "name": "Zambia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/ZM.svg"
        }, {
          "name": "Zimbabwe",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/ZW.svg"
        }]
      }
    }, {
      "country": {
        "iso2": "GX",
        "name": "Global"
      },
      "transactionRequiredFields": [],
      "description": "10 GB 30 days Global",
      "destinationAmount": 50,
      "destinationAmountIncrement": null,
      "destinationAmountMax": null,
      "destinationAmountMin": null,
      "retailAmount": 64.31,
      "retailAmountUnit": "CAD",
      "destinationUnit": "USD",
      "id": 50699,
      "name": "10 GB 30 days Global",
      "type": "FIXED_VALUE_PIN_PURCHASE",
      "validityQuantity": 30,
      "validityUnit": "DAY",
      "pinTerms": null,
      "pinValidityUnit": "DAY",
      "pinValidityQuantity": 365,
      "pinUsageInfo": ["After buying, you'll get a QR Code. Scan it to install the eSIM profile. When you arrive at your destination, remember to turn on data roaming in your device settings."],
      "paymentPostingPeriodQuantity": null,
      "paymentPostingPeriodType": null,
      "paymentPostingPeriodUnit": null,
      "operator": {
        "imageUrl": "https://operator-logo.dtone.com/logo-4918-3.png",
        "label": "eSIM Global",
        "name": "eSIM Global",
        "regions": [{
          "name": "Afghanistan",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/AF.svg"
        }, {
          "name": "Albania",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/AL.svg"
        }, {
          "name": "Algeria",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/DZ.svg"
        }, {
          "name": "Argentina",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/AR.svg"
        }, {
          "name": "Armenia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/AM.svg"
        }, {
          "name": "Australia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/AU.svg"
        }, {
          "name": "Austria",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/AT.svg"
        }, {
          "name": "Azerbaijan",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/AZ.svg"
        }, {
          "name": "Bangladesh",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/BD.svg"
        }, {
          "name": "Belarus",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/BY.svg"
        }, {
          "name": "Belgium",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/BE.svg"
        }, {
          "name": "Bosnia and Herzegovina",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/BA.svg"
        }, {
          "name": "Brazil",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/BR.svg"
        }, {
          "name": "Bulgaria",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/BG.svg"
        }, {
          "name": "Burkina Faso",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/BF.svg"
        }, {
          "name": "Canada",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/CA.svg"
        }, {
          "name": "Chad",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/TD.svg"
        }, {
          "name": "Chile",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/CL.svg"
        }, {
          "name": "China",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/CN.svg"
        }, {
          "name": "Colombia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/CO.svg"
        }, {
          "name": "Costa Rica",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/CR.svg"
        }, {
          "name": "Croatia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/HR.svg"
        }, {
          "name": "Cyprus",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/CY.svg"
        }, {
          "name": "Czech Republic",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/CZ.svg"
        }, {
          "name": "Denmark",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/DK.svg"
        }, {
          "name": "Dominican Republic",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/DO.svg"
        }, {
          "name": "Democratic Republic of the Congo",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/CD.svg"
        }, {
          "name": "Ecuador",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/EC.svg"
        }, {
          "name": "Egypt",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/EG.svg"
        }, {
          "name": "El Salvador",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/SV.svg"
        }, {
          "name": "Estonia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/EE.svg"
        }, {
          "name": "Swaziland",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/SZ.svg"
        }, {
          "name": "Faroe Islands",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/FO.svg"
        }, {
          "name": "Finland",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/FI.svg"
        }, {
          "name": "France",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/FR.svg"
        }, {
          "name": "Gabon",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/GA.svg"
        }, {
          "name": "Georgia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/GE.svg"
        }, {
          "name": "Germany",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/DE.svg"
        }, {
          "name": "Ghana",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/GH.svg"
        }, {
          "name": "Gibraltar",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/GI.svg"
        }, {
          "name": "Greece",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/GR.svg"
        }, {
          "name": "Guadeloupe",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/GP.svg"
        }, {
          "name": "Guatemala",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/GT.svg"
        }, {
          "name": "Honduras",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/HN.svg"
        }, {
          "name": "Hong Kong",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/HK.svg"
        }, {
          "name": "Hungary",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/HU.svg"
        }, {
          "name": "Iceland",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/IS.svg"
        }, {
          "name": "India",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/IN.svg"
        }, {
          "name": "Indonesia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/ID.svg"
        }, {
          "name": "Iran",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/IR.svg"
        }, {
          "name": "Ireland",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/IE.svg"
        }, {
          "name": "Israel",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/IL.svg"
        }, {
          "name": "Italy",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/IT.svg"
        }, {
          "name": "Japan",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/JP.svg"
        }, {
          "name": "Jordan",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/JO.svg"
        }, {
          "name": "Kazakhstan",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/KZ.svg"
        }, {
          "name": "Kenya",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/KE.svg"
        }, {
          "name": "Kuwait",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/KW.svg"
        }, {
          "name": "Kyrgyzstan",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/KG.svg"
        }, {
          "name": "Laos",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/LA.svg"
        }, {
          "name": "Latvia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/LV.svg"
        }, {
          "name": "Lesotho",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/LS.svg"
        }, {
          "name": "Liechtenstein",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/LI.svg"
        }, {
          "name": "Lithuania",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/LT.svg"
        }, {
          "name": "Luxembourg",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/LU.svg"
        }, {
          "name": "Macao",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/MO.svg"
        }, {
          "name": "Madagascar",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/MG.svg"
        }, {
          "name": "Malawi",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/MW.svg"
        }, {
          "name": "Malaysia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/MY.svg"
        }, {
          "name": "Malta",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/MT.svg"
        }, {
          "name": "Mexico",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/MX.svg"
        }, {
          "name": "Moldova",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/MD.svg"
        }, {
          "name": "Montenegro",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/ME.svg"
        }, {
          "name": "Morocco",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/MA.svg"
        }, {
          "name": "Mozambique",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/MZ.svg"
        }, {
          "name": "Myanmar",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/MM.svg"
        }, {
          "name": "Netherlands",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/NL.svg"
        }, {
          "name": "New Zealand",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/NZ.svg"
        }, {
          "name": "Nicaragua",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/NI.svg"
        }, {
          "name": "Niger",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/NE.svg"
        }, {
          "name": "Nigeria",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/NG.svg"
        }, {
          "name": "Macedonia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/MK.svg"
        }, {
          "name": "Norway",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/NO.svg"
        }, {
          "name": "Pakistan",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/PK.svg"
        }, {
          "name": "Palestine",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/PS.svg"
        }, {
          "name": "Panama",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/PA.svg"
        }, {
          "name": "Paraguay",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/PY.svg"
        }, {
          "name": "Peru",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/PE.svg"
        }, {
          "name": "Philippines",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/PH.svg"
        }, {
          "name": "Poland",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/PL.svg"
        }, {
          "name": "Portugal",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/PT.svg"
        }, {
          "name": "Puerto Rico",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/PR.svg"
        }, {
          "name": "Qatar",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/QA.svg"
        }, {
          "name": "Congo",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/CG.svg"
        }, {
          "name": "Reunion",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/RE.svg"
        }, {
          "name": "Romania",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/RO.svg"
        }, {
          "name": "Rwanda",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/RW.svg"
        }, {
          "name": "Senegal",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/SN.svg"
        }, {
          "name": "Serbia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/RS.svg"
        }, {
          "name": "Singapore",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/SG.svg"
        }, {
          "name": "Slovakia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/SK.svg"
        }, {
          "name": "Slovenia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/SI.svg"
        }, {
          "name": "South Africa",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/ZA.svg"
        }, {
          "name": "South Korea",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/KR.svg"
        }, {
          "name": "Spain",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/ES.svg"
        }, {
          "name": "Sri Lanka",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/LK.svg"
        }, {
          "name": "Sudan",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/SD.svg"
        }, {
          "name": "Sweden",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/SE.svg"
        }, {
          "name": "Switzerland",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/CH.svg"
        }, {
          "name": "Taiwan",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/TW.svg"
        }, {
          "name": "Tajikistan",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/TJ.svg"
        }, {
          "name": "Tanzania",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/TZ.svg"
        }, {
          "name": "Thailand",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/TH.svg"
        }, {
          "name": "Tunisia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/TN.svg"
        }, {
          "name": "Turkey",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/TR.svg"
        }, {
          "name": "Uganda",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/UG.svg"
        }, {
          "name": "Ukraine",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/UA.svg"
        }, {
          "name": "United Arab Emirates",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/AE.svg"
        }, {
          "name": "United Kingdom",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/GB.svg"
        }, {
          "name": "United States",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/US.svg"
        }, {
          "name": "Uruguay",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/UY.svg"
        }, {
          "name": "Uzbekistan",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/UZ.svg"
        }, {
          "name": "Vietnam",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/VN.svg"
        }, {
          "name": "Zambia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/ZM.svg"
        }, {
          "name": "Zimbabwe",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/ZW.svg"
        }]
      }
    }, {
      "country": {
        "iso2": "GX",
        "name": "Global"
      },
      "transactionRequiredFields": [{
        "rbParentLabel": "Credit Party",
        "requiredFields": [{
          "rbFieldLabel": "Mobile Number",
          "dtoneFieldName": "creditPartyIdentifierMobileNumber",
          "regex": "^+?[0-9]{4,20}$",
          "id": 24
        }]
      }],
      "description": "3 GB 15 days Global",
      "destinationAmount": 20,
      "destinationAmountIncrement": null,
      "destinationAmountMax": null,
      "destinationAmountMin": null,
      "retailAmount": 25.72,
      "retailAmountUnit": "CAD",
      "destinationUnit": "USD",
      "id": 56083,
      "name": "3 GB 15 days Global",
      "type": "FIXED_VALUE_PIN_PURCHASE",
      "validityQuantity": 15,
      "validityUnit": "DAY",
      "pinTerms": null,
      "pinValidityUnit": "DAY",
      "pinValidityQuantity": 365,
      "pinUsageInfo": ["After buying, you will get a QR Code. Scan it to install the eSIM profile. When you arrive at your destination, remember to turn on data roaming in your device settings."],
      "paymentPostingPeriodQuantity": null,
      "paymentPostingPeriodType": null,
      "paymentPostingPeriodUnit": null,
      "operator": {
        "imageUrl": "https://operator-logo.dtone.com/logo-4918-3.png",
        "label": "eSIM Global",
        "name": "eSIM Global",
        "regions": [{
          "name": "Afghanistan",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/AF.svg"
        }, {
          "name": "Albania",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/AL.svg"
        }, {
          "name": "Algeria",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/DZ.svg"
        }, {
          "name": "Argentina",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/AR.svg"
        }, {
          "name": "Armenia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/AM.svg"
        }, {
          "name": "Australia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/AU.svg"
        }, {
          "name": "Austria",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/AT.svg"
        }, {
          "name": "Azerbaijan",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/AZ.svg"
        }, {
          "name": "Bangladesh",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/BD.svg"
        }, {
          "name": "Belarus",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/BY.svg"
        }, {
          "name": "Belgium",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/BE.svg"
        }, {
          "name": "Bosnia and Herzegovina",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/BA.svg"
        }, {
          "name": "Brazil",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/BR.svg"
        }, {
          "name": "Bulgaria",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/BG.svg"
        }, {
          "name": "Burkina Faso",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/BF.svg"
        }, {
          "name": "Canada",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/CA.svg"
        }, {
          "name": "Chad",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/TD.svg"
        }, {
          "name": "Chile",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/CL.svg"
        }, {
          "name": "China",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/CN.svg"
        }, {
          "name": "Colombia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/CO.svg"
        }, {
          "name": "Costa Rica",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/CR.svg"
        }, {
          "name": "Croatia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/HR.svg"
        }, {
          "name": "Cyprus",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/CY.svg"
        }, {
          "name": "Czech Republic",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/CZ.svg"
        }, {
          "name": "Denmark",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/DK.svg"
        }, {
          "name": "Dominican Republic",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/DO.svg"
        }, {
          "name": "Democratic Republic of the Congo",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/CD.svg"
        }, {
          "name": "Ecuador",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/EC.svg"
        }, {
          "name": "Egypt",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/EG.svg"
        }, {
          "name": "El Salvador",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/SV.svg"
        }, {
          "name": "Estonia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/EE.svg"
        }, {
          "name": "Swaziland",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/SZ.svg"
        }, {
          "name": "Faroe Islands",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/FO.svg"
        }, {
          "name": "Finland",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/FI.svg"
        }, {
          "name": "France",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/FR.svg"
        }, {
          "name": "Gabon",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/GA.svg"
        }, {
          "name": "Georgia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/GE.svg"
        }, {
          "name": "Germany",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/DE.svg"
        }, {
          "name": "Ghana",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/GH.svg"
        }, {
          "name": "Gibraltar",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/GI.svg"
        }, {
          "name": "Greece",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/GR.svg"
        }, {
          "name": "Guadeloupe",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/GP.svg"
        }, {
          "name": "Guatemala",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/GT.svg"
        }, {
          "name": "Honduras",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/HN.svg"
        }, {
          "name": "Hong Kong",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/HK.svg"
        }, {
          "name": "Hungary",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/HU.svg"
        }, {
          "name": "Iceland",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/IS.svg"
        }, {
          "name": "India",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/IN.svg"
        }, {
          "name": "Indonesia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/ID.svg"
        }, {
          "name": "Iran",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/IR.svg"
        }, {
          "name": "Ireland",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/IE.svg"
        }, {
          "name": "Israel",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/IL.svg"
        }, {
          "name": "Italy",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/IT.svg"
        }, {
          "name": "Japan",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/JP.svg"
        }, {
          "name": "Jordan",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/JO.svg"
        }, {
          "name": "Kazakhstan",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/KZ.svg"
        }, {
          "name": "Kenya",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/KE.svg"
        }, {
          "name": "Kuwait",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/KW.svg"
        }, {
          "name": "Kyrgyzstan",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/KG.svg"
        }, {
          "name": "Laos",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/LA.svg"
        }, {
          "name": "Latvia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/LV.svg"
        }, {
          "name": "Lesotho",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/LS.svg"
        }, {
          "name": "Liechtenstein",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/LI.svg"
        }, {
          "name": "Lithuania",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/LT.svg"
        }, {
          "name": "Luxembourg",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/LU.svg"
        }, {
          "name": "Macao",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/MO.svg"
        }, {
          "name": "Madagascar",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/MG.svg"
        }, {
          "name": "Malawi",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/MW.svg"
        }, {
          "name": "Malaysia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/MY.svg"
        }, {
          "name": "Malta",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/MT.svg"
        }, {
          "name": "Mexico",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/MX.svg"
        }, {
          "name": "Moldova",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/MD.svg"
        }, {
          "name": "Montenegro",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/ME.svg"
        }, {
          "name": "Morocco",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/MA.svg"
        }, {
          "name": "Mozambique",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/MZ.svg"
        }, {
          "name": "Myanmar",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/MM.svg"
        }, {
          "name": "Netherlands",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/NL.svg"
        }, {
          "name": "New Zealand",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/NZ.svg"
        }, {
          "name": "Nicaragua",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/NI.svg"
        }, {
          "name": "Niger",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/NE.svg"
        }, {
          "name": "Nigeria",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/NG.svg"
        }, {
          "name": "Macedonia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/MK.svg"
        }, {
          "name": "Norway",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/NO.svg"
        }, {
          "name": "Pakistan",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/PK.svg"
        }, {
          "name": "Palestine",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/PS.svg"
        }, {
          "name": "Panama",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/PA.svg"
        }, {
          "name": "Paraguay",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/PY.svg"
        }, {
          "name": "Peru",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/PE.svg"
        }, {
          "name": "Philippines",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/PH.svg"
        }, {
          "name": "Poland",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/PL.svg"
        }, {
          "name": "Portugal",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/PT.svg"
        }, {
          "name": "Puerto Rico",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/PR.svg"
        }, {
          "name": "Qatar",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/QA.svg"
        }, {
          "name": "Congo",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/CG.svg"
        }, {
          "name": "Reunion",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/RE.svg"
        }, {
          "name": "Romania",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/RO.svg"
        }, {
          "name": "Rwanda",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/RW.svg"
        }, {
          "name": "Senegal",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/SN.svg"
        }, {
          "name": "Serbia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/RS.svg"
        }, {
          "name": "Singapore",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/SG.svg"
        }, {
          "name": "Slovakia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/SK.svg"
        }, {
          "name": "Slovenia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/SI.svg"
        }, {
          "name": "South Africa",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/ZA.svg"
        }, {
          "name": "South Korea",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/KR.svg"
        }, {
          "name": "Spain",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/ES.svg"
        }, {
          "name": "Sri Lanka",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/LK.svg"
        }, {
          "name": "Sudan",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/SD.svg"
        }, {
          "name": "Sweden",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/SE.svg"
        }, {
          "name": "Switzerland",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/CH.svg"
        }, {
          "name": "Taiwan",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/TW.svg"
        }, {
          "name": "Tajikistan",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/TJ.svg"
        }, {
          "name": "Tanzania",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/TZ.svg"
        }, {
          "name": "Thailand",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/TH.svg"
        }, {
          "name": "Tunisia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/TN.svg"
        }, {
          "name": "Turkey",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/TR.svg"
        }, {
          "name": "Uganda",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/UG.svg"
        }, {
          "name": "Ukraine",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/UA.svg"
        }, {
          "name": "United Arab Emirates",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/AE.svg"
        }, {
          "name": "United Kingdom",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/GB.svg"
        }, {
          "name": "United States",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/US.svg"
        }, {
          "name": "Uruguay",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/UY.svg"
        }, {
          "name": "Uzbekistan",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/UZ.svg"
        }, {
          "name": "Vietnam",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/VN.svg"
        }, {
          "name": "Zambia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/ZM.svg"
        }, {
          "name": "Zimbabwe",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/ZW.svg"
        }]
      }
    }, {
      "country": {
        "iso2": "GX",
        "name": "Global"
      },
      "transactionRequiredFields": [{
        "rbParentLabel": "Credit Party",
        "requiredFields": [{
          "rbFieldLabel": "Mobile Number",
          "dtoneFieldName": "creditPartyIdentifierMobileNumber",
          "regex": "^+?[0-9]{4,20}$",
          "id": 24
        }]
      }],
      "description": "20 GB 7 days Global",
      "destinationAmount": 68,
      "destinationAmountIncrement": null,
      "destinationAmountMax": null,
      "destinationAmountMin": null,
      "retailAmount": 87.46,
      "retailAmountUnit": "CAD",
      "destinationUnit": "USD",
      "id": 56084,
      "name": "20 GB 7 days Global",
      "type": "FIXED_VALUE_PIN_PURCHASE",
      "validityQuantity": 7,
      "validityUnit": "DAY",
      "pinTerms": null,
      "pinValidityUnit": "DAY",
      "pinValidityQuantity": 365,
      "pinUsageInfo": ["After buying, you will get a QR Code. Scan it to install the eSIM profile. When you arrive at your destination, remember to turn on data roaming in your device settings."],
      "paymentPostingPeriodQuantity": null,
      "paymentPostingPeriodType": null,
      "paymentPostingPeriodUnit": null,
      "operator": {
        "imageUrl": "https://operator-logo.dtone.com/logo-4918-3.png",
        "label": "eSIM Global",
        "name": "eSIM Global",
        "regions": [{
          "name": "Afghanistan",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/AF.svg"
        }, {
          "name": "Albania",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/AL.svg"
        }, {
          "name": "Algeria",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/DZ.svg"
        }, {
          "name": "Argentina",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/AR.svg"
        }, {
          "name": "Armenia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/AM.svg"
        }, {
          "name": "Australia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/AU.svg"
        }, {
          "name": "Austria",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/AT.svg"
        }, {
          "name": "Azerbaijan",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/AZ.svg"
        }, {
          "name": "Bangladesh",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/BD.svg"
        }, {
          "name": "Belarus",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/BY.svg"
        }, {
          "name": "Belgium",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/BE.svg"
        }, {
          "name": "Bosnia and Herzegovina",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/BA.svg"
        }, {
          "name": "Brazil",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/BR.svg"
        }, {
          "name": "Bulgaria",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/BG.svg"
        }, {
          "name": "Burkina Faso",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/BF.svg"
        }, {
          "name": "Canada",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/CA.svg"
        }, {
          "name": "Chad",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/TD.svg"
        }, {
          "name": "Chile",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/CL.svg"
        }, {
          "name": "China",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/CN.svg"
        }, {
          "name": "Colombia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/CO.svg"
        }, {
          "name": "Costa Rica",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/CR.svg"
        }, {
          "name": "Croatia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/HR.svg"
        }, {
          "name": "Cyprus",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/CY.svg"
        }, {
          "name": "Czech Republic",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/CZ.svg"
        }, {
          "name": "Denmark",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/DK.svg"
        }, {
          "name": "Dominican Republic",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/DO.svg"
        }, {
          "name": "Democratic Republic of the Congo",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/CD.svg"
        }, {
          "name": "Ecuador",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/EC.svg"
        }, {
          "name": "Egypt",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/EG.svg"
        }, {
          "name": "El Salvador",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/SV.svg"
        }, {
          "name": "Estonia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/EE.svg"
        }, {
          "name": "Swaziland",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/SZ.svg"
        }, {
          "name": "Faroe Islands",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/FO.svg"
        }, {
          "name": "Finland",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/FI.svg"
        }, {
          "name": "France",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/FR.svg"
        }, {
          "name": "Gabon",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/GA.svg"
        }, {
          "name": "Georgia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/GE.svg"
        }, {
          "name": "Germany",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/DE.svg"
        }, {
          "name": "Ghana",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/GH.svg"
        }, {
          "name": "Gibraltar",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/GI.svg"
        }, {
          "name": "Greece",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/GR.svg"
        }, {
          "name": "Guadeloupe",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/GP.svg"
        }, {
          "name": "Guatemala",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/GT.svg"
        }, {
          "name": "Honduras",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/HN.svg"
        }, {
          "name": "Hong Kong",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/HK.svg"
        }, {
          "name": "Hungary",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/HU.svg"
        }, {
          "name": "Iceland",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/IS.svg"
        }, {
          "name": "India",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/IN.svg"
        }, {
          "name": "Indonesia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/ID.svg"
        }, {
          "name": "Iran",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/IR.svg"
        }, {
          "name": "Ireland",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/IE.svg"
        }, {
          "name": "Israel",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/IL.svg"
        }, {
          "name": "Italy",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/IT.svg"
        }, {
          "name": "Japan",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/JP.svg"
        }, {
          "name": "Jordan",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/JO.svg"
        }, {
          "name": "Kazakhstan",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/KZ.svg"
        }, {
          "name": "Kenya",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/KE.svg"
        }, {
          "name": "Kuwait",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/KW.svg"
        }, {
          "name": "Kyrgyzstan",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/KG.svg"
        }, {
          "name": "Laos",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/LA.svg"
        }, {
          "name": "Latvia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/LV.svg"
        }, {
          "name": "Lesotho",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/LS.svg"
        }, {
          "name": "Liechtenstein",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/LI.svg"
        }, {
          "name": "Lithuania",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/LT.svg"
        }, {
          "name": "Luxembourg",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/LU.svg"
        }, {
          "name": "Macao",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/MO.svg"
        }, {
          "name": "Madagascar",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/MG.svg"
        }, {
          "name": "Malawi",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/MW.svg"
        }, {
          "name": "Malaysia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/MY.svg"
        }, {
          "name": "Malta",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/MT.svg"
        }, {
          "name": "Mexico",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/MX.svg"
        }, {
          "name": "Moldova",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/MD.svg"
        }, {
          "name": "Montenegro",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/ME.svg"
        }, {
          "name": "Morocco",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/MA.svg"
        }, {
          "name": "Mozambique",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/MZ.svg"
        }, {
          "name": "Myanmar",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/MM.svg"
        }, {
          "name": "Netherlands",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/NL.svg"
        }, {
          "name": "New Zealand",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/NZ.svg"
        }, {
          "name": "Nicaragua",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/NI.svg"
        }, {
          "name": "Niger",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/NE.svg"
        }, {
          "name": "Nigeria",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/NG.svg"
        }, {
          "name": "Macedonia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/MK.svg"
        }, {
          "name": "Norway",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/NO.svg"
        }, {
          "name": "Pakistan",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/PK.svg"
        }, {
          "name": "Palestine",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/PS.svg"
        }, {
          "name": "Panama",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/PA.svg"
        }, {
          "name": "Paraguay",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/PY.svg"
        }, {
          "name": "Peru",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/PE.svg"
        }, {
          "name": "Philippines",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/PH.svg"
        }, {
          "name": "Poland",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/PL.svg"
        }, {
          "name": "Portugal",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/PT.svg"
        }, {
          "name": "Puerto Rico",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/PR.svg"
        }, {
          "name": "Qatar",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/QA.svg"
        }, {
          "name": "Congo",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/CG.svg"
        }, {
          "name": "Reunion",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/RE.svg"
        }, {
          "name": "Romania",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/RO.svg"
        }, {
          "name": "Rwanda",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/RW.svg"
        }, {
          "name": "Senegal",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/SN.svg"
        }, {
          "name": "Serbia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/RS.svg"
        }, {
          "name": "Singapore",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/SG.svg"
        }, {
          "name": "Slovakia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/SK.svg"
        }, {
          "name": "Slovenia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/SI.svg"
        }, {
          "name": "South Africa",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/ZA.svg"
        }, {
          "name": "South Korea",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/KR.svg"
        }, {
          "name": "Spain",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/ES.svg"
        }, {
          "name": "Sri Lanka",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/LK.svg"
        }, {
          "name": "Sudan",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/SD.svg"
        }, {
          "name": "Sweden",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/SE.svg"
        }, {
          "name": "Switzerland",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/CH.svg"
        }, {
          "name": "Taiwan",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/TW.svg"
        }, {
          "name": "Tajikistan",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/TJ.svg"
        }, {
          "name": "Tanzania",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/TZ.svg"
        }, {
          "name": "Thailand",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/TH.svg"
        }, {
          "name": "Tunisia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/TN.svg"
        }, {
          "name": "Turkey",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/TR.svg"
        }, {
          "name": "Uganda",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/UG.svg"
        }, {
          "name": "Ukraine",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/UA.svg"
        }, {
          "name": "United Arab Emirates",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/AE.svg"
        }, {
          "name": "United Kingdom",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/GB.svg"
        }, {
          "name": "United States",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/US.svg"
        }, {
          "name": "Uruguay",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/UY.svg"
        }, {
          "name": "Uzbekistan",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/UZ.svg"
        }, {
          "name": "Vietnam",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/VN.svg"
        }, {
          "name": "Zambia",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/ZM.svg"
        }, {
          "name": "Zimbabwe",
          "flagUrl": "https://mtescommon.blob.core.windows.net/country-flags-v2/ZW.svg"
        }]
      }
    }]
  },
  TopUp: {
    countries: [{
      "countryId": 8600,
      "callingCode": "244",
      "iso3": "AGO",
      "iso2": "AO",
      "name": "Angola"
    }, {
      "countryId": 8603,
      "callingCode": "54",
      "iso3": "ARG",
      "iso2": "AR",
      "name": "Argentina"
    }, {
      "countryId": 8615,
      "callingCode": "229",
      "iso3": "BEN",
      "iso2": "BJ",
      "name": "Benin"
    }, {
      "countryId": 8619,
      "callingCode": "267",
      "iso3": "BWA",
      "iso2": "BW",
      "name": "Botswana"
    }, {
      "countryId": 8620,
      "callingCode": "55",
      "iso3": "BRA",
      "iso2": "BR",
      "name": "Brazil"
    }, {
      "countryId": 8622,
      "callingCode": "226",
      "iso3": "BFA",
      "iso2": "BF",
      "name": "Burkina Faso"
    }, {
      "countryId": 8623,
      "callingCode": "257",
      "iso3": "BDI",
      "iso2": "BI",
      "name": "Burundi"
    }, {
      "countryId": 8625,
      "callingCode": "237",
      "iso3": "CMR",
      "iso2": "CM",
      "name": "Cameroon"
    }, {
      "countryId": 8630,
      "callingCode": "236",
      "iso3": "CAF",
      "iso2": "CF",
      "name": "Central African Republic"
    }, {
      "countryId": 8631,
      "callingCode": "235",
      "iso3": "TCD",
      "iso2": "TD",
      "name": "Chad"
    }, {
      "countryId": 8634,
      "callingCode": "57",
      "iso3": "COL",
      "iso2": "CO",
      "name": "Colombia"
    }, {
      "countryId": 8722,
      "callingCode": "242",
      "iso3": "COG",
      "iso2": "CG",
      "name": "Congo"
    }, {
      "countryId": 8675,
      "callingCode": "225",
      "iso3": "CIV",
      "iso2": "CI",
      "name": "Cote d Ivoire"
    }, {
      "countryId": 8640,
      "callingCode": "420",
      "iso3": "CZE",
      "iso2": "CZ",
      "name": "Czech Republic"
    }, {
      "countryId": 8641,
      "callingCode": "243",
      "iso3": "COD",
      "iso2": "CD",
      "name": "Democratic Republic of the Congo"
    }, {
      "countryId": 8642,
      "callingCode": "253",
      "iso3": "DJI",
      "iso2": "DJ",
      "name": "Djibouti"
    }, {
      "countryId": 8647,
      "callingCode": "240",
      "iso3": "GNQ",
      "iso2": "GQ",
      "name": "Equatorial Guinea"
    }, {
      "countryId": 8648,
      "callingCode": "291",
      "iso3": "ERI",
      "iso2": "ER",
      "name": "Eritrea"
    }, {
      "countryId": 8651,
      "callingCode": "251",
      "iso3": "ETH",
      "iso2": "ET",
      "name": "Ethiopia"
    }, {
      "countryId": 8655,
      "callingCode": "241",
      "iso3": "GAB",
      "iso2": "GA",
      "name": "Gabon"
    }, {
      "countryId": 8656,
      "callingCode": "220",
      "iso3": "GMB",
      "iso2": "GM",
      "name": "Gambia"
    }, {
      "countryId": 8662,
      "callingCode": "502",
      "iso3": "GTM",
      "iso2": "GT",
      "name": "Guatemala"
    }, {
      "countryId": 8663,
      "callingCode": "224",
      "iso3": "GIN",
      "iso2": "GN",
      "name": "Guinea"
    }, {
      "countryId": 8664,
      "callingCode": "245",
      "iso3": "GNB",
      "iso2": "GW",
      "name": "Guinea Bissau"
    }, {
      "countryId": 8670,
      "callingCode": "62",
      "iso3": "IDN",
      "iso2": "ID",
      "name": "Indonesia"
    }, {
      "countryId": 8677,
      "callingCode": "81",
      "iso3": "JPN",
      "iso2": "JP",
      "name": "Japan"
    }, {
      "countryId": 8680,
      "callingCode": "254",
      "iso3": "KEN",
      "iso2": "KE",
      "name": "Kenya"
    }, {
      "countryId": 8686,
      "callingCode": "266",
      "iso3": "LSO",
      "iso2": "LS",
      "name": "Lesotho"
    }, {
      "countryId": 8687,
      "callingCode": "231",
      "iso3": "LBR",
      "iso2": "LR",
      "name": "Liberia"
    }, {
      "countryId": 8690,
      "callingCode": "261",
      "iso3": "MDG",
      "iso2": "MG",
      "name": "Madagascar"
    }, {
      "countryId": 8691,
      "callingCode": "265",
      "iso3": "MWI",
      "iso2": "MW",
      "name": "Malawi"
    }, {
      "countryId": 8693,
      "callingCode": "223",
      "iso3": "MLI",
      "iso2": "ML",
      "name": "Mali"
    }, {
      "countryId": 8696,
      "callingCode": "230",
      "iso3": "MUS",
      "iso2": "MU",
      "name": "Mauritius"
    }, {
      "countryId": 8697,
      "callingCode": "52",
      "iso3": "MEX",
      "iso2": "MX",
      "name": "Mexico"
    }, {
      "countryId": 8701,
      "callingCode": "258",
      "iso3": "MOZ",
      "iso2": "MZ",
      "name": "Mozambique"
    }, {
      "countryId": 8703,
      "callingCode": "264",
      "iso3": "NAM",
      "iso2": "NA",
      "name": "Namibia"
    }, {
      "countryId": 8708,
      "callingCode": "227",
      "iso3": "NER",
      "iso2": "NE",
      "name": "Niger"
    }, {
      "countryId": 8709,
      "callingCode": "234",
      "iso3": "NGA",
      "iso2": "NG",
      "name": "Nigeria"
    }, {
      "countryId": 8717,
      "callingCode": "63",
      "iso3": "PHL",
      "iso2": "PH",
      "name": "Philippines"
    }, {
      "countryId": 8718,
      "callingCode": "48",
      "iso3": "POL",
      "iso2": "PL",
      "name": "Poland"
    }, {
      "countryId": 8726,
      "callingCode": "250",
      "iso3": "RWA",
      "iso2": "RW",
      "name": "Rwanda"
    }, {
      "countryId": 8732,
      "callingCode": "966",
      "iso3": "SAU",
      "iso2": "SA",
      "name": "Saudi Arabia"
    }, {
      "countryId": 8733,
      "callingCode": "221",
      "iso3": "SEN",
      "iso2": "SN",
      "name": "Senegal"
    }, {
      "countryId": 8734,
      "callingCode": "248",
      "iso3": "SYC",
      "iso2": "SC",
      "name": "Seychelles"
    }, {
      "countryId": 8740,
      "callingCode": "27",
      "iso3": "ZAF",
      "iso2": "ZA",
      "name": "South Africa"
    }, {
      "countryId": 8743,
      "callingCode": "249",
      "iso3": "SDN",
      "iso2": "SD",
      "name": "Sudan"
    }, {
      "countryId": 8650,
      "callingCode": "268",
      "iso3": "SWZ",
      "iso2": "SZ",
      "name": "Swaziland"
    }, {
      "countryId": 8748,
      "callingCode": "255",
      "iso3": "TZA",
      "iso2": "TZ",
      "name": "Tanzania"
    }, {
      "countryId": 8749,
      "callingCode": "66",
      "iso3": "THA",
      "iso2": "TH",
      "name": "Thailand"
    }, {
      "countryId": 8750,
      "callingCode": "228",
      "iso3": "TGO",
      "iso2": "TG",
      "name": "Togo"
    }, {
      "countryId": 8754,
      "callingCode": "90",
      "iso3": "TUR",
      "iso2": "TR",
      "name": "Turkey"
    }, {
      "countryId": 8756,
      "callingCode": "256",
      "iso3": "UGA",
      "iso2": "UG",
      "name": "Uganda"
    }, {
      "countryId": 8758,
      "callingCode": "971",
      "iso3": "ARE",
      "iso2": "AE",
      "name": "United Arab Emirates"
    }, {
      "countryId": 8759,
      "callingCode": "44",
      "iso3": "GBR",
      "iso2": "GB",
      "name": "United Kingdom"
    }, {
      "countryId": 8760,
      "callingCode": "1",
      "iso3": "USA",
      "iso2": "US",
      "name": "United States"
    }, {
      "countryId": 8767,
      "callingCode": "260",
      "iso3": "ZMB",
      "iso2": "ZM",
      "name": "Zambia"
    }, {
      "countryId": 8768,
      "callingCode": "263",
      "iso3": "ZWE",
      "iso2": "ZW",
      "name": "Zimbabwe"
    }],
    operators: [{
      "imageUrl": "https://operator-logo.dtone.com/logo-1459-3.png",
      "identified": null,
      "name": "ATT USA",
      "label": "ATT USA",
      "id": "1459"
    }, {
      "imageUrl": "https://operator-logo.dtone.com/logo-1464-3.png",
      "identified": null,
      "name": "T-Mobile USA",
      "label": "T-Mobile USA",
      "id": "1464"
    }, {
      "imageUrl": "https://operator-logo.dtone.com/logo-1465-3.png",
      "identified": null,
      "name": "Verizon USA",
      "label": "Verizon USA",
      "id": "1465"
    }, {
      "imageUrl": "https://operator-logo.dtone.com/logo-1731-3.png",
      "identified": null,
      "name": "SimpleMobile USA",
      "label": "SimpleMobile USA",
      "id": "1731"
    }, {
      "imageUrl": "https://operator-logo.dtone.com/logo-1737-3.png",
      "identified": null,
      "name": "Ultra Mobile USA",
      "label": "Ultra Mobile USA",
      "id": "1737"
    }, {
      "imageUrl": "https://operator-logo.dtone.com/logo-2025-3.png",
      "identified": null,
      "name": "H2O USA",
      "label": "H2O USA",
      "id": "2025"
    }, {
      "imageUrl": "https://operator-logo.dtone.com/logo-2027-3.png",
      "identified": null,
      "name": "Tracfone USA",
      "label": "Tracfone USA",
      "id": "2027"
    }, {
      "imageUrl": "https://operator-logo.dtone.com/logo-2029-3.png",
      "identified": null,
      "name": "Net10 USA Parent",
      "label": "Net10 USA Parent",
      "id": "2029"
    }, {
      "imageUrl": "https://operator-logo.dtone.com/logo-2171-3.png",
      "identified": null,
      "name": "SinPin PINLESS USA",
      "label": "SinPin PINLESS USA",
      "id": "2171"
    }, {
      "imageUrl": "https://operator-logo.dtone.com/logo-2673-3.png",
      "identified": null,
      "name": "MetroPCS Retail USA",
      "label": "MetroPCS Retail USA",
      "id": "2673"
    }]
  },
  GiftCard: {
    operators: [{
      "imageUrl": "https://operator-logo.dtone.com/logo-3285-3.png",
      "identified": null,
      "name": "Amazon USA",
      "label": "Amazon USA",
      "id": "3285"
    }, {
      "imageUrl": "https://operator-logo.dtone.com/logo-3355-3.png",
      "identified": null,
      "name": "eBay USA",
      "label": "eBay USA",
      "id": "3355"
    }, {
      "imageUrl": "https://operator-logo.dtone.com/logo-3335-3.png",
      "identified": null,
      "name": "Microsoft Xbox USA",
      "label": "Microsoft Xbox USA",
      "id": "3335"
    }, {
      "imageUrl": "https://operator-logo.dtone.com/logo-4416-3.png",
      "identified": null,
      "name": "Flixbus GiftCard USA",
      "label": "Flixbus GiftCard USA",
      "id": "4416"
    }]
  },
  Bills: {
    operators: [{
      "imageUrl": "https://operator-logo.dtone.com/logo-2978-3.png",
      "identified": null,
      "name": "Easycall PINLESS USA",
      "label": "Easycall PINLESS USA",
      "id": "2978"
    }]
  },
  TopUpProduct: {
    operators: [{
      "id": "1468",
      "label": "Movistar Argentina",
      "name": "Movistar Argentina",
      "identified": false,
      "imageUrl": "https://operator-logo.dtone.com/logo-1468-3.png",
      "subServices": [{
        "id": 101,
        "imageUrl": "url",
        "label": "Top-up",
        "name": "Airtime"
      }]
    }, {
      "id": "1693",
      "label": "Claro Argentina",
      "name": "Claro Argentina",
      "identified": false,
      "imageUrl": "https://operator-logo.dtone.com/logo-1693-3.png",
      "subServices": [{
        "id": 101,
        "imageUrl": "url",
        "label": "Top-up",
        "name": "Airtime"
      }]
    }, {
      "id": "1770",
      "label": "Personal Argentina",
      "name": "Personal Argentina",
      "identified": false,
      "imageUrl": "https://operator-logo.dtone.com/logo-1770-3.png",
      "subServices": [{
        "id": 101,
        "imageUrl": "url",
        "label": "Top-up",
        "name": "Airtime"
      }]
    }, {
      "id": "3305",
      "label": "Tuenti Argentina",
      "name": "Tuenti Argentina",
      "identified": false,
      "imageUrl": "https://operator-logo.dtone.com/logo-3305-3.png",
      "subServices": [{
        "id": 101,
        "imageUrl": "url",
        "label": "Top-up",
        "name": "Airtime"
      }]
    }],
    products: [{
      "country": {
        "iso2": "AR",
        "name": "Argentina"
      },
      "transactionRequiredFields": [],
      "description": "800 ARS",
      "destinationAmount": 800,
      "destinationAmountIncrement": null,
      "destinationAmountMax": null,
      "destinationAmountMin": null,
      "retailAmount": 5.23,
      "retailAmountUnit": "CAD",
      "destinationUnit": "ARS",
      "id": 47019,
      "name": "Movistar Argentina 800 ARS",
      "type": "FIXED_VALUE_RECHARGE",
      "validityQuantity": 30,
      "validityUnit": "DAY",
      "pinTerms": null,
      "pinValidityUnit": null,
      "pinValidityQuantity": null,
      "pinUsageInfo": null,
      "paymentPostingPeriodQuantity": null,
      "paymentPostingPeriodType": null,
      "paymentPostingPeriodUnit": null
    }, {
      "country": {
        "iso2": "AR",
        "name": "Argentina"
      },
      "transactionRequiredFields": [],
      "description": "900 ARS",
      "destinationAmount": 900,
      "destinationAmountIncrement": null,
      "destinationAmountMax": null,
      "destinationAmountMin": null,
      "retailAmount": 5.89,
      "retailAmountUnit": "CAD",
      "destinationUnit": "ARS",
      "id": 47020,
      "name": "Movistar Argentina 900 ARS",
      "type": "FIXED_VALUE_RECHARGE",
      "validityQuantity": 30,
      "validityUnit": "DAY",
      "pinTerms": null,
      "pinValidityUnit": null,
      "pinValidityQuantity": null,
      "pinUsageInfo": null,
      "paymentPostingPeriodQuantity": null,
      "paymentPostingPeriodType": null,
      "paymentPostingPeriodUnit": null
    }, {
      "country": {
        "iso2": "AR",
        "name": "Argentina"
      },
      "transactionRequiredFields": [],
      "description": "1000 ARS",
      "destinationAmount": 1000,
      "destinationAmountIncrement": null,
      "destinationAmountMax": null,
      "destinationAmountMin": null,
      "retailAmount": 6.54,
      "retailAmountUnit": "CAD",
      "destinationUnit": "ARS",
      "id": 47021,
      "name": "Movistar Argentina 1000 ARS",
      "type": "FIXED_VALUE_RECHARGE",
      "validityQuantity": 30,
      "validityUnit": "DAY",
      "pinTerms": null,
      "pinValidityUnit": null,
      "pinValidityQuantity": null,
      "pinUsageInfo": null,
      "paymentPostingPeriodQuantity": null,
      "paymentPostingPeriodType": null,
      "paymentPostingPeriodUnit": null
    }, {
      "country": {
        "iso2": "AR",
        "name": "Argentina"
      },
      "transactionRequiredFields": [],
      "description": "1500 Movistar Argentina",
      "destinationAmount": 1500,
      "destinationAmountIncrement": null,
      "destinationAmountMax": null,
      "destinationAmountMin": null,
      "retailAmount": 9.82,
      "retailAmountUnit": "CAD",
      "destinationUnit": "ARS",
      "id": 47022,
      "name": "Movistar Argentina 1500 ARS",
      "type": "FIXED_VALUE_RECHARGE",
      "validityQuantity": 30,
      "validityUnit": "DAY",
      "pinTerms": null,
      "pinValidityUnit": null,
      "pinValidityQuantity": null,
      "pinUsageInfo": null,
      "paymentPostingPeriodQuantity": null,
      "paymentPostingPeriodType": null,
      "paymentPostingPeriodUnit": null
    }, {
      "country": {
        "iso2": "AR",
        "name": "Argentina"
      },
      "transactionRequiredFields": [],
      "description": "2000 ARS",
      "destinationAmount": 2000,
      "destinationAmountIncrement": null,
      "destinationAmountMax": null,
      "destinationAmountMin": null,
      "retailAmount": 13.09,
      "retailAmountUnit": "CAD",
      "destinationUnit": "ARS",
      "id": 47023,
      "name": "Movistar Argentina 2000 ARS",
      "type": "FIXED_VALUE_RECHARGE",
      "validityQuantity": 30,
      "validityUnit": "DAY",
      "pinTerms": null,
      "pinValidityUnit": null,
      "pinValidityQuantity": null,
      "pinUsageInfo": null,
      "paymentPostingPeriodQuantity": null,
      "paymentPostingPeriodType": null,
      "paymentPostingPeriodUnit": null
    }, {
      "country": {
        "iso2": "AR",
        "name": "Argentina"
      },
      "transactionRequiredFields": [],
      "description": "2500 ARS",
      "destinationAmount": 2500,
      "destinationAmountIncrement": null,
      "destinationAmountMax": null,
      "destinationAmountMin": null,
      "retailAmount": 16.36,
      "retailAmountUnit": "CAD",
      "destinationUnit": "ARS",
      "id": 47024,
      "name": "Movistar Argentina 2500 ARS",
      "type": "FIXED_VALUE_RECHARGE",
      "validityQuantity": 30,
      "validityUnit": "DAY",
      "pinTerms": null,
      "pinValidityUnit": null,
      "pinValidityQuantity": null,
      "pinUsageInfo": null,
      "paymentPostingPeriodQuantity": null,
      "paymentPostingPeriodType": null,
      "paymentPostingPeriodUnit": null
    }, {
      "country": {
        "iso2": "AR",
        "name": "Argentina"
      },
      "transactionRequiredFields": [],
      "description": "3000 ARS",
      "destinationAmount": 3000,
      "destinationAmountIncrement": null,
      "destinationAmountMax": null,
      "destinationAmountMin": null,
      "retailAmount": 19.63,
      "retailAmountUnit": "CAD",
      "destinationUnit": "ARS",
      "id": 47025,
      "name": "Movistar Argentina 3000 ARS",
      "type": "FIXED_VALUE_RECHARGE",
      "validityQuantity": 30,
      "validityUnit": "DAY",
      "pinTerms": null,
      "pinValidityUnit": null,
      "pinValidityQuantity": null,
      "pinUsageInfo": null,
      "paymentPostingPeriodQuantity": null,
      "paymentPostingPeriodType": null,
      "paymentPostingPeriodUnit": null
    }, {
      "country": {
        "iso2": "AR",
        "name": "Argentina"
      },
      "transactionRequiredFields": [],
      "description": "3500 ARS",
      "destinationAmount": 3500,
      "destinationAmountIncrement": null,
      "destinationAmountMax": null,
      "destinationAmountMin": null,
      "retailAmount": 22.9,
      "retailAmountUnit": "CAD",
      "destinationUnit": "ARS",
      "id": 47026,
      "name": "Movistar Argentina 3500 ARS",
      "type": "FIXED_VALUE_RECHARGE",
      "validityQuantity": 30,
      "validityUnit": "DAY",
      "pinTerms": null,
      "pinValidityUnit": null,
      "pinValidityQuantity": null,
      "pinUsageInfo": null,
      "paymentPostingPeriodQuantity": null,
      "paymentPostingPeriodType": null,
      "paymentPostingPeriodUnit": null
    }]
  }
};