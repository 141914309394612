import React, { useCallback, useEffect, useState } from 'react'
import classes from './IBANCalculator.module.scss'
import Box from '../../../../../remitbee/components/box/Box'
import { useMediaQuery } from '@mui/material'
import theme from '../../../../../remitbee/theme/Theme'
import Typography from '../../../../../remitbee/components/typography/Typography'
import { ibanData } from './IBANExamples'
import DropDownItem from '../../../../../remitbee/components/dropDown/DropDownItem'
import Flag from '../../../../../remitbee/components/flag/Flag'
import DropDown from '../../../../../remitbee/components/dropDown/DropDown'
import Input from '../../../../../remitbee/components/input/Input'
import Button from '../../../../../remitbee/components/button/Button'
import { checkIBANValidation } from '../../../../../redux/settings/actions'
import Alert from '../../../../../remitbee/components/alert/Alert'
import copyUrl from '../../../../../../public/design-system/rewards/copy-url-v2.svg';
import copiedUrl from '../../../../../../public/design-system/rewards/copied-url.svg';

const Generator = () => {
    const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.md}px)`);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [filteredCountryList, setFilteredCountryList] = useState(ibanData);
    const [values, setValues] = useState(null);
    const [ibanState, setIbanState] = useState({
        value: '',
        success: false,
        error: false,
        info: null
    });
    const [copyLinkPressed, setCopyLinkPressed] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formErrors, setFormErrors] = useState(null);

    const handleCountryChange = (e) => {
        const itemData = JSON.parse(e.currentTarget.getAttribute('data-item'));
        if (!itemData) return
        setValues(null);
        setFormErrors(null);
        setIbanState({
            value: '',
            success: false,
            error: false,
            info: null
        })
        setSelectedCountry(itemData);
    }

    const handleCountrySearch = (typed: string, receiveSuggestions: any) => {
        if (typed && typed !== '') {
            const filteredList = ibanData.filter(c => c.name.toUpperCase().includes(typed.toUpperCase()));
            filteredList.length > 0 ? setFilteredCountryList(filteredList) : setFilteredCountryList([]);
        } else setFilteredCountryList(ibanData);
    }

    useEffect(() => {
        let timer;
        if (copyLinkPressed) {
          timer = setTimeout(() => {
            setCopyLinkPressed(false);
          }, 5000); // Set copy button as unpressed in 5 seconds
        }
    
        return () => {
          clearTimeout(timer);
        };
      }, [copyLinkPressed]);

      const handleValidateIban = async () => {
        try {
            if(!selectedCountry) return;
            setLoading(true)
           const { iso2, check_digit } = selectedCountry;
           const fieldsExtracted = selectedCountry?.fields?.map(field => values[field?.field_name]);
           const iban = `${iso2}${check_digit}${fieldsExtracted.join('')}`;
            let { success, data } = await checkIBANValidation({ brc: iban, brc_type: 'iban' });
            if(success && data?.validateBankReferenceNumberV2 && data.validateBankReferenceNumberV2?.is_valid) {
               setIbanState({ ...ibanState, success: true, value: iban, info: data?.validateBankReferenceNumberV2?.bank_details, error: false })
               setLoading(false)
            } else throw new Error();
        } catch (error) {
            setIbanState({ ...ibanState, success: false, error: true })
            setLoading(false)
        }
      }


    const renderCountry = (country:any, index?:number) => {
        return (
            <DropDownItem
                key={index}
                value={country?.name}
                data={country}
                onClick={handleCountryChange}
            >
                <Box display='flex' alignItems='center'>
                    <Flag code={country?.iso2} />
                    <Box ml={3}>{country?.name}</Box>
                </Box>
            </DropDownItem>
        )
    }

    const handleSend = () => window.open('https://www.remitbee.com/signup', '_blank')

    const handleFormErrors = (field, error) => {
        setFormErrors(prevState => ({ ...prevState, [field]: error }));
      }

    const validateInput = (field, value) => {
        const currentField = selectedCountry?.fields?.find(f => f.field_name === field);
        if(!currentField) return;
        const regex = new RegExp(currentField.regex);
        if(regex.test(value)) {
            handleFormErrors(field, null);
        } else handleFormErrors(field, 'Invalid format');
      }

    useEffect(() => {
      if(values) {
        Object.entries(values).forEach(([field, value]) => {
          validateInput(field, value)
        });
      }
    }, [values])

    const handleContiue = async () => {
        const valueData = values && Object.entries(values);
        if(ibanState?.success) {
           window.open('https://www.remitbee.com/signup', '_blank')
           return;
        }
            

        if(valueData?.length) {
          valueData.forEach(([field, value]) => {
          validateInput(field, value)
        });
    
        const allFieldsFilled = Object.values(values).every((value:any) => value && value?.trim() !== '');
        const noErrors = Object.values(formErrors).every(error => !error);
    
        if (allFieldsFilled && noErrors) {
            await handleValidateIban()
        }
      }
     }

  return (
    <Box className={classes['rb-validator-iban-container']}>
      <Box className={classes['rb-validator-text-container']}>
        <Typography variant={isMobile ? 'displayMd' : 'displayLg'} weight='bold' color={theme.palette.primary.navy}>Calculate an IBAN</Typography>
        <Typography variant='textMd' align='center'>Fill in the details to calculate IBAN number</Typography>
      </Box>

      <Box className={classes['rb-dropdown-container']}>
            <DropDown id='iban-dropdown' label='Country' placeholder='Select country' list={filteredCountryList.map((country,i) => renderCountry(country,i))} selectedItem={selectedCountry ? renderCountry(selectedCountry) : null} onSearch={handleCountrySearch}/>
        </Box>

      {selectedCountry ? <Box display='flex' gap={16} flexWrap='wrap' justifyContent='space-between'>
       {selectedCountry?.fields?.map((field,i) => {
          return (
            <Box className={classes['rb-iban-dynamic-input']}>
            <Input
              value={values ? values[field?.field_name] : ''}
              label={field?.label}
              onChange={({ target: { value } }) => setValues({ ...values, [field?.field_name]: value })}
              required
              fullWidth={isMobile}
              error={formErrors ? formErrors[field?.field_name] : ''}
            />
            </Box>
          )
       })}
      </Box> : null}  

      {ibanState?.success ? <Box className={classes['rb-validator-success-frame']}>
        <Box><Alert id="iban-success" showCloseIcon={false} variant='success' type='alert' message="This IBAN Looks right!" /></Box>

        <Box>
            <Typography variant='body2'>IBAN details :</Typography>
            <Box display='flex' flexDirection={isMobile ? 'column' : 'row'} justifyContent='space-between' mt={1}>
                <div className={classes['rb-success-content']}>
                    <Typography variant='h5' weight='semibold' color={theme.palette.primary.navy}>{(ibanState?.info?.account_number) ? (ibanState?.info?.account_number) : ibanState?.value}</Typography>
                    {ibanState?.info?.bank_name ? <Typography variant='body2'>{ibanState?.info?.bank_name}</Typography> : null}
                </div>
                <div className={classes['rb-button-container']}><Button
                 variant='outlined'
                 id='copy-iban'
                 fullWidth={isMobile}
                 onClick={() => { setCopyLinkPressed(!copyLinkPressed); navigator.clipboard.writeText(`${(ibanState?.info?.account_number) ? (ibanState?.info?.account_number) : ibanState?.value}`) }}
                 >
                  <img src={copyLinkPressed ? copiedUrl : copyUrl} width={22} height={22} color={theme.palette.primary.blue} />
                  {copyLinkPressed ? 'Copied' : 'Copy'}
                </Button></div>
            </Box>
        </Box>
      </Box> : 
      ibanState?.error ? <Box><Alert id="iban-success" showCloseIcon={false} variant='negative' type='alert' message="This IBAN doesn't looks right!" /></Box> : null}

      <Button disabled={loading || !selectedCountry} loading={loading} fullWidth variant='filledPrimary' id='check-iban' onClick={handleContiue}>
        {ibanState?.success ? 'Send money now' : "Calculate IBAN"}
      </Button>
    </Box>
  )
}

export default Generator