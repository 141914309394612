import _defineProperty from "/home/node/node_modules/.pnpm/next@14.2.16_@babel+core@7.26.10_react-dom@18.3.1_react@18.3.1_sass@1.65.1/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import client from "./client";
var isDevEnv = process.env.REACT_APP_ENV === 'dev';
export var strapiQuery = function strapiQuery(query, variables) {
  return new Promise(function (resolve) {
    var objToBeReturned = {
      success: true,
      errorMessage: null,
      data: null
    };
    client.query({
      variables: variables,
      query: query
    }).then(function (_ref) {
      var data = _ref.data;
      objToBeReturned.data = data;
      resolve(objToBeReturned);
    })["catch"](function (err) {
      console.log(variables !== null && variables !== void 0 && variables.path ? "Error for path ".concat(variables.path, " - ").concat(err) : "".concat(err));
      objToBeReturned = _objectSpread(_objectSpread({}, objToBeReturned), {}, {
        success: false,
        errorMessage: err.message
      });
      resolve(objToBeReturned);
    });
  });
};
export default strapiQuery;