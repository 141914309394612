import React, { useCallback, useState } from 'react';
import { AreaChart, ResponsiveContainer, XAxis, YAxis, Tooltip, Area } from 'recharts';
import Typography from '../../../remitbee/components/typography/Typography';
import theme from '../../../remitbee/theme/Theme';
import classes from './FavoriteRates.module.scss';
import dayjs from 'dayjs';
import { interval } from 'date-fns';

const RatesGraphV2 = ({
  ratesHistory,
  onMouseHover,
  showAxisX = false,
  showAxisY = false,
  currencyCode,
  setDateData,
  setTimeData,
  isCurrencyExchange=null,
  source=null,
  target=null,
  isMobile = false,
  height = 345,
  dy=18
}) => {
  const [lastPosition, setLastPosition] = useState(null);

  const CustomTooltip: React.FC<any> = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className={classes['rb-graph-tooltip']}>
          <Typography variant="textLg" color={theme.palette.primary.navy} weight="semibold">
            {isCurrencyExchange
              ? `1 ${source?.currency_code} = ${payload[0].value} ${target?.currency_code}`
              : payload[0].value}
          </Typography>
          <Typography variant="textXs">{payload[0]?.payload?.name}</Typography>
        </div>
      );
    }
  };

  const formatData = ratesHistory.map((item) => {
    return isCurrencyExchange
      ? {
          rate: parseFloat(item.rate).toFixed(2),
          name: item?.datetime_added || item?.date_added,
        }
      : {
          name: item.datetime_added,
          rate: parseFloat(item.rate).toFixed(2),
        };
  });

  // const rates = formatData && formatData.length && formatData.map((data) => parseFloat(data.rate));
  // const minRate = Math.min(...rates);
  // const maxRate = Math.max(...rates);

  // const padding = 0.5;

  const getXAxisProps = (filter) => {
    if (filter === '1M') {
      return {
        tickFormatter: (date) => dayjs(date).format('MMM D'), // Format as "Jul 5"
        interval: (index) => index % 7 === 0, // Show ticks every 7th data point (weekly)
      };
    } else if (filter === '1Y') {
      return {
        tickFormatter: (date) => dayjs(date).format('MMM D'), // Format as "Jul"
        interval: (index) => index % 30 === 0, // Show ticks every ~30 data points (monthly)
      };
    } else {
      // Default case: Show all dates
      return {
        tickFormatter: (date) => dayjs(date).format('MMM D'),
        interval: 'preserveStartEnd',
      };
    }
  };

  const handleMouseMove = useCallback(
    (state) => {
      if (state.isTooltipActive) {
        const { activePayload } = state;
        if (
          activePayload &&
          activePayload.length &&
          lastPosition?.name !== activePayload[0].payload.name
        ) {
          const date = dayjs(activePayload[0].payload.name, 'MMM DD, YYYY h:mm a');

          const formattedDate = date.format('DD-MM-YYYY');
          const formattedTime = date.format('h:mm A');
          setLastPosition({
            name: activePayload[0].payload.name,
            rate: activePayload[0].payload.rate,
          });
          if (setDateData) setDateData(formattedDate);
          if (setTimeData) setTimeData(formattedTime);
          if (onMouseHover)
            onMouseHover({
              date: activePayload[0].payload.name,
              rate: activePayload[0].payload.rate,
              currency_code: currencyCode,
            });
        }
      }
    },
    [lastPosition]
  );

  return (
    <ResponsiveContainer width="100%" height={height}>
      <AreaChart
        data={formatData}
        onMouseMove={handleMouseMove}
        margin={showAxisY && { top: 10, right: 30, left: 30, bottom: 10 }} // Adjust margins
      >
        <defs>
          <linearGradient id="colorGradient" x1="0" y1="0" x2="1" y2="1">
            <stop offset="5%" stopColor="#1167F7" stopOpacity={1} />
            <stop offset="95%" stopColor="rgba(255, 255, 255, 0.00)" stopOpacity={0} />
          </linearGradient>
        </defs>

        <XAxis
          dataKey="name"
          axisLine={false}
          tickLine={false}
          hide={!showAxisX}
          tickFormatter={(date) => dayjs(date).format('MMM D')}
          style={{ fontSize: '14px', fill: theme.palette.input.body, fontFamily: 'Inter' }}
          interval="preserveStart"
          minTickGap={40} // Increase to prevent label crowding
          allowDuplicatedCategory={false}
          dy={isMobile ? 5 : dy}
        />

        <YAxis
          domain={['dataMin', 'dataMax']} // Dynamic range
          dataKey="rate"
          orientation="right"
          hide={!showAxisY}
          axisLine={false}
          tickLine={false}
          tickCount={5}
          tickFormatter={(value) => value.toFixed(2)}
          style={{ fontSize: '14px', fill: theme.palette.input.body, fontFamily: 'Inter' }}
          dx={20}
          //   padding={{ top: 10, bottom: 10 }} // More spacing
        />

        <Tooltip content={<CustomTooltip />} />
        <Area
          type="monotone"
          fill="url(#colorGradient)"
          dataKey="rate"
          stroke={theme.palette.primary.blue}
          strokeWidth={'2px'} // Reduce for cleaner lines
          activeDot={{
            r: 3,
            fill: theme.palette.primary.blue,
            stroke: theme.palette.primary.blue,
            strokeWidth: 1,
          }}
          dot={false} // Hide the dots
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default RatesGraphV2;
