import React, { useEffect, useState, useRef } from 'react'
import { fetchCountriesFromRates } from '../../../../../redux/system/actions';
import { useSelector } from 'react-redux';
import classes from './FullWidthSection.module.scss'
import Typography from '../../../../../remitbee/components/typography/Typography';
import theme from '../../../../../remitbee/theme/Theme';
import Box from '../../../../../remitbee/components/box/Box';
import { useMediaQuery } from '@mui/material';
import Spacing from '../../../../../remitbee/styles/spacing/Spacing';

const CANADA_CURRENCY_CODE = 'CAD';
const CANADA_CURRENCY_NAME = 'Canadian dollar';
const sendingValues = [1, 5, 10, 20, 50, 100, 150, 200, 250, 500, 1000, 1200, 1500, 2000, 2500, 3000];


const ConversionTable = ({ currency_code, tableWidth = null, storyBookMockData = [] }) => {
    const { countries } = storyBookMockData.length > 0 ? storyBookMockData :  useSelector((state) => state.system);
    const [tranferRates, setTransferRates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currencyCodeLabel, setCurrencyCodeLabel] = useState(null);
    const [rate, setRate] = useState(1);
    const [currencyCode, setCurrencyCode] = useState(currency_code);
    const [currencyName, setCurrencyName] = useState(null);
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`);
    const [activeTable, setActiveTable] = useState(0);
    const scrollRef = useRef(null);

    useEffect(() => {
        setLoading(true);
        const getCountries = async () => {
         if (countries) {
           setTransferRates(countries)
           return
         }
         if(storyBookMockData.length > 0) {
            setTransferRates(storyBookMockData)
            return
         }
         fetchCountriesFromRates();
        }
    
        getCountries();
        setLoading(false);
      }, [countries]);

      const updateCurrencyInfo = (code) => {
        const selectedCountry = tranferRates.filter((country) => {
          if (!isNaN(Number(code)) && country.country_to_id === +code) {
            setCurrencyCodeLabel(country.currency_code)
            return true;
          }
          if (country.currency_code === code) {
            setCurrencyCodeLabel(code)
            return true
          }
          return false;
        });

        if (selectedCountry && selectedCountry.length) {
            const { rate, country_to, currency_name } = selectedCountry[0];
            setRate(rate);
            setCurrencyName(currency_name)
        }
    }

    const handleScroll = () => {
      if (scrollRef?.current) {
        const container = scrollRef?.current;
        const containerWidth = container?.clientWidth;
        const scrollLeft = container?.scrollLeft;
  
        let currentIndex = 0;
        let currentScroll = 0;
        Array.from(container?.children)?.some((tab: any, index) => {
          currentScroll += tab?.offsetWidth;
          if (currentScroll > scrollLeft + containerWidth / 2) {
            currentIndex = index;
            return true;
          }
          return false;
        });
  
        if (scrollLeft === 0) {
          currentIndex = 0;
        }
  
        setActiveTable(currentIndex);
      }
    };

    useEffect(() => {
      if (scrollRef?.current) {
        scrollRef?.current?.addEventListener('scroll', handleScroll);
      }
  
      return () => {
        if (scrollRef?.current) {
          scrollRef?.current?.removeEventListener('scroll', handleScroll);
        }
      };
    }, []);
    

    useEffect(() => {
        if (tranferRates.length > 0 && currencyCode) updateCurrencyInfo(currencyCode)
        if(storyBookMockData.length > 0 && currencyCode) updateCurrencyInfo(currencyCode)
      }, [currencyCode, tranferRates])
    
      const sendingValueRows = sendingValues.map(value => ({
        sending: `${value} ${CANADA_CURRENCY_CODE}`,
        receiving: `${Number(rate * value).toFixed(2)} ${currencyCodeLabel}`
      }));

      const receivingValueRows = sendingValues.map(value => ({
        sending: `${value} ${currencyCode}`,
        receiving: `${Number(value / rate).toFixed(2)} ${CANADA_CURRENCY_CODE}`
      }));

  return (
    <>
    <Box componentRef={scrollRef} display='flex' justifyContent='flex-start' alignItems='stretch' gap={56} className={classes['rb-conversion-table']}>
    {   <div className={classes['rb-exchange-rate-table']} style={{width : tableWidth && !isMobile && tableWidth}}>
       <table>
    <thead>
          <tr>
            <th colSpan={2}><Typography variant='textXl' weight='semibold' color={theme.palette.primary.navy}>{`${currencyName} to Canadian Dollar Exchange Rate`}</Typography></th>
          </tr>
        </thead>
    <tbody>
      {receivingValueRows.map((rate, index) => (
        <tr key={index}>
          <td><Typography align="left" weight='semibold' color={theme.palette.primary.blue} variant={isMobile ? 'textSm' : 'textMd'} className={classes['rb-table-text']}>{rate.sending}</Typography></td>
          <td><Typography align="right" variant={isMobile ? 'textSm' : 'textMd'} className={classes['rb-table-text']} weight='semibold'>{rate.receiving} </Typography></td>
        </tr>
      ))}
    </tbody>
  </table>
  </div>}
  { <div className={classes['rb-exchange-rate-table']} style={{width : tableWidth && tableWidth}}>
   <table>
    <thead>
          <tr>
            <th colSpan={2}><Typography variant='textXl' weight='semibold' color={theme.palette.primary.navy}>{`Canadian dollar to ${currencyName} exchange rate`}</Typography></th>
          </tr>
        </thead>
    <tbody>
      {sendingValueRows.map((rate, index) => (
        <tr key={index}>
          <td><Typography align='left' weight='semibold' color={theme.palette.primary.blue} variant={isMobile ? 'textSm' : 'textMd'} className={classes['rb-table-text']}>{rate.sending}</Typography></td>
          <td><Typography align='right' variant={isMobile ? 'textSm' : 'textMd'} className={classes['rb-table-text']} weight='semibold'>{rate.receiving}</Typography></td>
        </tr>
      ))}
    </tbody>
  </table>
  </div>}
    </Box>
    {isMobile && <Spacing variant='betweenSummaryCards' />}
     {isMobile && (
      <Box display="flex" justifyContent="center" mt={2}>
        <div
          style={{
            backgroundColor: activeTable === 0 ? theme.palette.primary.blue : '#C5D9FC',
          }}
          className={classes['rb-bottom-part']}
          onClick={() => setActiveTable(0)}
        />
        <div
          style={{
            backgroundColor: activeTable === 1 ? theme.palette.primary.blue : '#C5D9FC',
          }}
          className={classes['rb-bottom-part']}
          onClick={() => setActiveTable(1)}
        />
      </Box>
    )}
    </>
  )
}

export default ConversionTable