import react, { useState } from 'react';
import Container from '../../components/container/Container';
import Typography from '../../components/typography/Typography';
import classes from './PressMentionSection.module.scss';
import theme from '../../theme/Theme';
import Markdown from 'markdown-to-jsx';
import { parseArrFromString } from '../../../shared/utility';
import Box from '../../components/box/Box';
import Image from 'next/image';
import { getImageAlt } from '../../../shared/utility';
import Spacing from '../../styles/spacing/Spacing';
import { useMediaQuery } from '@mui/material';

interface Props {
  title?: string;
  titleVariant?: string;
  titleColor?: string;
  imageList?: any;
  backgroundColor?: string;
  titleWeight?: string;
}

const PressMentionSection: React.FC<Props> = ({
  title,
  titleVariant,
  titleColor,
  imageList,
  backgroundColor,
  titleWeight,
}) => {
  const imagesList = imageList
    ? typeof imageList === 'string'
      ? parseArrFromString(imageList)
      : imageList
    : null;
    const isMobile = useMediaQuery(`(max-width: ${theme?.breakpoints?.sm}px)`);

  const handleClick = (link) => window.open(link);

  return (
    <div
      style={{
        backgroundColor: backgroundColor || null,
        backgroundPosition: 'center',
      }}
    >
      <Container className={classes['rb-container']}>
        {title && (
          <Typography
            variant={titleVariant || 'TextLg'}
            weight={titleWeight || 'medium'}
            color={titleColor || theme.palette.input.body}
            align="center"
          >
            <Markdown>{title}</Markdown>
          </Typography>
        )}
       {isMobile ? <Spacing variant='titleToCard' /> : <Spacing variant="betweenSectionToInputFields" />}
        {imagesList && (
          <Box display="flex" justifyContent="center" gap={24} flexWrap="wrap" flexDirection={isMobile ? "column" : "row"} alignItems='center'>
            {imagesList.map((item, index) => {
              return (
                <div
                  key={index}
                  onClick={item?.onClick ? () => handleClick(item.onClick) : null}
                  style={{ cursor: item?.onClick ? 'pointer' : '' }}
                >
                  <img
                    src={item?.src}
                    alt={item.alt || getImageAlt(item.src) || 'imageAlt'}
                    className={classes['rb-imagesList']}
                  />
                </div>
              );
            })}
          </Box>
        )}
      </Container>
    </div>
  );
};

export default PressMentionSection;
