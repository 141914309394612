import React, { useEffect, useState } from 'react'
import Container from '../../../../../remitbee/components/container/Container'
import { useMediaQuery } from '@mui/material'
import theme from '../../../../../remitbee/theme/Theme'
import classes from './ExchangeRateGraph.module.scss'
import Box from '../../../../../remitbee/components/box/Box'
import Typography from '../../../../../remitbee/components/typography/Typography'
import DropDown from '../../../../../remitbee/components/dropDown/DropDown'
import dayjs from 'dayjs'
import { useSelector, useDispatch } from 'react-redux';
import { isInteger } from '../../../../../shared/math'
import { getExchangeCurrencyRateHistory, getTransferRateHistory } from '../../../../../redux/rates/actions'
import DropDownItem from '../../../../../remitbee/components/dropDown/DropDownItem'
import Flag from '../../../../../remitbee/components/flag/Flag'
import { fetchCountriesRest } from '../../../../../redux/system/actions'
import { exchangeRatesOnline } from '../../../../../redux/exchange/actions'
import { snackbar } from '../../../../../remitbee/components/snackbar/SnackBar'
import TransferHoriz from '../../../../../remitbee/icons/TransferHoriz'
import Spacing from '../../../../../remitbee/styles/spacing/Spacing'
import Divider from '../../../../../remitbee/components/divider/Divider'
import Button from '../../../../../remitbee/components/button/Button'
import RatesGraphV2 from '../../../../rates/ratesComponents/RatesGraphV2'
import RateEmail from './RateEmail'

interface ExchangeRateGraphProps {
    backgroundColor?: string
    rootPadding?: string
    mobileRootPadding?: string
    title?: string
    isCurrencyExchange?: boolean
    selected?: string
    reverse?: boolean
    subscribe?: boolean
    storybookMockData?: any
    showGraph?: boolean
}

const CANADA = {
    id: 1,
    country_to: "Canada",
    currency_code: "CAD",
    currency_name: "Canadian Dollar",
    iso2: "CA",
    iso3: "CAN",
};

const  WEEK = '1W', MONTH = '1M', YEAR = '1Y', ALL = 'All';
const chartRangeOptions = [ WEEK, MONTH, YEAR, ALL];
let mapRangeOptions = {
    '1D': {
        from: dayjs().subtract(1, 'day').endOf('day').format('YYYY-MM-DD H:mm:ss'),
        unit: "hour",
        unitStepSize: 1,
    },
    "1W": {
        from: dayjs().subtract(7, 'day').startOf('day').format('YYYY-MM-DD H:mm:ss'),
        unit: "day",
        unitStepSize: 1
    },
    "1M": {
        from: dayjs().subtract(1, 'month').startOf('day').format('YYYY-MM-DD H:mm:ss'),
        unit: "day",
        unitStepSize: 7
    },
    "3M": {
        from: dayjs().subtract(3, 'month').startOf('day').format('YYYY-MM-DD H:mm:ss'),
        unit: "day",
        unitStepSize: 10
    },
    "6M": {
        from: dayjs().subtract(6, 'month').startOf('day').format('YYYY-MM-DD H:mm:ss'),
        unit: "month",
        unitStepSize: 1
    },
    "1Y": {
        from: dayjs().subtract(1, 'year').startOf('day').format('YYYY-MM-DD H:mm:ss'),
        unit: "month",
        unitStepSize: 2
    },
    'All': {
        from: dayjs().subtract(5, 'year').startOf('day').format('YYYY-MM-DD H:mm:ss'),
        unit: "month",
        unitStepSize: 3
    }
}

const generateMockData = () => {
    const mockData = [];
    const currentDate = dayjs().startOf('day'); // Start of the current day
  
    for (let i = 0; i < 12; i++) { // Generate 12-15 data points
      const mockEntry = {
        country_from_name: "Canada",
        country_from_code: "CAN",
        currency_from_name: "Canadian Dollar",
        currency_from_code: "CAD",
        country_to_name: "United States",
        country_to_code: "USA",
        currency_to_name: "United States Dollar",
        currency_to_code: "USD",
        date_added: currentDate.subtract(i, 'day').format('MMM DD, YYYY h:mm a'), // Subtract weeks
        rate: (0.7 + i * 0.01).toFixed(2), // Increment rate for variation
        spot_rate: (1.4 + i * 0.02).toFixed(2) // Increment spot rate for variation
      };
      mockData.push(mockEntry);
    }
  
    return mockData;
  };

const DATA = {
    history: generateMockData(),
    countryList: [
        {
            "id": 1,
            "country_from": "United States",
            "country_from_id": 236,
            "country_to": "Canada",
            "country_to_id": 40,
            "rate": "2.3667",
            "discount_sell": "-.03",
            "discount_buy": ".01",
            "manual_rate_sell": "44",
            "manual_rate_buy": "5",
            "manual": false,
            "favorite": false,
            "currency_code": "USD",
            "currency_name": "United States Dollar",
            "iso2": "US",
            "iso3": "USA",
            "calling_code": 1
        }
    ]
}

const ExchangeRateGraph: React.FC<ExchangeRateGraphProps> = ({
    backgroundColor,
    rootPadding,
    mobileRootPadding,
    title,
    isCurrencyExchange,
    selected,
    reverse,
    subscribe,
    storybookMockData,
    showGraph = true
}) => {
    const isTablet = useMediaQuery(`(max-width: ${theme.breakpoints.lg}px)`);
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`);
    const containerPadding = { padding: isTablet && mobileRootPadding || rootPadding || '' }
    const [source, setSource] = useState<any>(isCurrencyExchange && reverse ? '236' : CANADA);
    const [target, setTarget] = useState<any>(isCurrencyExchange && !reverse ? '236' : selected ? selected : CANADA);
    const [dateFilter, setDateFilter] = useState(WEEK);
    const [ratesHistory, setRatesHistory] = useState(null);
    const [last, setLast] = useState(null);
    const { countries } = useSelector(state => state.system);
    const [currencyList, setCurrencyList] = useState([]);
    const dispatch = useDispatch();
    const [range, setRange] = useState(null);
    const [loading, setLoading] = useState(true);
    const [filteredRangeData, setFilteredRangeData] = useState([]);

    const loadTransferRates = async () => {
        if (countries) {
            setCurrencyList(countries);
            if (typeof source === 'string') onItemClick(countries.find(item => (item.country_to_id === +source || item.country_id === +source)));
        }
        else dispatch(fetchCountriesRest());
    }

    const loadExchangeRates = async () => {
        try {
            if(!storybookMockData) {
            const { success, data, errorMessage } = await exchangeRatesOnline();
            if (success && data) {
                const exchangeData = data.ExchangeRatesOnline[0];

                const unitedStates = {
                    id: exchangeData.id,
                    country_name: exchangeData.country_from,
                    country_id: exchangeData.country_from_id,
                    currency_code: exchangeData.currency_code,
                    currency_name: exchangeData.currency_name,
                    iso2: exchangeData.iso2,
                    iso3: exchangeData.iso3,
                }
                const canada = {
                    ...CANADA,
                    id: exchangeData.id,
                    country_name: exchangeData.country_to,
                    country_id: exchangeData.country_to_id,
                }
                setCurrencyList([canada, unitedStates]);
                setSource(canada)
                setTarget(unitedStates)
            } else {
                throw new Error(errorMessage)
            }
         } else {
            setCurrencyList(DATA.countryList);
            setSource({...CANADA, id: DATA.countryList[0].id, country_id: DATA.countryList[0].country_to_id, country_name: DATA.countryList[0].country_to});
            setTarget(DATA.countryList[0]);
        }
        }
        catch (error) {
            snackbar.error(error.message, 3000)
        }
    }

    useEffect(() => {
        if (!isCurrencyExchange) loadTransferRates();
        else loadExchangeRates();
    }, [countries])

    useEffect(() => {
        if (selected && countries && countries.length > 0) {
            const selectedCountry = countries.find(country => ((country.country_id === parseInt(selected)) || (country.rate_id === parseInt(selected))));
            setTarget(selectedCountry)
        }
    }, [selected, countries?.length]);

    const getRatesHistory = async () => {
        let to = dayjs().format('YYYY-MM-DD H:mm:ss');
        let options = mapRangeOptions[dateFilter];
        let rateId = target && +(target?.rate_id ? target?.rate_id : target?.id);
        if (isInteger(rateId) && !storybookMockData) {
            const { success, data } = await getTransferRateHistory(
                {
                    rateId,
                    dateFrom: options.from,
                    dateTo: to,
                });
            if (success) {
                if (data && data.transferRateHistory && data.transferRateHistory.length) {
                    const history = [...data.transferRateHistory];
                    setRatesHistory(history.sort((a, b) => new Date(a?.datetime_added).getTime() - new Date(b?.datetime_added).getTime()));
                    setLast(data.transferRateHistory[data.transferRateHistory.length - 1]);
                    setRange({ to, ...options });
                }
            }
            setLoading(false);
        } else {
            setRatesHistory(DATA?.history);
            setLast(DATA.history[DATA.history.length - 1])
        }
    }

    const getExchangeRatesHistory = async () => {
        let to = dayjs().format('YYYY-MM-DD H:mm:ss');
        let options = mapRangeOptions[dateFilter];

        if (target && target.currency_code && !storybookMockData) {
            const { data } = await getExchangeCurrencyRateHistory(
                {
                    currency: target.currency_code,
                    dateFrom: options?.from,
                    dateTo: to,
                });
            if (data && data?.length) {
                setRatesHistory(data);
                setLast(data[data.length - 1]);
                setRange({ to, ...options });
            }
              setLoading(false);
        } else {
                setRatesHistory(DATA?.history);
                setLast(DATA?.history[DATA?.history.length - 1]);
                setRange({ to, ...options });
              setLoading(false);
        }
    }

    useEffect(() => {
        if (isCurrencyExchange) {
            getExchangeRatesHistory();
            return
        }
        getRatesHistory();
    }, [target, dateFilter, source]);

    useEffect(() => {
      if(range) {
        let from = dayjs(range.from).format('YYYY-MM-DD H:mm:ss');
        let to = dayjs(range.to).format('YYYY-MM-DD H:mm:ss');
        if (ratesHistory) {
            let filteredData = ratesHistory.filter(item => {
                let date = dayjs(item?.datetime_added || item?.date_added);
                return date.isAfter(from) && date.isBefore(to);
            });
            setFilteredRangeData(filteredData);
      }
    }
    }, [ratesHistory, range]);

    const handleSetSource = (country) => {
        setSource(country);
    };

    const handleSetTarget = (country) => {
        if (country) setTarget(country);
    };

    const handleSwapClick = () => {
        setSource(target);
        setTarget(source);
    }

    const onClickRangeButton = range => {
        setDateFilter(range);
    }

    const onItemClick = (item, action?: any) => {
        if (item) {
            action(item);
        }
    }

    const handleCountryChange = (e, action) => {
        const itemData = JSON.parse(e.currentTarget.getAttribute('data-item'));
        if (!itemData) return
        onItemClick(itemData);
    }

    const renderCountryList = (country?: any, action?: any) => {
        if (!country) return null
        return (
            <DropDownItem
                key={country.country_id}
                value={country.country_id}
                data={country}
                onClick={(e) => handleCountryChange(e, action)}
            >
                <Box display='flex' alignItems='center'>
                    <Flag code={country.iso2} />
                    <Box ml={3}><Typography variant='body1'>{`${country?.currency_code} - ${country.currency_name}`}</Typography></Box>
                </Box>
            </DropDownItem>
        )
    }

    return (
        <div style={{
            backgroundColor: backgroundColor || null
        }}>
            <Container
                type='large'
                className={classes['rb-root']}
                style={containerPadding}
            >
                <Box display='flex' flexDirection='column' alignItems='center'>
                    {showGraph && <>
                    <Typography align='center' variant='displayXl' weight='semibold' color={theme.palette.primary.navy}>
                        {title}
                    </Typography>
                    <Spacing variant='betweenSections' />
                    <Box className={classes['rb-graph-container']}>
                        <div className={classes['rb-header']}>
                            {isTablet ? null : <Typography variant='textLg' weight='semibold' color={theme.palette.primary.navy}>
                                {!isCurrencyExchange ? 'Convert from:' : 'Exchange from:'}
                            </Typography>}
                            <div className={classes['rb-exchange-currency-swapper']}>
                                <DropDown
                                    id='source-country'
                                    className={classes['rb-source-country']}
                                    list={currencyList.map(country => renderCountryList(country, handleSetSource))}
                                    selectedItem={renderCountryList(source)}
                                />
                                {isCurrencyExchange ?
                                    <div onClick={handleSwapClick} className={classes['rb-swap-icon']}>
                                        <TransferHoriz />
                                    </div> :
                                    <Typography variant='textLg' weight='semibold' color={theme.palette.primary.navy}>
                                        to
                                    </Typography>
                                }
                                <div>
                                    <DropDown
                                        id='source-country'
                                        className={classes['rb-source-country']}
                                        list={currencyList.map(country => renderCountryList(country, handleSetSource))}
                                        selectedItem={renderCountryList(target)}
                                    />
                                </div>
                            </div>
                        </div>
                        
                        {isTablet ? null : <Box><Divider /></Box>}
                        <Box display='flex' flexDirection={isMobile ? 'column' : 'row'} gap={isMobile ? 8 : 0}>
                          <Box flexGrow={1} display='flex' flexDirection='column' gap={8}>
                            <Typography variant='textLg' weight='medium'>
                                {`1 ${source?.currency_name} equals`}
                            </Typography>
                            <Typography variant='displayMd' weight='semibold' color={theme.palette.primary.navy}>
                                {`${last?.rate} ${target?.currency_name}`}
                            </Typography>
                          </Box>
                          <Box flexGrow={1} display='flex' flexDirection='column' gap={isMobile ? 32 : 8} alignItems={isMobile ? 'flex-start' : 'flex-end'}>
                            <Typography variant='textMd'>
                            {last?.datetime_added || last?.date_added}
                            </Typography>
                            <Box display='flex' justifyContent={isMobile ? 'space-between' : 'flex-end'}>
                                {chartRangeOptions.map((range, index) => (
                                    <Button
                                      id='range'
                                      className={classes['rb-button-group']}
                                      style={{ marginLeft: isMobile ? 0 : '16px'}}
                                      key={index}
                                      onClick={() => onClickRangeButton(range)}
                                      variant={range === dateFilter ? 'filled' : 'text'}
                                    >
                                        {<Typography variant='textMd' color={range === dateFilter ? '#FFF' : theme.palette.input.body}>{range}</Typography>}
                                    </Button>
                                ))}
                            </Box>
                          </Box>
                        </Box>
                        <Box>
                            {filteredRangeData && filteredRangeData.length ? 
                            <RatesGraphV2 isMobile={isTablet} source={source} target={target} ratesHistory={filteredRangeData} currencyCode={target.currency_code} showAxisX={true} showAxisY={isMobile ? false : true}  isCurrencyExchange={true} onMouseHover={null} setDateData={null} setTimeData={null}/> : null}
                        </Box>
                    </Box>
                    </>}
                    {subscribe && <Box display='flex' flexDirection='column' mt={showGraph ? 15 : 0} className={classes['rb-subscribe-container']}>
                         
                           <RateEmail selected={target} current={last ? last.rate : null} isCurrencyExchange={isCurrencyExchange} />
                        
                    </Box>}
                </Box>
            </Container>
        </div>
    )
}

export default ExchangeRateGraph