
import Dialog from '../../remitbee/components/dialogs/Dialog';
import Typography from '../../remitbee/components/typography/Typography';
import Spacing from '../../remitbee/styles/spacing/Spacing';
import Box from '../../remitbee/components/box/Box';
import Button from '../../remitbee/components/button/Button';
import theme from '../../remitbee/theme/Theme';
import { useMediaQuery } from '@mui/material';

interface Props {
    openDialog: boolean;
    shareList: any;
    setOpenDialog?: Function,
    customImage?: any;
    customTitle?: string;
}

const ShareLinksDialog: React.FC<Props> = ({ openDialog, shareList, setOpenDialog, customImage, customTitle }) => {
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.lg}px)`);

    return (
        <Dialog
            id='share-dialog'
            open={openDialog}
            onClose={() => setOpenDialog(!openDialog)}
        >

            {customImage || <img width={isMobile ? 160 : 180} height={isMobile ? 160 : 180} src={'/design-system/rewards/share.svg'} alt='share icon' />}
            <Spacing variant="betweenSections" />
            <Typography align='center' variant={isMobile ? 'h3' : 'h2'} weight='semibold' color={theme.palette.primary.navy} id='share-referral-heading'>
                {customTitle || "Share your referral code"}
            </Typography>
            <Spacing variant="titleToCard" />
            <Box display='flex' justifyContent='flex-start' flexDirection='column' gap={12}>
                {shareList.map((item, i) => (
                    <Box key={i}>
                        <Button id={item.id} variant='outlined' onClick={item.onClick} fullWidth pressed={item.pressed} >
                            {item.icon} {item.label}
                        </Button>
                    </Box>))}
            </Box>
        </Dialog>
    )
}

export default ShareLinksDialog;