import _defineProperty from "/home/node/node_modules/.pnpm/next@14.2.16_@babel+core@7.26.10_react-dom@18.3.1_react@18.3.1_sass@1.65.1/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { ApolloClient, InMemoryCache, Observable, createHttpLink } from '@apollo/client';
import { ApolloLink } from '@apollo/client/link/core';
import https from 'https';
var GRAPHQL_URL = (process.env.STRAPI_URL || 'https://cms.remitbee.com') + '/graphql';
var isStagingEnv = process.env.REACT_APP_ENV === 'staging';
var stagingHttpLinkOpts = isStagingEnv ? {
  fetchOptions: {
    agent: new https.Agent({
      rejectUnauthorized: false
    })
  }
} : {};
var httpLink = createHttpLink(_objectSpread({
  uri: GRAPHQL_URL
}, stagingHttpLinkOpts));
var timeoutLink = new ApolloLink(function (operation, forward) {
  return new Observable(function (observer) {
    var handle = setTimeout(function () {
      observer.error(new Error('Request timeout'));
    }, 12000); // 12 second timeout

    forward(operation).subscribe({
      next: function next(response) {
        clearTimeout(handle);
        observer.next(response);
      },
      error: function error(_error) {
        clearTimeout(handle);
        observer.error(_error);
      },
      complete: function complete() {
        clearTimeout(handle);
        observer.complete();
      }
    });
  });
});
var timeoutHttpLink = ApolloLink.from([timeoutLink, httpLink]);

// const originMiddleware = new ApolloLink((operation, forward) => {
//     operation.setContext(({ headers = {} }) => ({
//         headers: {
//             ...headers,
//             // origin: `https://remitbee.com`
//         },
//     }));
//     return forward(operation);
// });

var link = ApolloLink.from([timeoutHttpLink]);
var apolloClientOpts = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore'
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  }
};
var client = new ApolloClient({
  link: link,
  cache: new InMemoryCache(),
  defaultOptions: apolloClientOpts
});
export default client;