import React, { useEffect, useState } from 'react'
import classes from './ConverterGraph.module.scss'
import Typography from '../../../../../remitbee/components/typography/Typography'
import theme from '../../../../../remitbee/theme/Theme'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import Button from '../../../../../remitbee/components/button/Button'
import Share from '../../../../../remitbee/icons/Share'
import { Area, AreaChart, ResponsiveContainer, YAxis } from 'recharts'
import { useMediaQuery } from '@mui/material'
import { useTranslation } from 'next-i18next'
import copyUrl from '../../../../../../public/design-system/rewards/copy-url.svg';
import copiedUrl from '../../../../../../public/design-system/rewards/copied-url.svg';
import email from '../../../../../../public/design-system/rewards/email.svg';
import twitterX from '../../../../../../public/design-system/rewards/twitter-X.svg';
import whatsapp from '../../../../../../public/design-system/rewards/whatsapp.svg';
import ShareLinksDialog from '../../../../rewards/ShareLinksDialog'
import axios from 'axios'
import { isArray } from '@apollo/client/utilities'
import { fetchCountriesRest, updateForexRateDetails } from '../../../../../redux/system/actions'
import { useDispatch } from 'react-redux';

const MOCK_DATA = [
  {
    "date": "2025-01-06 23:00:00",
    "open": 59.8137810113062,
    "low": 59.7448318026377,
    "high": 59.8345541930738,
    "close": 59.8117850871745,
    "volume": 361
  },
  {
    "date": "2025-01-06 19:00:00",
    "open": 59.7147453227166,
    "low": 59.6843307139499,
    "high": 59.8350961603075,
    "close": 59.8069378761823,
    "volume": 339
  },
  {
    "date": "2025-01-06 15:00:00",
    "open": 59.798,
    "low": 59.745,
    "high": 59.825,
    "close": 59.748,
    "volume": 33
  },
  {
    "date": "2025-01-06 11:00:00",
    "open": 59.653,
    "low": 59.598,
    "high": 59.778,
    "close": 59.778,
    "volume": 48
  },
  {
    "date": "2025-01-06 07:00:00",
    "open": 59.865,
    "low": 59.649,
    "high": 59.918,
    "close": 59.668,
    "volume": 48
  },
  {
    "date": "2025-01-06 03:00:00",
    "open": 59.616,
    "low": 59.603,
    "high": 59.888,
    "close": 59.888,
    "volume": 48
  },
  {
    "date": "2025-01-05 23:00:00",
    "open": 59.561,
    "low": 59.561,
    "high": 59.631,
    "close": 59.612,
    "volume": 36
  }
]

const ConverterHeroComponent = ({ countryId, shareLink, setCurrencyInfo, storybookData }) => {
    
    const { countries } = useSelector((state) => state.system)
    const [selectedCurrency, setSelectedCurrency] = useState(null);
    const [ratesHistory, setRatesHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md}px)`);
    const [copyLinkPressed, setCopyLinkPressed] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const { t } = useTranslation()
    const [forexRates, setForexRates] = useState(null);
    const dispatch = useDispatch();

    const referralLink = shareLink;
    const TEXT = "Get%20$10%20towards%20your%20first%20money%20transfer%20or%20currency%20exchange%20with%20RemitBee.%20Join%20now!%20";
    const shareList = [
        {
            icon: <img src={copyLinkPressed ? copiedUrl : copyUrl} width={22} height={22} color={theme.palette.primary.green} />,
            label: (copyLinkPressed ? 'Copied!' : 'Copy link'),
            id: 'copy-link',
            onClick: () => { setCopyLinkPressed(!copyLinkPressed); navigator.clipboard.writeText(`${referralLink}`) },
            pressed: copyLinkPressed,
        },
        {
            icon: <img src={email} width={22} height={22} color={theme.palette.primary.green} />,
            label: 'Send via email',
            id: 'send-email',
            onClick: () => { window.open(`mailto:?subject=${TEXT}&body=${referralLink}`) },
        },
        {
            icon: <img src={twitterX} width={22} height={22} color={theme.palette.primary.green} />,
            label: 'Share on X (Twitter)',
            id: 'share-twitter',
            onClick: () => { window.open(`https://www.twitter.com/intent/tweet?url=${referralLink}`) },
        },
        {
            icon: <img src={whatsapp} width={22} height={22} color={theme.palette.primary.green} />,
            label: 'Send with WhatsApp',
            id: 'share-whatsapp',
            onClick: () => { window.open(`https://api.whatsapp.com/send?text=${referralLink}`) },
        }
    ];

    const generateMockData = () => {
      const highRates = MOCK_DATA.map((rate) => rate.high);
      const lowRates = MOCK_DATA.map((rate) => rate.low);
      const closeRates = MOCK_DATA.map((rate) => rate.close);
      const openRates = MOCK_DATA.map((rate) => rate.open);
      const payload = { High: highRates, Low: lowRates, Close: closeRates, Open: openRates, currency: selectedCurrency?.currency_name };

      setForexRates(payload);
      dispatch(updateForexRateDetails(payload));

      const difference = openRates.at(-1) - openRates[0];
      const diffInPercent = (difference / openRates[0]) * 100;
      setCurrencyInfo({
        previousDay: openRates.at(-1).toFixed(4),
        range: `${highRates[0].toFixed(4)}-${lowRates[0].toFixed(4)}`,
        change: `${difference.toFixed(4)} ${selectedCurrency?.currency_code}, ${diffInPercent.toFixed(4)}%`
      });
    }

    useEffect(() => {
      if (countries?.length && countryId) {
          const parsedCountryId = parseInt(countryId);
          const country = countries.find(
            (c: any) =>
              (c?.country_id === parsedCountryId || c?.country_to_id === parsedCountryId) &&
              c.currency_code !== 'USD'
          ) || countries.find(
            (c: any) =>
              c?.country_id === parsedCountryId || c?.country_to_id === parsedCountryId
          );
          if (country) setSelectedCurrency(country);
        } else {
          dispatch(fetchCountriesRest());
        }
    }, [countries]);

      useEffect(() => {
        let timer;
        if (copyLinkPressed) {
          timer = setTimeout(() => {
            setCopyLinkPressed(false);
          }, 5000); // Set copy button as unpressed in 5 seconds
        }
    
        return () => {
          clearTimeout(timer);
        };
      }, [copyLinkPressed]);

    useEffect(() => {
        if(selectedCurrency && !storybookData) {
        setLoading(true)
        fetchForexRates()
        } 
        if(storybookData) {
         generateMockData()
        }
    }, [selectedCurrency])

  const fetchRates = async (baseCurrency, targetCurrency) => {
    try {
      const response = await axios.get(
        `${process.env.FOREX_API_ENDPOINT}/historical-chart/4hour/${baseCurrency}${targetCurrency}`,
        {
          params: {
            from: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
            to: dayjs().format('YYYY-MM-DD'),
            apikey: process.env.FOREX_API_KEY
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error(`Failed to fetch rates for ${baseCurrency}${targetCurrency}:`, error);
      return null;
    }
  };

  const fetchForexRates = async () => {
    try {

      let ratesData = await fetchRates('CAD', selectedCurrency?.currency_code);
      if (!ratesData || !Array.isArray(ratesData) || !ratesData.length) {
        // Fallback to USD conversion
        const [usdToCurrencyData, cadToUsdData] = await Promise.all([
          fetchRates('USD', selectedCurrency?.currency_code),
          fetchRates('CAD', 'USD')
        ]);

        if (usdToCurrencyData && cadToUsdData && Array.isArray(usdToCurrencyData) && usdToCurrencyData.length && Array.isArray(cadToUsdData) && cadToUsdData.length) {
          const cadToUsdOpenRates = cadToUsdData.map((rate) => rate.open);

          ratesData = usdToCurrencyData.map((rate, index) => ({
            high: rate?.high * cadToUsdOpenRates[index],
            low: rate?.low * cadToUsdOpenRates[index],
            close: rate?.close * cadToUsdOpenRates[index],
            open: rate?.open * cadToUsdOpenRates[index]
          }));

        } else {
          console.error('Fallback rates fetching failed.');
          return;
        }
      }

      if(ratesData && ratesData.length) {
        const highRates = ratesData.map((rate) => rate.high);
        const lowRates = ratesData.map((rate) => rate.low);
        const closeRates = ratesData.map((rate) => rate.close);
        const openRates = ratesData.map((rate) => rate.open);
        const payload = { High: highRates, Low: lowRates, Close: closeRates, Open: openRates, currency: selectedCurrency?.currency_name };

        setForexRates(payload);
        dispatch(updateForexRateDetails(payload));

        const difference = openRates.at(-1) - openRates[0];
        const diffInPercent = (difference / openRates[0]) * 100;
        setCurrencyInfo({
          previousDay: openRates.at(-1).toFixed(4),
          range: `${highRates[0].toFixed(4)}-${lowRates[0].toFixed(4)}`,
          change: `${difference.toFixed(4)} ${selectedCurrency?.currency_code}, ${diffInPercent.toFixed(4)}%`
        });
      }

    } catch (error) {
      console.log(error)
    }
  }

    function getPercentageDifference(value1, value2) {
        const difference = value2 - value1;
        const percentage = (difference / value1) * 100;
        const symbol = difference >= 0 ? '↑' : '↓';
        const absolutePercentage = Math.abs(percentage).toFixed(2);
        
        return {text:`${symbol} ${absolutePercentage}%`, status: symbol === '↑' ? 'up' : 'down', value:difference };
    }
    
  const difference = forexRates && forexRates.Open.length && getPercentageDifference(forexRates?.Open?.at(-1), forexRates?.Open[0])  


  
  const SmallAreaChart = ({ type, list }) => {

    if(!list?.length) return null;
    const listUpaded = list.length > 1 ? list : [list[0], list[0]];
    const formattedSmallData = listUpaded.map(item => ({
        rate: parseFloat(item),
      }));

      const rates = formattedSmallData && formattedSmallData.length && formattedSmallData.map(data => parseFloat(data.rate));
      const minRate = Math.min(...rates);
      const maxRate = Math.max(...rates);
  
      const padding = 0.5;

    return (
      <ResponsiveContainer width={70} height={30}>
        <AreaChart data={formattedSmallData}>
         <YAxis
            domain={[minRate - padding, maxRate + padding]}
            hide={true}
         />
          <Area
            type="monotone"
            dataKey="rate"
            stroke={type === "High" ? "#008000" : "#AD1206"}
            fill={type === "High" ? "rgba(0, 128, 0, 0.07)" : "rgba(128, 0, 0, 0.07)"}
            isAnimationActive={false}
          />
        </AreaChart>
      </ResponsiveContainer>
    );
  };

    const CardContainer = ({ value, list }) => {
        if(!forexRates || !list.length) return null;

        const forexDifference = getPercentageDifference(forexRates[value][1] || forexRates[value][0], forexRates[value][0])
        
        return (
            <div className={classes['rb-asset-card-container']}>
                <div className={classes['rb-title']}>
                    <Typography variant={isMobile ? "textMd" : 'textLg'} weight='medium' color={theme.palette.primary.navy}>{value}</Typography>
                    <SmallAreaChart list={list} type={value} />
                </div>
                <div className={classes['rb-content']}>
                    <Typography variant='textMd' weight='medium' color={theme.palette.primary.navy}>{forexRates[value][0]?.toFixed(4)}</Typography>
                    <div className={`${classes['rb-rate-status']} ${classes[`rb-rate-status--${forexDifference?.status}`]}`}><Typography variant='textXs' weight='medium'>{forexDifference.text}</Typography></div>
                </div>
            </div>
        )
    }

  if(!forexRates) return null;
  return (
   <>
    <div className={classes['rb-hero-converter-container']}>
        <div className={classes['rb-converter-frame-1']}>
            <div className={classes['rb-frame-1-content']}>
                <div className={classes['rb-main-rate-box']}><Typography variant={'displayLg'} weight='semibold' color={theme.palette.primary.navy}>{forexRates?.Open[0]?.toFixed(4)}</Typography><div className={`${classes['rb-rate-status']} ${classes[`rb-rate-status--${difference?.status}`]}`}><Typography variant='textXs' weight='medium'>{difference.text}</Typography></div></div>
                <Typography variant={isMobile ? "textMd" : 'textLg'} weight='semibold' color={difference?.status === 'down' ? theme.palette.error.dark : theme.palette.success.dark}>{`${difference?.status === 'up' ? '+' : ''} ${difference?.value?.toFixed(4)} Today`}</Typography>
          <div className={classes['rb-sub-text']}>
            <Typography variant='textMd' weight='medium'>{`${dayjs(ratesHistory.at(-1)?.datetime_added).format('MMM DD, YYYY') || ''} | `}</Typography>
            <Typography color={theme.palette.primary.blue} weight='medium' variant='textMd'>Disclaimer</Typography>
          </div>
            </div>
            <Button className={classes['rb-share']} onClick={() => setOpenDialog(!openDialog)} variant='outlined' id='share'>{isMobile ? '' : `Share`}<Share /></Button>
        </div>
        <div className={classes['rb-converter-frame-2']}>
        {forexRates && forexRates?.High && <CardContainer value='High' list={forexRates?.High} />}
        {forexRates && forexRates?.Low && <CardContainer value='Low' list={forexRates?.Low} />}
        {forexRates && forexRates?.Close && <CardContainer value='Close' list={forexRates?.Close} />}
        </div>
    </div>
    <ShareLinksDialog openDialog={openDialog} shareList={shareList} setOpenDialog={setOpenDialog} />
    </> 
  )
}

export default ConverterHeroComponent