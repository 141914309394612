import React, { useEffect, useState } from 'react'
import Box from '../../../../../remitbee/components/box/Box'
import classes from './IBANCalculator.module.scss'
import Typography from '../../../../../remitbee/components/typography/Typography'
import theme from '../../../../../remitbee/theme/Theme'
import Input from '../../../../../remitbee/components/input/Input'
import Alert from '../../../../../remitbee/components/alert/Alert'
import Button from '../../../../../remitbee/components/button/Button'
import copyUrl from '../../../../../../public/design-system/rewards/copy-url-v2.svg';
import copiedUrl from '../../../../../../public/design-system/rewards/copied-url.svg';
import { useMediaQuery } from '@mui/material'
import { checkIBANValidation } from '../../../../../redux/settings/actions'

const Validator = () => {
    const [ibanState, setIbanState] = useState({
        value: '',
        success: false,
        error: false
    });
    const [copyLinkPressed, setCopyLinkPressed] = useState(false);
    const [validatedIban, setValidatedIban] = useState(null);
    const [loading, setLoading] = useState(false);
    const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.md}px)`);

    useEffect(() => {
        let timer;
        if (copyLinkPressed) {
          timer = setTimeout(() => {
            setCopyLinkPressed(false);
          }, 5000); // Set copy button as unpressed in 5 seconds
        }
    
        return () => {
          clearTimeout(timer);
        };
      }, [copyLinkPressed]);

      const handleValidateIban = async () => {
        if(!(ibanState.value.length > 0)) return;
         setLoading(true)
         const { success, data } = await checkIBANValidation({ brc: ibanState.value, brc_type: 'iban' });
         if(success && data?.validateBankReferenceNumberV2 && data.validateBankReferenceNumberV2?.is_valid) {
            setIbanState({ ...ibanState, success: true, error: false })
            setValidatedIban(data?.validateBankReferenceNumberV2?.bank_details)
            setLoading(false)
         } else {
          setIbanState({ ...ibanState, success: false, error: true })
          setLoading(false)
         }
      }

      const handleSend = () => window.open('https://www.remitbee.com/signup', '_blank')

      const handleClick = () => {
        if (ibanState.success) {
          handleSend()
        } else {
          handleValidateIban()
        }
      }

  return (
    <Box className={classes['rb-validator-iban-container']}>
      <Box className={classes['rb-validator-text-container']}>
        <Typography variant={isMobile ? 'displayMd' : 'displayLg'} weight='bold' color={theme.palette.primary.navy}>Check an IBAN</Typography>
        <Typography align="center" variant='textMd'>Let’s check if you IBAN is in the correct format</Typography>
      </Box>
      <Box>
        <Input
          value={ibanState?.value}
          onChange={({ target: { value } }) => setIbanState({ ...ibanState, value })}
          label='Enter your IBAN'
          error={ibanState?.error ? "This iban doesn't seem to be valid" : ''}
          fullWidth
          placeholder='e.g, GB12ABCD34567890123456'
        />
      </Box>
      {ibanState?.success ? <Box className={classes['rb-validator-success-frame']}>
        <Box><Alert id="iban-success" showCloseIcon={false} variant='success' type='alert' message="This IBAN Looks right!" /></Box>

        <Box>
            <Typography variant='body2'>IBAN details :</Typography>
            <Box display='flex' flexDirection={isMobile ? 'column' : 'row'} justifyContent='space-between' mt={1}>
                <div className={classes['rb-success-content']}>
                    <Typography variant='h5' weight='semibold' color={theme.palette.primary.navy}>{(validatedIban?.account_number) ? (validatedIban?.account_number) : ibanState?.value}</Typography>
                    {validatedIban?.bank_name ? <Typography variant='body2'>{validatedIban?.bank_name}</Typography> : null}
                </div>
                <div className={classes['rb-button-container']}><Button
                 variant='outlined'
                 id='copy-iban'
                 fullWidth={isMobile}
                 onClick={() => { setCopyLinkPressed(!copyLinkPressed); navigator.clipboard.writeText(`${(validatedIban?.account_number) ? (validatedIban?.account_number) : ibanState?.value}`) }}
                 >
                  <img src={copyLinkPressed ? copiedUrl : copyUrl} width={22} height={22} color={theme.palette.primary.blue} />
                  {copyLinkPressed ? 'Copied' : 'Copy'}
                </Button></div>
            </Box>
        </Box>
      </Box> : null}
      {/* {ibanState?.error ? <Box><Alert id="iban-error" showCloseIcon={false} variant='negative' type='alert' message="This IBAN doesn't looks right!" /></Box> : null} */}
      <Button disabled={loading} loading={loading} fullWidth variant='filledPrimary' id='check-iban' onClick={handleClick}>
        {ibanState?.success ? 'Send money now' : "Check IBAN"}
      </Button>
    </Box>
  )
}

export default Validator