import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react'
import strapiQuery from '../../../../../settings/apollo/strapi/query';
import { SEARCH_HELP_QUERY } from '../../../apollo/queries/help/article/search';
import Markdown from 'markdown-to-jsx';
import Input from '../../../../../remitbee/components/input/Input';
import SearchIcon from '../../../../../remitbee/icons/SearchIcon';
import theme from '../../../../../remitbee/theme/Theme';
import CloseIcon from '../../../../../remitbee/icons/CloseIcon';
import classes from './HeroSection.module.scss'
import DropDownList from '../../../../../remitbee/components/dropDown/DropDownList';


const HelpSearch = () => {
    const [openDropDown, setOpenDropDown] = useState(false);
    const [results, setResults] = useState([]);
    const router = useRouter();
    const { t } = useTranslation('help');
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        const handler = setTimeout(() => {
            const getResults = async (input) => {
                setOpenDropDown(true);
                const { data, errorMessage } = await strapiQuery(SEARCH_HELP_QUERY, { search: input });
                if (errorMessage) {
                    setResults([]);
                    return;
                }
                const resultData = data?.search?.helpCentreArticles && data?.search?.helpCentreArticles?.data?.map(e => e.attributes) || [];
                setResults(resultData?.length > 0 ? resultData : []);
            };
            if (searchText?.length > 0) getResults(searchText);
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [searchText]);

    const boldKeywordFromDescription = (word, paragraph) => {
        // Escape special characters in the word to prevent issues in the regex
        const escapedWord = word.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

        // Update the regex to match anywhere within a word (no word boundaries)
        const regex = new RegExp(escapedWord, 'gi');  // 'gi' for case-insensitive search and global replacement

        // If no match is found, return the paragraph unchanged
        if (!regex?.test(paragraph)) {
            return paragraph;
        }

        // Replace matched substrings with the bold span
        const newParagraph = paragraph.replace(regex, `<span class="${classes['rb-semibold']}">${word}</span>`);
        return newParagraph;
    };

    const renderListItems = (results) => {
        return (
            results?.map((r, i) =>
                <span onClick={() => router.push(`/help/${r?.help_centre_group?.data?.attributes?.help_centre_category?.data?.attributes?.slug}/${r.slug}`)} className={classes['rb-HelpCenter-listItems']}>
                    <Markdown children={boldKeywordFromDescription(searchText, r.title)} />
                </span>
            )
        )
    }


    return (
        <>
        <Input
            placeholder={`Search how-tos and more`}
            fullWidth={true}
            value={searchText}
            onChange={({ target: { value } }) => setSearchText(value)}
            startAdornment={<SearchIcon width={20} height={20} color={theme.palette.input.body} />}
            endAdornment={searchText?.length > 0 && <div className={classes['rb-HelpCenter-header-search-input-endAdornment']} onClick={() => setSearchText('')}><CloseIcon width={22} height={22} color={theme.palette.primary.blue} /></div>}
        />
         <DropDownList
          dropListOpen={openDropDown}
          dropdownListClasses={classes['rb-HelpCenter-dropdownList']}
          primaryListItems={renderListItems(results)}
          handleOutsideClick={() => setOpenDropDown(false)}
        />
        </>
    )
}

export default HelpSearch