import React, { useEffect, useRef, useState } from 'react'
import Spacing from '../../../../../remitbee/styles/spacing/Spacing';
import Box from '../../../../../remitbee/components/box/Box';
import Input from '../../../../../remitbee/components/input/Input';
import SearchIcon from '../../../../../remitbee/icons/SearchIcon';
import theme from '../../../../../remitbee/theme/Theme';
import Typography from '../../../../../remitbee/components/typography/Typography';
import { esimProductsPublic, getCountriesPublicByType } from '../../../../../redux/esim/actions';
import { snackbar } from '../../../../../remitbee/components/snackbar/SnackBar';
import ProductCards from '../../../../eSim/ProductSelection/ProductCards';
import BoxList from '../../BoxList';
import Button from '../../../../../remitbee/components/button/Button';
import Markdown from 'markdown-to-jsx';
import EachTab from './EachTab';
import Flag from '../../../../../remitbee/components/flag/Flag';
import { useRouter } from 'next/router';
import Image from '../../../../../remitbee/components/image/Image';
import { MOCK_DATA } from './StorybookMock';

const EsimContainer = ({
    classes,
    isMobile,
    serviceType,
    selectedLocalCountry,
    t,
    storybookMock
}) => {
    const [countriesListData, setCountriesListData] = useState(null);
    const [globalList, setGlobalList] = useState(null);
    const [globalListData, setGlobalListData] = useState(null);
    const [localList, setLocalList] = useState(null);
    const [regionalList, setRegionalList] = useState(null);
    const [loading, setLoading] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [selectedTab, setSelectedTab] = useState(0);
    const scrollRef = useRef(null);
    const router = useRouter()

        useEffect(() => {
            const fetchProducts = async () => {
                if(storybookMock) {
                    setLocalList(MOCK_DATA.e_sim.local)
                    setRegionalList(MOCK_DATA.e_sim.regional)
                    setGlobalList(MOCK_DATA.e_sim.global)
                    return;
                }
                try {
                    const promises = [];
                    promises[0] = getCountriesPublicByType('LOCAL');
                    promises[1] = getCountriesPublicByType('REGIONAL');
                    promises[2] = getCountriesPublicByType('GLOBAL');
    
                    const [resultLocal, resultRegional, resultGlobal] = await Promise.all(promises);
                    const countriesList = {
                        local: resultLocal?.data?.getCountriesPublic?.countries,
                        regional: resultRegional?.data?.getCountriesPublic?.countries,
                        global: resultGlobal?.data?.getCountriesPublic?.countries,
                    };
                    setCountriesListData(countriesList);
                    setLocalList(countriesList?.local);
                    setRegionalList(countriesList?.regional);
                    if (serviceType === 'eSIMLocal' && selectedLocalCountry) {
                        const selectedCountry = countriesList?.local.find(
                            (country) => country?.iso2 === selectedLocalCountry
                        );
                        const { success, data, errorMessage } = await esimProductsPublic(
                            selectedCountry?.countryId,
                            null,
                            4,
                            'FIXED_VALUE_PIN_PURCHASE'
                        );
                        if (success && data && data.getProductsPublic) {
                            data.getProductsPublic?.products && setGlobalList(data.getProductsPublic?.products);
                        }
                    }
                    if (serviceType === 'eSIMRegional' && selectedLocalCountry) {
                        const selectedCountry = countriesList?.regional.find(
                            (country) => country?.iso2 === selectedLocalCountry
                        );
                        const { success, data, errorMessage } = await esimProductsPublic(
                            selectedCountry?.countryId,
                            null,
                            4,
                            'FIXED_VALUE_PIN_PURCHASE'
                        );
                        if (success && data && data.getProductsPublic) {
                            data.getProductsPublic?.products && setGlobalList(data.getProductsPublic?.products);
                        }
                    }
                    if (serviceType === 'eSIM') {
                        const { success, data, errorMessage } = await esimProductsPublic(
                            countriesList?.global[0].countryId,
                            null,
                            4,
                            'FIXED_VALUE_PIN_PURCHASE'
                        );
                        if (success && data && data.getProductsPublic) {
                            data.getProductsPublic?.products && setGlobalList(data.getProductsPublic?.products);
                        }
                    }
                } catch (error) {
                    snackbar.error('Something went wrong', 3000)
                } finally {
                    setLoading(false)
                }
            };
            fetchProducts();
        }, []);
    
        useEffect(() => {
            if (countriesListData) {
                setLocalList(
                    countriesListData?.local?.filter((country) =>
                        country?.name.toLowerCase().includes(searchText.toLowerCase())
                    )
                );
                setRegionalList(
                    countriesListData?.regional?.filter((country) =>
                        country?.name.toLowerCase().includes(searchText.toLowerCase())
                    )
                );
            }
        }, [searchText]);
    
        useEffect(() => {
            if (globalList) {
                const data = [...globalList].sort((a, b) => {
                    return parseFloat(a.retailAmount) - parseFloat(b.retailAmount);
                });
                setGlobalListData(data);
            }
        }, [globalList]);


      let list = [
          {
              tabHeader: 'Local',
              title: 'Explore Local plans',
              text: 'Get a prepaid eSIM data plan at local rates in 140+ countries. Enjoy the convenience of reliable and high-speed mobile data tailored to meet your needs.',
              body: <EachTab
                  list={localList?.map((country, index) => {
                      return (
                        <Box display='flex' gap={8} key={index}>
                            <Flag code={country.iso2} width={36} height={24} />
                            <Typography variant='textMd' color={theme.palette.primary.navy} weight='medium'>
                                {country.name}
                            </Typography>
                        </Box>
                      )})}
                  actionText={'View all countries'}
                  actionTextInverted={'View less countries'}
                  isMobile={isMobile}
                  classes={classes}
                  />,
          },
          {
              tabHeader: 'Regional',
              title: 'Explore Regional plans',
              text: 'Stay connected across multiple countries with our Regional prepaid eSIM plan. Access fast and reliable data and seamless connectivity without borders.',
              body: (
                //   <BoxList
                //       withoutTopPadding={true}
                //       list={regionalList}
                //       title={''}
                //       withOutImage={true}
                //       isBox={true}
                //       withoutButton={true}
                //   />
                  <Box display='flex' flexWrap='wrap' gap={24}>
                      {regionalList?.map((image =>
                      (<div className={classes['rb-regional-card']}>
                          <div className={classes['rb-region-image']}>
                              <img
                                src={`${process.env.AZURE_COMMON_STORAGE_ENDPOINT}/dtone-region-icons/${image?.iso2}-active.svg`} alt='North America' 
                                />
                          </div>
                          <Typography variant='label2' weight='medium'>
                              {image.name}
                          </Typography>
                      </div>)))
                      }
                  </Box>)
          },
          {
              tabHeader: 'Global',
              title: 'Explore Global plans',
              text: 'Explore the world with our Global eSIM plan, offering uninterrupted mobile data across the globe. Experience high-speed connectivity no matter where you travel.',
              body: (
                  <>
                      <Box display='flex' justifyContent='center' alignItems='center'>
                        <Box className={classes['rb-products-container']}>
                          {globalListData?.map((data) => {
                              return (
                                  <ProductCards
                                      plan={data}
                                      selectedPlan={null}
                                      destination_type={'public'}
                                      t={t}
                                      onSelect={() => { }}
                                  />
                              );
                          })}
                          </Box>
                      </Box>
                      <Spacing variant={!isMobile ? 'contentToCta' : 'betweenSectionToInputFields'} />
                      <Box display="flex" justifyContent="center" alignItems="center">
                          <Button variant="outlined" id="Know more about Global plans" onClick={() => router.push('/signup')}>
                              {'Know more about Global plans'}
                          </Button>
                      </Box>
                  </>
              ),
          },
      ];

      const setSelectedTabAndScroll = (index) => {
        setSelectedTab(index);
        if (scrollRef.current) {
          const containerWidth = scrollRef?.current?.clientWidth;
          const tabWidth = scrollRef?.current?.children[index]?.offsetWidth;
          const tabPosition = scrollRef?.current?.children[index]?.offsetLeft;
          const scrollPosition = tabPosition - (containerWidth / 2 - tabWidth / 2);
          scrollRef?.current?.scrollTo({
            left: scrollPosition,
            behavior: 'smooth',
          });
        }
      };

  return (
    <>
      <Spacing variant='betweenSectionToInputFields' />

<Box display='flex' flexDirection='column' justifyContent='center' alignItems='cener'>
    <Box display='flex' justifyContent='center'>
        <Box className={classes['rb-search']}>
            <Input
                id="search-destination"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                placeholder={'Search for a country or a region'}
                startAdornment={
                    <SearchIcon width={20} height={20} color={theme.palette.input.body} />
                }
                fullWidth
            />
        </Box>
    </Box>
    <Spacing variant={'betweenSections'} />
    <Box display='flex' justifyContent='center'>
        {list?.map((item, index) => {
            return (
                <Box
                    className={`
                            ${classes['rb-tab-title']}
                            ${index === selectedTab &&
                        classes['rb-tab-title-active']
                        }
                        `}
                    onClick={() => setSelectedTabAndScroll(index)}
                    key={index}
                >
                    <Typography
                        variant="textMd"
                        weight="semibold"
                        className={classes['rb-tab']}
                        color={index === selectedTab && theme.palette.background.white}
                    >
                        <Markdown children={item.tabHeader as React.ReactNode} />
                    </Typography>
                </Box>
            );
        })}
    </Box>
    <Spacing variant={'betweenSectionToInputFields'} />
    {list?.length > 0 && list[selectedTab] && (
<Box className={classes['rb-content-box']}>
<Box display="flex" justifyContent="flex-start" flexDirection="column" gap={16}>
  <Box display="flex" flexDirection="column" gap={8}>
    <Typography
      variant={'textXl'}
      weight="semibold"
      color={theme.palette.primary.navy}
      align="center"
    >
      <Markdown children={list[selectedTab]?.title} />
    </Typography>
  </Box>
  <Box display="flex" justifyContent={'center'} >
    <Typography variant="textMd" align='center' className={classes['rb-service-text']}>
      <Markdown
        children={list[selectedTab]?.text}
      />
    </Typography>
  </Box>
</Box>
<Spacing variant={!isMobile ? 'contentToCta' : 'betweenSectionToInputFields'} />

{list[selectedTab]?.body && list[selectedTab]?.body}
</Box>
)}
</Box>
    </>
  )
}

export default EsimContainer