import react from 'react';
import Box from '../../components/box/Box';
import classes from './RateTableSection.module.scss';
import Container from '../../components/container/Container';
import Typography from '../../components/typography/Typography';
import theme from '../../theme/Theme';
import Spacing from '../../styles/spacing/Spacing';
import SimpleConversionTable from '../../../components/landingPage/reusableSections/new/FullWidthSection/SimpleConversionTable';
import ConversionTable from '../../../components/landingPage/reusableSections/new/FullWidthSection/ConversionTable';
import { parseArrFromString } from '../../../shared/utility';
import Button from '../../components/button/Button';
import { useRouter } from 'next/router';
import { setRedirectSignupCookie } from '../../../shared/cookie-handler';

interface Props {
  backgroundColor?: string;
  title?: string;
  titleVariant?: string;
  titleColor?: string;
  subTitle?: string;
  subTitleVariant?: string;
  subTitleColor?: string;
  contentType: string;
  currency_code?: string;
  currencies?: any;
  primaryButtonText?: string;
  primaryButtonAction?: string;
  primaryButtonVariant?: string;
  storyBookMockData?: any;
}

const RateTableSection: React.FC<Props> = ({
  backgroundColor,
  title,
  titleVariant,
  titleColor,
  subTitle,
  subTitleVariant,
  // subTitleColor,
  contentType,
  currency_code,
  // currencies,
  primaryButtonText,
  primaryButtonAction,
  primaryButtonVariant,
  storyBookMockData
}) => {
  // const currencyPairsList = currencies
  //   ? typeof currencies === 'string'
  //     ? parseArrFromString(currencies)
  //     : currencies
  //   : null;
  const router = useRouter();

  const getComponents = () => {
    switch (contentType) {
      case 'SimpleConversionTable':
        return <SimpleConversionTable currency_code={currency_code} tableWidth={'960px'} storyBookMockData={storyBookMockData && storyBookMockData}/>;
      case 'ConversionTable':
        return <ConversionTable currency_code={currency_code} tableWidth={'960px'} storyBookMockData={storyBookMockData && storyBookMockData}/>;
    }
  };

  const goToUrl = async (actionUrl) => {
    if (!actionUrl) return;
    if (actionUrl.startsWith('http')) {
      window.open(actionUrl, '_blank');
    } else if (actionUrl.startsWith('www')) {
      window.open(`https://${actionUrl}`, '_blank');
    } else {
      let query = null;
      if (actionUrl && actionUrl.includes('signup')) await setRedirectSignupCookie(router.asPath);
      if (actionUrl && actionUrl.includes('signup?')) {
        router.push(actionUrl);
        return;
      }
      router.push({ pathname: actionUrl, query });
    }
  };

  return (
    <div
      style={{
        backgroundColor: backgroundColor || null,
        backgroundPosition: 'center',
      }}
    >
      <Box flexDirection={'column'} className={classes['rb-root']}>
      <div className={contentType === 'SimpleConversionTable' ? classes['rb-container'] : classes['rb-container-table']}>
        <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' className={classes['rb-content']}>
        {title && (
          <Typography
            variant={titleVariant || 'displayLg'}
            weight="bold"
            color={titleColor || theme.palette.primary.navy}
            align='center'
          >
            {title}
          </Typography>
        )}
        {subTitle && (
          <>
            <Spacing variant="betweenHeaderToSubheader" />
            <Typography
              variant={subTitleVariant || 'textLg'}
              color={titleColor || theme.palette.input.body}
              align='center'
            >
              {subTitle}
            </Typography>
          </>
        )}
        {contentType === 'SimpleConversionTable' && (
          <Spacing variant="betweenSectionToInputFields" />
        )}
        {primaryButtonText &&  contentType === 'ConversionTable' && (
          <>
            <Spacing variant="betweenSections" />
            <Button
              id="rate-table-section-primary-button"
              variant={primaryButtonVariant || 'primary'}
              onClick={() => goToUrl(primaryButtonAction)}
            >
              {primaryButtonText}
            </Button>
          </>
        )}
        </Box>
        {contentType === 'ConversionTable' && <Spacing variant="rateTableSection" />}
        {contentType && getComponents()}
        {primaryButtonText  && contentType === 'SimpleConversionTable' && (
          <>
            <Spacing variant="betweenSections" />
            <Button
              id="rate-table-section-primary-button"
              variant={primaryButtonVariant || 'primary'}
              onClick={() => goToUrl(primaryButtonAction)}
            >
              {primaryButtonText}
            </Button>
          </>
        )}
      </div>
      </Box>
    </div>
  );
};

export default RateTableSection;
