import _taggedTemplateLiteral from "/home/node/node_modules/.pnpm/next@14.2.16_@babel+core@7.26.10_react-dom@18.3.1_react@18.3.1_sass@1.65.1/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2;
import gql from "graphql-tag";
export var SEARCH_HELP_QUERY = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["  \nquery GetHelpCentreArticles(\n  $search: String!\n) {\n  search(query: $search) {\n    helpCentreArticles(\n      filters: {\n        publishedAt: { notNull: true },\n        help_centre_group: { name: {notNull: true} ,help_centre_category: { slug: {notNull: true}}}\n      }\n      pagination: {\n        start: 0\n        limit: 200\n      }\n    ) {\n      data {\n        id\n        attributes {\n          title\n          slug\n          meta_description\n          help_centre_group {\n              data {\n                attributes {\n                  name\n                  help_centre_category {\n                    data {\n                      attributes {\n                        title\n                        slug\n                      }\n                    }\n                  }\n                }\n              }\n          }\n        }\n      }\n    }\n  }\n  }\n\n"])));
export var SEARCH_HELP_QUERY_WITH_GROUP = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  query helpArticles($search: String!){\n    helpCentreArticles(\n      filters: {\n        publishedAt: { notNull: true },\n        help_centre_group: { \n          and: [\n            { publishedAt: { notNull: true } }, \n            { help_centre_category: { publishedAt: { notNull: true } } }\n          ]\n        },\n        or: [\n          { title: { contains: $search } },\n          { content: { contains: $search } }\n        ],\n      },\n      pagination: {\n          start: 0,\n          limit: 200\n      },\n      sort: [\"publishedAt:desc\"]\n  ) {\n        data {\n          attributes {\n            title\n            content\n            slug\n            meta_description\n            createdAt\n            updatedAt\n            help_centre_group {\n              data {\n                attributes {\n                  name\n                  help_centre_category {\n                    data {\n                      attributes {\n                        title\n                        slug\n                      }\n                    }\n                  }\n                }\n              }\n            }\n          }\n        }\n    }\n  }\n"])));

// Non-Fuzzy Search Query

// export const SEARCH_HELP_QUERY = gql`  
//   query helpArticles($search: String!){
//     helpCentreArticles(
//       filters: {
//         publishedAt: { notNull: true },
//         help_centre_group: { name: { notNull: true }, help_centre_category: { title: { notNull: true }} },
//         or: [
//           { title: { contains: $search } },
//           { content: { contains: $search } }
//         ],
//       },
//       pagination: {
//           start: 0,
//           limit: 200
//       }
//   ) {
//         data {
//           attributes {
//             title
//             content
//             slug
//             meta_description
//             help_centre_group {
//               data {
//                 attributes {
//                   help_centre_category {
//                     data {
//                       attributes {
//                         slug
//                       }
//                     }
//                   }
//                 }
//               }
//             }
//           }
//       }
//     }
//   }
// `;