import React from 'react'
import Box from '../../../../../remitbee/components/box/Box'
import Spacing from '../../../../../remitbee/styles/spacing/Spacing'
import Container from '../../../../../remitbee/components/container/Container'
import { useMediaQuery } from '@mui/material'
import theme from '../../../../../remitbee/theme/Theme'
import classes from './Service.module.scss'
import { useTranslation } from 'next-i18next'
import Typography from '../../../../../remitbee/components/typography/Typography'
import Markdown from 'markdown-to-jsx'
import EsimContainer from './EsimContainer'
import GiftContainer from './GiftContainer'
import DToneOperators from '../../../components/GiftCardPublic/DToneOperators'

interface ServiceSectionProps {
    title?: string
    titleVariant?: string
    titleWeight?: string
    titleColor?: string
    titleAlign?: string
    text?: string
    textVariant?: string
    textWeight?: string
    textColor?: string
    textAlign?: string
    serviceType?: string
    selectedLocalCountry?: string
    background?: string
    mobileRootPadding?: string
    rootPadding?: string
    services?: any
    storybookMock?: boolean
}

const ServiceSection: React.FC<ServiceSectionProps> = ({
    title,
    titleVariant = "displayXl",
    titleWeight,
    titleColor,
    titleAlign,
    text,
    textVariant = 'textLg',
    textWeight,
    textColor = theme.palette.input.body,
    textAlign,
    serviceType,
    selectedLocalCountry,
    background,
    mobileRootPadding,
    rootPadding,
    services,
    storybookMock
}) => {

    const { t } = useTranslation('e-sim');
    const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.md}px)`);
    const containerPadding = { padding: isMobile && mobileRootPadding || rootPadding || '' }

    const getComponent = () => {
        switch(serviceType) {
            case 'eSIM':
                return <EsimContainer storybookMock={storybookMock} classes={classes} serviceType={serviceType} selectedLocalCountry={selectedLocalCountry}  t={t} isMobile={isMobile}/>;
            case 'GiftCard':
                return <GiftContainer serviceType={serviceType} storybookMock={storybookMock} classes={classes} isMobile={isMobile} serviceId={2} defaultCountry={selectedLocalCountry} />;       
            case 'TopUp':
                return <GiftContainer serviceType={serviceType} storybookMock={storybookMock} classes={classes} isMobile={isMobile} serviceId={1} defaultCountry={selectedLocalCountry} />
            case 'Bills':
                return <GiftContainer serviceType={serviceType} storybookMock={storybookMock} classes={classes} isMobile={isMobile} serviceId={3} defaultCountry={selectedLocalCountry} />  
            case 'TopUpProduct':
                return <DToneOperators storybookMock={storybookMock} services={services} />
        }      
    }


    return (
        <div style={{ background: background }}>
            <Container
                type="large"
                style={containerPadding}
                className={classes['rb-root']}
            >
                <Box display='flex' flexDirection='column' >
                    {title && <Typography variant={titleVariant} weight={titleWeight || 'bold'} color={titleColor || theme.palette.primary.navy} align={titleAlign || 'center'}>
                        {typeof title !== 'string' ? title : <Markdown>{title}</Markdown>}
                    </Typography>}

                    {text && <>
                      <Spacing variant='titleToDivider' />
                      <Typography variant={textVariant} weight={textWeight || 'bold'} color={textColor || theme.palette.primary.navy} align={textAlign || 'center'}>
                        {typeof text !== 'string' ? text : <Markdown>{text}</Markdown>}
                    </Typography>
                    </>}

                </Box>
                {serviceType && getComponent()}
            </Container>
        </div>
    )
}

export default ServiceSection