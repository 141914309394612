import classes from './ExchangeRateGraph.module.scss'
import React, { useEffect, useState } from 'react'
import { subscribeRateAlert } from '../../../../../redux/auth/actions';
import Box from '../../../../../remitbee/components/box/Box';
import Typography from '../../../../../remitbee/components/typography/Typography';
import theme from '../../../../../remitbee/theme/Theme';
import Input from '../../../../../remitbee/components/input/Input';
import FlagIcon from '../../../../common/FlagIcon';
import Flag from '../../../../../remitbee/components/flag/Flag';
import CustomCheckbox from '../../../../../remitbee/components/checkbox/Checkbox';
import Divider from '../../../../../remitbee/components/divider/Divider';
import Button from '../../../../../remitbee/components/button/Button';
import { useMediaQuery } from '@mui/material';
import USFlag from '../../../../../../public/new/img/US Flag.svg'

const RateEmail = ({ selected, current, isCurrencyExchange }) => {


    const [currency, setCurrency] = useState(null);
      const [weekly, setWeekly] = useState(true);
      const [email, setEmail] = useState('');
      const [rate, setRate] = useState('');
      const [submitted, setSubmitted] = useState(false);
      const [checked, setChecked] = useState(false);
      const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md}px)`);
      

        useEffect(() => {
          if (current) setRate(current);
        }, [current]);
      
        useEffect(() => {
          if (selected.currency_code && selected.iso2) setCurrency(selected)
        }, [selected]);
      
        const handleSetFrequency = () => {
          setWeekly(!weekly);
        }
      
        const handleSubmit = async event => {
          event.preventDefault();
          if(!email || !rate) return;
          const data:any = {
            lead_email: email,
            threshold: rate,
            exchange_rate_id: '',
            exchange_type: '',
          }
      
          if (isCurrencyExchange && selected.currency_code === 'USD') {
            data.exchange_rate_id = selected.id;
            data.exchange_type = 'BUY';
          } else if (isCurrencyExchange && selected.currency_code === 'CAD') {
            data.exchange_rate_id = selected.id;
            data.exchange_type = 'SELL';
          } else {
            data.rate_id = selected.id;
          }
      
          await subscribeRateAlert(data);
          setSubmitted(true);
        }

        const handleTryAgain = event => {
            event.preventDefault()
            setEmail('');
            setRate(current || '');
            setSubmitted(false);
          }


  return (
    <>
    <Box display='flex' flexDirection='column' gap={16} mb={8}>
                            <Typography color={theme.palette.primary.navy} variant='displayLg' weight='bold' align='center'>
                                Want to catch the best rate?
                            </Typography>
<Typography variant='textLg' align='center'>
Don’t worry! We’ve got you covered. Sign up for email notifications and we’ll let you know when your rate is available. We’ll also update you on all of the latest currency news.
</Typography>

                        </Box>
      <Box pt={8} display='flex' flexDirection='column' gap={16} className={classes['rb-divider']}>
        <Box display='flex' flexDirection='column' gap={8}>
          <Typography variant='textXl' weight='semibold' color={theme.palette.primary.navy}>
           Email me when
          </Typography>
          <Box display='flex' gap={24} alignItems={isMobile ? '' : 'center'} flexDirection={isMobile ? 'column' : 'row'} justifyContent={isMobile ? '' : 'space-between'}>
            <div className={classes['rb-flag-container-1']}>
                <div className={classes['rb-input']}>
                    <input type='text' value={1}></input>
                </div>
                <div className={classes['rb-flag']}>
                <svg xmlns="http://www.w3.org/2000/svg" width="38" height="26" viewBox="0 0 38 26" fill="none">
                  <rect x="7.44531" y="0.62793" width="23.2411" height="24.6937" fill="white" />
                  <path d="M2.82845 0.62793C1.2663 0.62793 0 1.89423 0 3.45638V22.5433C0 24.1054 1.2663 25.3717 2.82845 25.3717H8.46502V0.627951L2.82845 0.62793Z" fill="#FF4B55" />
                  <path d="M34.9373 0.627951H29.3008V25.3717H34.9373C36.4994 25.3717 37.7658 24.1054 37.7658 22.5432V3.45638C37.7658 1.89423 36.4995 0.62793 34.9373 0.62793V0.627951Z" fill="#FF4B55" />
                  <path d="M22.8353 15.8587L26.6992 13.6509L25.8126 13.2075C25.564 13.0832 25.4216 12.8147 25.4584 12.539L25.7469 10.3761L24.1111 10.8521C23.7497 10.9572 23.3747 10.7349 23.2934 10.3675L23.1367 9.65936L21.6007 11.457C21.3879 11.7062 20.9822 11.5158 21.0377 11.1929L21.6937 7.3787L20.6894 7.65334C20.396 7.73359 20.0862 7.60007 19.9432 7.33171L18.8857 5.34966V5.34668L18.8849 5.34815L18.8841 5.34668V5.34966L17.8266 7.33171C17.6834 7.60001 17.3736 7.73352 17.0802 7.65334L16.0759 7.3787L16.7319 11.1929C16.7875 11.5158 16.3818 11.7062 16.1688 11.4571L14.6329 9.65946L14.4762 10.3676C14.3949 10.7351 14.0199 10.9573 13.6585 10.8521L12.0227 10.3762L12.3112 12.5391C12.348 12.8147 12.2056 13.0832 11.9569 13.2076L11.0703 13.6508L14.9343 15.8588C15.3345 16.0875 15.5099 16.5726 15.3484 17.0044L15.0181 17.8878L18.329 17.6051C18.5122 17.5895 18.6681 17.737 18.6627 17.9208L18.5585 21.4646H19.2096L19.1052 17.9206C19.0998 17.7368 19.2557 17.5893 19.439 17.605L22.7514 17.8878L22.4211 17.0044C22.2597 16.5725 22.4351 16.0874 22.8353 15.8587L22.8353 15.8587Z" fill="#FF4B55" />
                </svg>
                    <Typography variant='textXl' weight='medium'>CAD</Typography>
                </div>
            </div>
            <Typography variant='textMd' weight='medium' color={theme.palette.primary.navy}>
                is above
            </Typography>
            <div className={classes['rb-flag-container-2']}>
                <div className={classes['rb-input-target']}>
                    <input type='text' value={rate} onChange={(e) => setRate(e.target.value)}></input>
                </div>
                <div className={classes['rb-flag']}>
                    <img src={USFlag} alt="US Flag" width={38} height={27}/>
                    <Typography variant='textXl' weight='medium'>USD</Typography>
                </div>
            </div>
          </Box>
        </Box>
        <Box display='flex' gap={5}>
            <Box mt={0.5} style={{ width: 'fit-content'}} justifyContent='center' display='flex'><CustomCheckbox
               isChecked={checked}
                onChange={() => setChecked(!checked)}
                /></Box>
            <Box display='flex' flexGrow={1}><Typography variant='textMd' weight='medium' color={theme.palette.primary.navy}>Send me daily updates</Typography>    </Box>
        </Box>
      </Box>
      <Box mt={8} mb={8}>
            <Divider />
        </Box>
        <Box mb={8}>
            <Input
                type='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder='Enter your email address'
                label='Email address'
                fullWidth
            />
        </Box>
        <Box display='flex' justifyContent='center'>
            <Button
                className={classes['rb-button']}
                id='subscribe'
                variant='filled'
                fullWidth
                onClick={handleSubmit}
            >
                Subscribe to rate alerts
            </Button>
        </Box>
    </>
  )
}

export default RateEmail