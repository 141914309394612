import React, { useEffect, useState } from 'react';
import classes from './Forecast.module.scss';
import Box from '../../../../../remitbee/components/box/Box';
import Typography from '../../../../../remitbee/components/typography/Typography';
import theme from '../../../../../remitbee/theme/Theme';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import Button from '../../../../../remitbee/components/button/Button';

const ForecastSection = ({ backgroundColor, currency }) => {
  const [forexInfo, setForexInfo] = useState(null);
  const [predictedCost, setPredictedCost] = useState(null);
  const { forexRates } = useSelector((state) => state.system);
  const [viewMore, setViewMore] = useState(false);
  const [forecastData, setForecastData] = useState([]);
  const [shortList, setShortList] = useState([]);

  useEffect(() => {
     if (forexRates) {
        setForexInfo({
            high: Math.max(...forexRates.High),
            low: Math.min(...forexRates.Low),
            open: forexRates.Open[0],
            currency_name: forexRates?.currency
        });
     }
   }, [forexRates]);

   useEffect(() => {
     if (forexInfo?.open) {
        setPredictedCost(predictForecastRate(forexInfo?.open));
     }
   }, [forexInfo?.open]);

   useEffect(() => {
     if(predictedCost) {
      const data = generateForecastData();
      setForecastData([...data]);
      setShortList(data.slice(0, 6));
     }
   }, [predictedCost])

   const handleClick = () => {
    setShortList(viewMore ? forecastData.slice(0, 6) : forecastData);
    setViewMore(!viewMore);
   }


  const getRandomValue = (value, maxDifference = 0.02) => {
    if (!value) return;
    const difference = (Math.random() - 0.5) * maxDifference * 2;
    const finalValue = value + difference;
    return finalValue.toFixed(4);
  };

  console.log(forexInfo?.open, predictedCost);

  const predictForecastRate = (rate) => {
    const currentRate = rate;
    const inflationRate1 = 0.02;
    const inflationRate2 = 0.01;
    const forecastPeriod = 1;

    const result = currentRate * (Math.pow(((1 + inflationRate1)/(1 + inflationRate2)), forecastPeriod));

    return result;
  };

  const generateForecastData = () => {
    const data = [];
    const currentDate = dayjs();
    for (let i = 1; i <= 12; i++) {
        const month = currentDate.add(i, 'month').format("MMM'YY");
        data.push({
            month,
            high: getRandomValue(forexInfo?.high),
            low: getRandomValue(forexInfo?.low),
            expected: getRandomValue(predictedCost)
        });
    }
    return data;
  }

  if (!forexInfo || !predictedCost) return null;

  return (
    <section style={{ backgroundColor: backgroundColor }}>
       <Box
            flexDirection={'column'}
            className={classes['rb-root']}
        >
          <Box display='flex' flexDirection='column' gap={32}>
            <Typography variant='displayLg' weight='bold' color={theme.palette.primary.navy}>
              Forecast in detail
            </Typography>
            <Box className={classes['rb-forecast-container']}>
              {shortList && shortList.length && shortList.map((item, i) => (
                <Box key={i} className={classes['rb-forecast-card']}>
                  <Typography variant='textXl' color={theme.palette.primary.navy} weight='semibold'>Forecast for {item.month}</Typography>
                  <Typography variant='textMd'>
                    {`Expected high and low CAD to ${currency} forecast is ${item.high} - ${item.low} ${forexInfo?.currency_name} respectively. Expected rate for ${item.month} is ${item.expected} ${forexInfo?.currency_name}.`}
                  </Typography>
                </Box>
              ))}
            </Box>
            <Box className={classes['rb-forecast-button']} >
              <Button variant='text' id='view-more' onClick={handleClick}>{!viewMore ? `View more` : 'View less'}</Button>
            </Box>
          </Box>
        </Box>
    </section>
  )
}

export default ForecastSection;