import React, { useEffect, useState } from 'react'
import Box from '../../../../../remitbee/components/box/Box';
import Button from '../../../../../remitbee/components/button/Button';

const EachTab = ({ list = [], actionText, isMobile, actionTextInverted, classes }) => {

    const [fullList, setFullList] = useState(list);
    const [shortList, setShortList] = useState([]);
    const [showAll, setShowAll] = useState(false);

        useEffect(() => {
            setFullList(list)
            setShortList(list?.slice(0, isMobile ? 8 : 12))
        }, [list?.length])

        const handleClick = () => {
            setShowAll(!showAll);
            setShortList(showAll ? fullList.slice(0, isMobile ? 8 : 12) : fullList)
        }

  return (
    <>
    <Box className={classes['rb-cards-container']}>
      {shortList?.map((tag: any, index: number) => (
        <Box key={index} className={classes['rb-card-container']} style={{ cursor: tag?.onClick ? 'pointer' : '' }}>
                {tag}
        </Box>
        ))}
    </Box>
    {fullList?.length > 12 && <Box mt={isMobile ? 8 : 12} display='flex' justifyContent='center' alignItems='center'>
          <Button fullWidth={isMobile} className={classes['rb-button-regular']} variant='outlined' onClick={handleClick} id='show-all'>
            {showAll ? actionTextInverted : actionText}
          </Button>
        </Box>}
    </>
  )
}

export default EachTab