import React from 'react'
import Box from '../../../../../remitbee/components/box/Box'
import classes from './ColumnSection.module.scss'
import Typography from '../../../../../remitbee/components/typography/Typography'
import theme from '../../../../../remitbee/theme/Theme'
import Image from 'next/image'
import Button from '../../../../../remitbee/components/button/Button'
import { getImageAlt } from '../../../../../shared/utility'
import { useMediaQuery } from '@mui/material'
import CheckIconCircled from '../../../../../remitbee/icons/CheckIconCircled'
import Spacing from '../../../../../remitbee/styles/spacing/Spacing'
import Markdown from 'markdown-to-jsx'

const SectionCard = ({
    cardType,
    cardGrid = '3',
    cardList,
    cardGridGap
}) => {
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md}px)`);
    const formattedCardGrid = isMobile ? 1 : parseInt(cardGrid);

    const IconType = ({ cardIcon, cardIconWidth = 48, cardIconHeight = 48 }) => {
        switch (cardType) {
            case 'BankLogo':
            case 'PromoCard':    
                return <img src={cardIcon} alt={getImageAlt(cardIcon)} />
            default:
                return <Image
                    src={cardIcon}
                    alt='icon'
                    width={cardIconWidth}
                    height={cardIconHeight}
                />
        }
    }


    const EachCard = ({ info, index }) => {
        const bulletPoints = cardType === 'BulletPoints' ? Object.keys(info)
        ?.filter((e) => e.includes('cardList'))
        ?.map((e) => info[e]) 
        : null;

        return (
            <div className={
                `
                 ${classes['rb-section-card']}
                 ${classes[`rb-cardType-${cardType}`]}
                `
            } 
            style={{ backgroundColor: info?.cardBackGroundColor, borderColor: info?.cardBorderColor, borderRight: info?.cardRightBorder && !isMobile && `1px solid ${theme.palette.border.regular}` }}
            >
                {info?.cardBadge && <div className={classes['rb-section-badge']}>
                    <Typography variant='textSm' color={theme.palette.primary.blue}>{info?.cardBadge}</Typography>
                </div>}
               {info?.cardIcon &&  <Box id="icon" display='flex' justifyContent={info?.cardIconPosition === 'left' ? 'flex-start' : info?.cardIconPosition === 'right' ? 'flex-end' : 'center'} >
                    {<IconType cardIcon={info?.cardIcon} cardIconWidth={info?.cardIconWidth} cardIconHeight={info?.cardIconHeight}/>}
                </Box>}
                <Box display='flex' flexDirection='column' gap={cardType === 'PromoCard' ? 24 : 20}>
                 {(info?.cardTitle || info?.cardText) ? <Box display='flex' flexDirection='column' >
                    <Typography variant={info?.cardTitleVariant || 'textXl'} weight='bold' color={theme.palette.primary.navy} align={info?.cardTitleAlign || 'left'}>
                        {typeof info?.cardTitle !== 'string' ? info?.cardTitle : <Markdown>{info?.cardTitle}</Markdown>}
                    </Typography>
                    {info?.cardText && <Spacing variant='betweenSectionsSmall' />}
                    <Typography variant={info?.cardTextVariant || 'textMd'} align={info?.cardTextAlign || 'left'}>
                       {typeof info?.cardText !== 'string' ? info?.cardText : <Markdown>{info?.cardText}</Markdown>}
                    </Typography>
                 </Box> : null}
                   {bulletPoints && <Box className={classes['rb-content-container']}>
                     {bulletPoints?.map((point, index) => {
                        return (
                            <Box key={index} className={classes['rb-each-list']}>
                                <div><CheckIconCircled /></div>
                                <Typography variant='textMd' color={theme.palette.primary.navy}>
                                    {point}
                                </Typography>
                            </Box>
                        )
                     })}
                   </Box>}
                 
                 {info?.cardButtonText && <Box>
                    <Button id='action' variant='text' onClick={info?.cardButtonAction ? () => window.open(info?.cardButtonAction) : null} className={classes['rb-card-button']}>
                      {info?.cardButtonText}
                    </Button>
                 </Box>}   
                </Box>
            </div>
        )
    }  


  return (
    <Box display='grid' gap={cardGridGap ? cardGridGap : cardType?.includes('Unboxed') ? '32px 16px' : isMobile ? 24 : 16} style={{ gridTemplateColumns: `repeat(${formattedCardGrid}, 1fr)` }}>
      {cardList?.map((card, index) => {
        return (
          <EachCard key={index} info={card} index={index} />
        )
      }
     )}
    </Box>
  )
}

export default SectionCard