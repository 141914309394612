import React, { useState, useEffect,useRef } from 'react';
import Box from '../../../../../remitbee/components/box/Box';
import classes from './HalfWidthSection.module.scss';
import { useMediaQuery } from '@mui/material';
import theme from '../../../../../remitbee/theme/Theme';
import Typography from '../../../../../remitbee/components/typography/Typography';
import Spacing from '../../../../../remitbee/styles/spacing/Spacing';
import Button from '../../../../../remitbee/components/button/Button';
import Placeholder from '../../../../../remitbee/icons/PlaceholderIcon';
import { setRedirectSignupCookie } from '../../../../../shared/cookie-handler';
import { useRouter } from 'next/router';
import CheckIconCircled from '../../../../../remitbee/icons/CheckIconCircled';
import Markdown from 'markdown-to-jsx';
import MultiConverter from '../../../components/DesignSystem/MultiConverterTab/MultiConverter';
import { getImageAlt, parseArrFromString } from '../../../../../shared/utility';
import IBANCalculator from '../IBANCalculator/IBANCalculator';
import Container from '../../../../../remitbee/components/container/Container';
import Image from '../../../../../remitbee/components/image/Image';
import DebitCalculator from './DebitCalculator';
import Table from '../FullWidthSection/Table';
import dynamic from 'next/dynamic';
import FeeCalculator from '../FeeCalculator/FeeCalculator';

const ConverterHeroComponent = dynamic(() => import('../ConverterGraph/ConverterHeroComponent'), {
  ssr: false,
});

interface MoneyTransferProps {
  country: string;
  currency: string;
}

interface ColumnSectionValues {
  titleVariant: string;
  textVariant: string;
  title: string;
  text: string;
}

interface Props {
  reverse?: boolean;
  reverseMobile?: boolean;
  title: string | React.ReactNode;
  titleVariant?: string;
  titleWeight?: string;
  titleColor?: string;
  subtitle?: string;
  subtitleVariant?: string;
  subtitleWeight?: string;
  subtitleColor?: string;
  subText?: string;
  subTextVariant?: string;
  subTextWeight?: string;
  subTextColor?: string;
  tag?: string;
  tagVariant?: string;
  tagWeight?: string;
  tagColor?: string;
  text?: string | React.ReactNode;
  textVariant?: string;
  textWeight?: string;
  textColor?: string;
  primaryButtonText?: string;
  primaryButtonAction?: string;
  primaryButtonVariant?: string;
  primaryButtonStartIcon?: string;
  primaryButtonEndIcon?: string;
  primaryButtonStartIconVariant?: string | boolean;
  primaryButtonEndIconVariant?: string;
  secondaryButtonText?: string;
  secondaryButtonAction?: string;
  secondaryButtonVariant?: string;
  secondaryButtonStartIconVariant?: string;
  secondaryButtonEndIconVariant?: string;
  listItems?: string;
  imageUrl?: string;
  ImageProps?: string;
  contentType?: string;
  bottomListItems?: string;
  mainListItems?: string;
  mainListItemsGrid?: number | string;
  backgroundColor?: string;
  background?: string;
  backgroundImage?: string;
  rootPadding?: string;
  mobileRootPadding?: string;
  promotion?: any;
  moneyTransferProps?: MoneyTransferProps;
  userRating?: string;
  userName?: string;
  userOccupation?: string;
  componentType?: string;
  countryId?: string;
  shareLink?: string;
  isMobileServer?: boolean;
  twoSideList?: string;
  isMainListButton?: boolean;
  tableColumns?: any;
  secondTableColumns?: any;
  tableData?: any;
  secondTableData?: any;
  columnsBackground?: string;
  tableWidth?: string;
  isImageInTop?: boolean;
  columnSectionProps?: ColumnSectionValues[];
  textInSameColumn?: boolean;
  isBackgroundCard?: boolean;
  imageList?: any;
  storybookData?: any;
  leftTitle? : string;
  rightTitle? : string;
  leftTitleColor? : string;
  rightTitleColor? : string;
  twoSideCardColor?: string;
  isTopBorder?: boolean;
  tableLastColumnAlign?: string;
  video?: string;
  zoom?: string;
  videoProp?: any;
}

const HalfWidthSection: React.FC<Props> = ({
  reverse,
  reverseMobile,
  title,
  titleVariant = 'displayLg',
  titleWeight = 'bold',
  titleColor = theme.palette.primary.navy,
  subtitle,
  subtitleVariant = 'textXl',
  subtitleWeight = 'bold',
  subtitleColor = theme.palette.primary.navy,
  subText,
  subTextVariant = 'textMd',
  subTextWeight = 'regular',
  subTextColor = theme.palette.input.body,
  tag,
  tagVariant,
  tagWeight,
  tagColor,
  text,
  textVariant = 'textLg',
  textWeight = 'regular',
  textColor = theme.palette.input.body,
  primaryButtonText,
  primaryButtonAction,
  primaryButtonVariant,
  primaryButtonStartIcon,
  primaryButtonEndIcon,
  primaryButtonStartIconVariant,
  primaryButtonEndIconVariant,
  secondaryButtonText,
  secondaryButtonAction,
  secondaryButtonVariant,
  secondaryButtonStartIconVariant,
  secondaryButtonEndIconVariant,
  listItems,
  imageUrl,
  ImageProps,
  contentType,
  bottomListItems,
  mainListItems,
  mainListItemsGrid,
  backgroundColor,
  background,
  backgroundImage,
  rootPadding,
  mobileRootPadding,
  promotion,
  moneyTransferProps,
  userRating,
  userName,
  userOccupation,
  componentType,
  countryId,
  shareLink,
  isMobileServer,
  twoSideList,
  isMainListButton = false,
  tableColumns,
  secondTableColumns,
  secondTableData,
  tableData,
  columnsBackground,
  tableWidth,
  isImageInTop = false,
  columnSectionProps,
  textInSameColumn,
  isBackgroundCard = false,
  imageList,
  storybookData,
  leftTitle,
  rightTitle,
  leftTitleColor,
  rightTitleColor,
  twoSideCardColor,
  isTopBorder= false,
  tableLastColumnAlign,
  video,
  zoom,
  videoProp
}) => {
  const isMobile = isMobileServer || useMediaQuery(`(max-width: ${theme.breakpoints.md}px)`);
  const isTablet = useMediaQuery(`(max-width: ${theme.breakpoints.xl}px)`);
  const isNotTablet = useMediaQuery(`(min-width: ${theme.breakpoints.sm}px)`);
  const isNotMobile = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`);
  const imageListData = typeof imageList === 'string' ? parseArrFromString(imageList) : imageList;
  const list = listItems
    ? typeof listItems === 'string'
      ? componentType === 'graph'
        ? parseArrFromString(listItems)
        : Object.values(parseArrFromString(listItems)[0])
      : listItems
    : null;
  const bottomList = bottomListItems
    ? typeof bottomListItems === 'string'
      ? parseArrFromString(bottomListItems)
      : bottomListItems
    : null;
  const mainList = mainListItems
    ? typeof mainListItems === 'string'
      ? parseArrFromString(mainListItems)
      : mainListItems
    : null;
  const mainListGrid = mainListItemsGrid || mainList?.length;
  const router = useRouter();
  const [currencyInfo, setCurrencyInfo] = useState(null);
  const twoSideListData = twoSideList
    ? typeof twoSideList === 'string'
      ? parseArrFromString(twoSideList)
      : twoSideList
    : null;
  const tableDataConverted = tableData
    ? typeof tableData === 'string'
      ? parseArrFromString(tableData)
      : tableData
    : null;
    const tableColumnsConverted = tableColumns
    ? typeof tableColumns === 'string'
      ? parseArrFromString(tableColumns)
      : tableColumns
    : null;
    const videoProps = typeof videoProp === 'string' ? parseArrFromString(videoProp)[0] : videoProp;
    const [height, setHeight] = useState('100%');
    const videoRef = useRef(null);
    const containerRef = useRef(null);
    const secondTableDataConverted = secondTableData
    ? typeof secondTableData === 'string'
      ? parseArrFromString(secondTableData)
      : secondTableData
    : [];
    const secondTableColumnsConverted = secondTableColumns
    ? typeof secondTableColumns === 'string'
      ? parseArrFromString(secondTableColumns)
      : secondTableColumns
    : null;


    if (videoProps && videoProps.loop && typeof videoProps.loop === 'string') {
      videoProps.loop = Boolean(videoProps.loop);
    }
  
    useEffect(() => {
      if (zoom && videoRef.current && containerRef.current) {
        if (videoProps && videoProps.speed) {
          videoRef.current.playbackRate = videoProps.speed;
        }
        const value =
          ((containerRef.current.getBoundingClientRect().width * 65) / 96) * parseInt(zoom);
        setHeight(`${value}px`);
      }
    }, [zoom, videoProps]);

  const addReferralLink = () => {
    let _args = {};
    _args = { promo: promotion };
    return _args;
  };

  const goToUrl = async (actionUrl) => {
    if (!actionUrl) return;
    if (actionUrl.startsWith('http')) {
      window.open(actionUrl, '_blank');
    } else if (actionUrl.startsWith('www')) {
      window.open(`https://${actionUrl}`, '_blank');
    } else {
      let query = null;
      if (actionUrl && actionUrl.includes('signup')) await setRedirectSignupCookie(router.asPath);
      if (actionUrl && actionUrl.includes('signup?')) {
        router.push(actionUrl);
        return;
      }
      if (promotion) query = addReferralLink();
      router.push({ pathname: actionUrl, query });
    }
  };

  const MTProps = parseArrFromString(moneyTransferProps);
  const ColumnSectionProps =
    typeof columnSectionProps === 'string'
      ? parseArrFromString(columnSectionProps)
      : columnSectionProps;

  const getComponents = () => {
    switch (contentType) {
      case 'MultiCurrencyConverter':
        return <MultiConverter />;
      case 'SendMoneyConverter':
        return (
          <Box className={classes['rb-se-box']}>
            <MultiConverter componentType="moneyTransfer" moneyTransferProps={MTProps[0]} />
          </Box>
        );
      case 'CurrencyExchange':
        return (
          <Box className={classes['rb-ce-box']}>
            <MultiConverter componentType="currencyExchange" moneyTransferProps={MTProps[0]} />
          </Box>
        );
      case 'ShareConverter':
        return (
          <ConverterHeroComponent
            storybookData={storybookData}
            countryId={countryId}
            shareLink={shareLink}
            setCurrencyInfo={setCurrencyInfo}
          />
        );
      case 'IBANCalculator':
        return <IBANCalculator />;
      case 'FeeCalculator':
        return <FeeCalculator variant={'mini'} /> 
    }
  };

  const getContent = () => {
    let ImagePropsObject;
    // eg ImageProps={ {"width": "524px", "height":"380px"} }
    // if (ImageProps) {
    //     console.log(ImageProps)
    //     // Convert width and height to numeric values if they are given as strings with "px"
    //     const parsedProps = JSON.parse(ImageProps.trim());
    //     ImagePropsObject = {
    //         ...parsedProps,
    //         width: parseInt(parsedProps.width, 10),
    //         height: parseInt(parsedProps.height, 10),
    //     };
    // }
    if (imageUrl && !video)
      return (
        // <NextImage
        //     src={imageUrl}
        //     alt={getImageAlt(imageUrl) || 'imageAlt'}
        //     className={classes['rb-image']}
        //     width={'480px'}
        //     height={contentType === 'userReview' ? '500px' : '380px'}
        //     loader={({ src, width, quality }) => `${src}?w=${width}&q=${quality}`}
        //     {...ImagePropsObject}
        //     loading="lazy"
        // />
        <Image
          url={imageUrl}
          alt={getImageAlt(imageUrl) || 'imageAlt'}
          className={classes['rb-image']}
          //width={480}
          //height={contentType === 'userReview' ? 500 : 380}
          {...ImagePropsObject}
        />
      );
      else if(video){
        return(
          <div ref={containerRef} >
                  <div className={classes['rb-iframe-dev']}>
                     <img src={imageUrl} alt={getImageAlt(imageUrl) || 'imageAlt'} className={classes['rb-iframe']} onClick={() => router.push(`${[video]}`)} /> 
                  </div>
              </div>
        );
      }
    else if (contentType) return getComponents();
  };

  const redirectToAction = (action) => {
    if (action) {
      if (action.startsWith('http')) {
        window.open(action, '_blank');
      } else if (action.startsWith('www')) {
        window.open(`https://${action}`, '_blank');
      } else {
        router.push(action);
      }
    }
  };

  const primaryButton = primaryButtonText && (
    <Button
      fullWidth={!isNotTablet}
      id={'HalfWidthSectionPrimaryBtnId'}
      style={ primaryButtonVariant === 'text' ? { padding: 0, height: 'auto' } : {}}
      variant={primaryButtonVariant ? primaryButtonVariant : 'filled'}
      onClick={() => goToUrl(primaryButtonAction)}
      startIcon={
        primaryButtonStartIcon ? (
          <img src={primaryButtonStartIcon} alt={getImageAlt(primaryButtonStartIcon) || 'button icon'} />
        ) : primaryButtonStartIconVariant ? (
          <Placeholder
            color={
              primaryButtonStartIconVariant === 'string'
                ? primaryButtonStartIconVariant
                : theme.palette.background.white
            }
          />
        ) : null
      }
      endIcon={
        primaryButtonEndIcon ? (
          <img src={primaryButtonEndIcon} alt={getImageAlt(primaryButtonEndIcon) || 'button icon'} />
        ) : primaryButtonEndIconVariant ? (
          <Placeholder
            color={
              primaryButtonEndIconVariant === 'string'
                ? primaryButtonEndIconVariant
                : theme.palette.background.white
            }
          />
        ) : null
      }
    >
      {primaryButtonText}
    </Button>
  );

  const secondaryButton = secondaryButtonText && (
    <Button
      fullWidth={!isNotTablet}
      id={'HalfWidthSectionSecondaryBtnId'}
      style={ primaryButtonVariant === 'text' ? { padding: 0, height: 'auto' } : {}}
      variant={secondaryButtonVariant ? secondaryButtonVariant : 'outlined'}
      onClick={() => goToUrl(secondaryButtonAction)}
      startIcon={
        secondaryButtonStartIconVariant ? (
          <Placeholder
            color={
              secondaryButtonStartIconVariant === 'string'
                ? secondaryButtonStartIconVariant
                : theme.palette.primary.blue
            }
          />
        ) : null
      }
      endIcon={
        secondaryButtonEndIconVariant ? (
          <Placeholder
            color={
              secondaryButtonEndIconVariant === 'string'
                ? secondaryButtonEndIconVariant
                : theme.palette.primary.blue
            }
          />
        ) : null
      }
    >
      {secondaryButtonText}
    </Button>
  );

  const containerPadding = { padding: (isMobile && mobileRootPadding) || rootPadding || '', borderTop: isTopBorder  && '1px solid #CACCD5' };

  return (
    <div
      style={{
        backgroundColor: backgroundColor || null,
        background: background || null,
        backgroundImage: backgroundImage && `url(${backgroundImage})`,
      }}
      className={
        componentType === 'halfBackgroundImage' &&
        isNotMobile &&
        isNotTablet &&
        classes['rb-background-image']
      }
    >
      <Container type="large" style={containerPadding} className={isTopBorder ? classes['rb-root-with-border'] : classes['rb-root']} >
     <div style={{}}>
     {componentType === 'twoSideList' && (
        <Box display="flex" flexDirection={'column'}>
          {tag && (
            <Typography
              className={classes['rb-tag']}
              variant={tagVariant || 'textMd'}
              weight={tagWeight || 'bold'}
              color={tagColor || theme.palette.primary.blue}
              align={'center'}
            >
              {tag}
            </Typography>
          )}
          <Spacing variant="betweenSectionsSmall" />
          {title && (
            <Typography
              variant={titleVariant || 'displayMd'}
              weight={titleWeight || 'bold'}
              color={titleColor || theme.palette.primary.navy}
              align={'center'}
            >
              {contentType === 'userReview' ? `"${title}"` : title}
            </Typography>
          )}
          {!isMobile && (<Spacing variant="twoSideSpacing" />)}
        </Box>
      )}
        <Box
          display="flex"
          className={classes['rb-main-grid']}
          gap={isNotMobile && 64}
          flexDirection={
            isMobile
              ? reverseMobile
                ? 'column-reverse'
                : 'column'
              : reverse
              ? 'row-reverse'
              : 'row'
          }
        >
          
          <Box className={classes['rb-info-grid']}>
            {contentType === 'userReview' && (
              <Box mb={5}>
                <svg xmlns="http://www.w3.org/2000/svg" width="43" height="36" viewBox="0 0 43 36" fill="none">
  <path d="M42.28 35.1501H26.04C24.4533 30.0168 23.66 24.6968 23.66 19.1901C23.66 13.5901 25.1067 9.15676 28 5.89009C30.9867 2.5301 35.3733 0.850098 41.16 0.850098V8.6901C36.4933 8.6901 34.16 11.5368 34.16 17.2301V19.8901H42.28V35.1501ZM18.62 35.1501H2.38C0.793333 30.0168 0 24.6968 0 19.1901C0 13.5901 1.44667 9.15676 4.34 5.89009C7.32667 2.5301 11.7133 0.850098 17.5 0.850098V8.6901C12.8333 8.6901 10.5 11.5368 10.5 17.2301V19.8901H18.62V35.1501Z" fill="url(#paint0_linear_14528_21967)"/>
  <defs>
    <linearGradient id="paint0_linear_14528_21967" x1="-7.22283" y1="15.4276" x2="52.5002" y2="23.4319" gradientUnits="userSpaceOnUse">
      <stop stop-color="#6C9CF9"/>
      <stop offset="1" stop-color="#1160F7"/>
    </linearGradient>
  </defs>
</svg>
              </Box>
            )}
            {((isMobile && isImageInTop ) || (componentType === 'banksList' && isTablet && isNotTablet))&& (
              <>
                {contentType === 'DebitCalculator' && <DebitCalculator />}
                {getContent()}
                {tableColumnsConverted && (
                  <Box justifyContent="center" display="flex">
                    <Box style={{ maxWidth: tableWidth && tableWidth }}>
                      <Table
                        variant="textMd"
                        columnsBackground={columnsBackground}
                        columns={tableColumnsConverted}
                        data={tableDataConverted}
                        tableLastColumnAlign={tableLastColumnAlign}
                      />
                    </Box>
                  </Box>
                )}
              </>
            )}
            {isImageInTop && isMobile && <Spacing variant="betweenSections" />}
            {componentType !== 'twoSideList' && (
              <Box display="flex" flexDirection={'column'} gap={8}>
                {tag && (
                  <Typography
                    className={classes['rb-tag']}
                    variant={tagVariant || 'textMd'}
                    weight={tagWeight || 'bold'}
                    color={tagColor || theme.palette.primary.blue}
                    align={componentType === 'hero' && isMobile ? 'center' : 'left'}
                  >
                    {tag}
                  </Typography>
                )}
                {title && (
                  <Typography
                    className={!(componentType === 'banksList' && isTablet && isNotTablet) && classes['rb-title']}
                    variant={titleVariant || 'displayMd'}
                    weight={titleWeight || 'bold'}
                    color={titleColor || theme.palette.primary.navy}
                    align={((componentType === 'hero' && isMobile) || (componentType === 'banksList' && isTablet && isNotTablet)) ? 'center' : 'left'}
                    style={{
                      display: componentType === 'hero' && isMobile && 'block',
                      textWrap: columnSectionProps && !isMobile ? 'nowrap' : 'wrap',
                    }}
                  >
                    {contentType === 'userReview' ? `"${title}"` : title}
                  </Typography>
                )}
              </Box>
            )}
            {currencyInfo && contentType === 'ShareConverter' ? (
              <Box mt={4}>
                <Typography
                  span={true}
                  className={classes['rb-text']}
                  variant={textVariant || 'textMd'}
                  weight={textWeight || 'regular'}
                  color={textColor || theme.palette.input.body}
                  align={componentType === 'hero' && !isNotMobile ? 'center' : 'left'}
                >
                  {`Today's range: ${currencyInfo?.range}. Yesterday's rate ${currencyInfo?.previousDay}. Change for today ${currencyInfo?.change}`}
                </Typography>
              </Box>
            ) : null}
            <Box>
              {text && (
                <>
                  <Spacing variant="betweenHeaderToSubheader" />
                  <Typography
                    // span={true}
                    className={classes['rb-text']}
                    variant={textVariant || 'textMd'}
                    weight={textWeight || 'regular'}
                    color={textColor || theme.palette.input.body}
                    align={((componentType === 'hero' && !isNotMobile) || ((componentType === 'banksList' && isTablet && isNotTablet))) ? 'center' : 'left'}
                  >
                    {typeof text === 'string' ? (
                      <Box
                        display="flex"
                        justifyContent={
                          componentType === 'hero' && !isNotMobile ? 'center' : 'left'
                        }
                      >
                        {' '}
                        <Markdown children={text} />{' '}
                      </Box>
                    ) : (
                      text
                    )}
                  </Typography>
                </>
              )}
              {
                subtitle && <>
                <Spacing variant="betweenSummaryCards" />
                <Typography
                  className={classes['rb-subTitle']}
                  variant={subtitleVariant || 'textXl'}
                  weight={subtitleWeight || 'bold'}
                  color={subtitleColor || theme.palette.primary.navy}
                  align={componentType === 'hero' && !isNotMobile ? 'center' : 'left'}
                >
                  {subtitle}      
                </Typography>
                </>
              }
              {
                subText && <>
                <Spacing variant="betweenHeaderToSubheader" />
                <Typography
                  className={classes['rb-subText']}
                  variant={subTextVariant || 'textMd'}
                  weight={subTextWeight || 'regular'}
                  color={subTextColor || theme.palette.input.body}
                  align={componentType === 'hero' && !isNotMobile ? 'center' : 'left'}
                >
                    {typeof subText === 'string' ? <Markdown children={subText} /> : subText}      
                </Typography>
                </>
              }
            </Box>
            {!isMainListButton    &&  (
              <>
                {(primaryButton || secondaryButton) &&  componentType !== 'bottomImage' && (
                  <Spacing variant="betweenSections" />
                )}
                {(primaryButton || secondaryButton) && componentType !== 'bottomImage'  && (
                  <Box display="flex" flexDirection={!isNotMobile ? 'column' : 'row'} gap={12} justifyContent={(componentType === 'banksList' && isTablet && isNotTablet) && 'center'}>
                    {primaryButton}
                    {secondaryButton}
                  </Box>
                )}
                {(primaryButton || secondaryButton) && !isNotMobile && componentType === 'hero' && (
                  <Spacing variant="betweenSections" />
                )}
                {(primaryButton || secondaryButton) && !isNotMobile && componentType === 'hero' && (
                  <Box display="flex" flexDirection={!isNotMobile ? 'column' : 'row'} gap={12}>
                    {primaryButton}
                    {secondaryButton}
                  </Box>
                )}
              </>
            )}
            {contentType === 'userReview' && (
              <Box mt={10} display="flex" flexDirection="column" gap={12}>
                <Box display="flex" gap={8}>
                  {Array(parseInt(userRating))
                    .fill(0)
                    .map((_, index) => (
                      <svg
                        key={index}
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="19"
                        viewBox="0 0 18 19"
                        fill="none"
                      >
                        <path
                          d="M9.35279 0.530828L11.9212 5.98908L17.6625 6.86456C17.7354 6.87589 17.8038 6.90825 17.86 6.95802C17.9162 7.00778 17.9581 7.07297 17.9808 7.14624C18.0035 7.2195 18.0061 7.29794 17.9885 7.37271C17.9708 7.44748 17.9336 7.51561 17.8809 7.56945L13.7272 11.8172L14.7079 17.8162C14.7206 17.8921 14.7126 17.9701 14.685 18.0415C14.6574 18.1128 14.6112 18.1747 14.5517 18.22C14.4922 18.2653 14.4217 18.2923 14.3482 18.2978C14.2748 18.3034 14.2013 18.2874 14.1362 18.2515L9.00059 15.4197L3.86501 18.2524C3.7999 18.2883 3.72643 18.3045 3.65297 18.299C3.5795 18.2935 3.50898 18.2666 3.44942 18.2213C3.38987 18.176 3.34366 18.1141 3.31606 18.0427C3.28845 17.9714 3.28055 17.8933 3.29326 17.8175L4.27396 11.8172L0.119092 7.56945C0.0664096 7.51561 0.0291506 7.44748 0.0115104 7.37271C-0.00612975 7.29794 -0.00345047 7.2195 0.0192466 7.14624C0.0419436 7.07297 0.0837569 7.00778 0.139978 6.95802C0.196198 6.90825 0.264593 6.87589 0.33746 6.86456L6.07883 5.98908L8.64838 0.530828C8.68042 0.46166 8.73046 0.403328 8.79275 0.362512C8.85504 0.321697 8.92707 0.300049 9.00059 0.300049C9.0741 0.300049 9.14613 0.321697 9.20842 0.362512C9.27071 0.403328 9.32075 0.46166 9.35279 0.530828Z"
                          fill="#FAC948"
                        />
                      </svg>
                    ))}
                </Box>
                <Typography variant="textXl" weight="semibold" color={theme.palette.primary.navy}>
                  {userName}
                  {userOccupation && (
                    <span className={classes['rb-userOccupation']}> {userOccupation}</span>
                  )}
                </Typography>
              </Box>
            )}
            {
              componentType === 'twoSideTable' && secondTableColumnsConverted && (
                <Box justifyContent="center" display="flex">
                  <Box style={{ maxWidth: tableWidth && tableWidth }}>
                    <Table
                      variant="textMd"
                      columnsBackground={columnsBackground}
                      columns={secondTableColumnsConverted}
                      data={secondTableDataConverted}
                      tableLastColumnAlign={tableLastColumnAlign}
                    />
                  </Box>
                </Box>
              )
            }
            {list && (componentType === 'graph' ? <Spacing variant='betweenHeaderToSubheader' /> :<Spacing variant={ "titleToCard" } />)}
            {list && <Box display="flex" flexDirection={'column'} gap={componentType === 'graph' ? 24 : 16}>
              {
                list.map((item, index) => {
                  return (
                    <Box key={index} display="flex" flexDirection="row" gap={12}>
                      {componentType === 'graph' ? (
                        <div>
                          <div
                            className={classes['rb-graph']}
                            style={{ backgroundColor: item.color }}
                          ></div>
                        </div>
                      ) : (
                        <div className={classes['rb-checkIcon-container']}>
                          <CheckIconCircled />
                        </div>
                      )}
                      <Typography
                        className={classes['rb-list-item']}
                        variant={'textMd'}
                        weight={'regular'}
                        color={theme.palette.input.body}
                        align="left"
                      >
                        <Markdown>{componentType === 'graph' ? item.text : item}</Markdown>
                      </Typography>
                    </Box>
                  );
                })}
            </Box>}
            {isMainListButton  &&  (
              <>
                {(primaryButton || secondaryButton) && (isNotTablet || (list && isMobile)) && (
                  <Spacing variant="betweenSections" />
                )}
                {(primaryButton || secondaryButton) && isNotTablet && (
                  <Box display="flex" flexDirection={!isNotMobile ? 'column' : 'row'} gap={12}>
                    {primaryButton}
                    {secondaryButton}
                  </Box>
                )}
                {(primaryButton || secondaryButton) && ((!isNotMobile && componentType === 'hero') || (list && isMobile)) && (
                  <Box display="flex" flexDirection={!isNotMobile ? 'column' : 'row'} gap={12}>
                    {primaryButton}
                    {secondaryButton}
                  </Box>
                )}
              </>
            )}
             {bottomList && componentType === 'bottomReview' && (
          <Box
            mt={10}
            display="flex"
            flexDirection={!isNotTablet ? 'column' : 'row'}
            justifyContent={'flex-start'}
            alignItems={'stretch'}
            gap={12}
          >
           {bottomList?.map((item, index) => (
             <Box
                display="flex"
                key={index}
                justifyContent="center"
                alignItems={isNotTablet ? 'flex-start' : 'center'}
                gap={isNotMobile  ? 16 : 12}
                onClick={() => (item.titleAction ? redirectToAction(item.titleAction) : null)}
                style={{maxWidth: `${item?.cardWidth}px`}}
                className={
                  (item.rating)
                    ? null
                    : `
                    ${classes['rb-bottomListItems-root']}
                    ${item.titleAction && classes['rb-bottomListItems-link']}
                    ${
                      backgroundColor
                        ? classes['rb-bottomListItems-regular']
                        : classes['rb-bottomListItems-light']
                    }
                `
                }
              >
                <div className={classes['rb-bottomListItems-icon']}>
                  {item.icon ? (
                    <img
                      src={item.icon}
                      alt='imageAlt'
                      width={parseInt(item.iconWidth) || 20}
                      height={parseInt(item.iconHeight) || 20}
                      onClick={() => item.imageAction && router.push(item.imageAction)}
                    />
                  ) : (
                    <CheckIconCircled width={20} height={20} />
                  )}
                </div>
               { <Box display="flex" flexDirection="column" gap={4}>
                  {item.rating && (
                    <Box display="flex" gap={8}>
                      <Typography
                        variant="textMd"
                        weight="semibold"
                        color={theme.palette.primary.navy}
                      >
                        {item.rating}
                      </Typography>
                      {Array(parseInt(item.rating))
                        .fill(0)
                        .map((_, index) => (
                          <svg
                            key={index}
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="19"
                            viewBox="0 0 18 19"
                            fill="none"
                          >
                            <path
                              d="M9.35279 0.530828L11.9212 5.98908L17.6625 6.86456C17.7354 6.87589 17.8038 6.90825 17.86 6.95802C17.9162 7.00778 17.9581 7.07297 17.9808 7.14624C18.0035 7.2195 18.0061 7.29794 17.9885 7.37271C17.9708 7.44748 17.9336 7.51561 17.8809 7.56945L13.7272 11.8172L14.7079 17.8162C14.7206 17.8921 14.7126 17.9701 14.685 18.0415C14.6574 18.1128 14.6112 18.1747 14.5517 18.22C14.4922 18.2653 14.4217 18.2923 14.3482 18.2978C14.2748 18.3034 14.2013 18.2874 14.1362 18.2515L9.00059 15.4197L3.86501 18.2524C3.7999 18.2883 3.72643 18.3045 3.65297 18.299C3.5795 18.2935 3.50898 18.2666 3.44942 18.2213C3.38987 18.176 3.34366 18.1141 3.31606 18.0427C3.28845 17.9714 3.28055 17.8933 3.29326 17.8175L4.27396 11.8172L0.119092 7.56945C0.0664096 7.51561 0.0291506 7.44748 0.0115104 7.37271C-0.00612975 7.29794 -0.00345047 7.2195 0.0192466 7.14624C0.0419436 7.07297 0.0837569 7.00778 0.139978 6.95802C0.196198 6.90825 0.264593 6.87589 0.33746 6.86456L6.07883 5.98908L8.64838 0.530828C8.68042 0.46166 8.73046 0.403328 8.79275 0.362512C8.85504 0.321697 8.92707 0.300049 9.00059 0.300049C9.0741 0.300049 9.14613 0.321697 9.20842 0.362512C9.27071 0.403328 9.32075 0.46166 9.35279 0.530828Z"
                              fill="#FAC948"
                            />
                          </svg>
                        ))}
                    </Box>
                  )}
                  {item.title && (
                    <Typography
                      variant="textMd"
                      weight="semibold"
                      color={theme.palette.primary.navy}
                      className={item.titleAction && `${classes['rb-bottomListItems-titleAction']}`}
                      align="left"
                    >
                      <Markdown children={item.title} />
                    </Typography>
                  )}
                  {item.text && (
                    <Typography paragraph={true} variant="textSm" align="left">
                      <Markdown children={item.text} />
                    </Typography>
                  )}
                </Box>}
              </Box>
            ))}
          </Box>
        )}
        {
          (componentType === 'bottomImage' ||  componentType === 'bottomImageType2') && <>
           {
          imageListData && (<Box
            mt={isNotMobile ? 10 : 8}
            display="flex"
            flexDirection={'row'}
            justifyContent={'flex-start'}
            alignItems={'center'}
            gap={(componentType === 'bottomImage' || (componentType === 'bottomImageType2' && isMobile)) ? 12 : (componentType === 'bottomImageType2' && !isMobile) ? 0 : 16}
          >
           {  (componentType === 'bottomImage' ||  componentType === 'bottomImageType2') && imageListData?.map((item, index) => ( <div className={item.icon && classes['rb-bottomListItems-icon']}>
            { (item?.desktopIcon && !isMobile) && <Box display='flex' gap={16} alignItems='center'  >
            <img
            src={item.desktopIcon}
            alt={getImageAlt(item.desktopIcon) || 'bottom list image'}
            width={parseInt(item.desktopIconWidth) || 20}
            height={parseInt(item.desktopIconHeight) || 20}
            onClick={() => item.imageAction && router.push(item.imageAction)}
          />
          <Typography
            variant="textLg"
            weight="medium"
            color={item?.textColor || theme.palette.primary.navy }
            style={{width: "192px"}}
            align="left">{item?.text}</Typography>
          </Box>}
           { isMobile && item?.mobileIcon && <img
            src={item.mobileIcon}
            alt={getImageAlt(item.mobileIcon) || 'bottom list image'}
            width={parseInt(item.mobileIconWidth) || 20}
            height={parseInt(item.mobileIconHeight) || 20}
            onClick={() => item.imageAction && router.push(item.imageAction)}
          /> } 
          { item.icon &&
            <img
              src={item.icon}
              alt={getImageAlt(item.icon) || 'bottom list image'}
              width={parseInt(item.iconWidth) || 20}
              height={parseInt(item.iconHeight) || 20}
              onClick={() => item.imageAction && router.push(item.imageAction)}
            />}
          { !item.icon && !item.desktopIcon && !item.mobileIcon &&
              <CheckIconCircled width={20} height={20} />
           }
          </div>))}</Box>)
        }

{(((primaryButton || secondaryButton) && !isNotMobile ) || (componentType === 'bottomImage' && (primaryButtonText || secondaryButtonText))  )&& (
          isNotMobile ? <Spacing variant="betweenSections" /> : <Spacing variant="betweenSectionToInputFields" />
        )}
        {(((primaryButton || secondaryButton) && !isNotMobile ) || componentType === 'bottomImage')&& (
          <Box display="flex" flexDirection={!isNotMobile ? 'column' : 'row'} gap={12}>
            {primaryButton}
            {secondaryButton}
          </Box>
        )}
          </>
        }
          </Box>
          {((!isMobile && isImageInTop) || (!isImageInTop && !(componentType === 'banksList' && isTablet && isNotTablet))) && (
            <>
            {isMobile && !columnSectionProps && <Spacing variant="betweenSections" /> }
            <Box justifyContent={reverse && video && 'center'}  className={classes['rb-content-grid']}>
              {contentType === 'DebitCalculator' && <DebitCalculator />}
              {getContent()}
              {tableColumnsConverted && (
                <Box justifyContent="center" display="flex">
                  <Box style={{ maxWidth: tableWidth && tableWidth }}>
                    <Table
                      variant="textSm"
                      columnsBackground={columnsBackground}
                      columns={tableColumnsConverted}
                      data={tableDataConverted}
                      tableLastColumnAlign={tableLastColumnAlign}
                    />
                  </Box>
                </Box>
              )}
            </Box>
            </>
          )}
        </Box>

        {columnSectionProps && (
          <>
          {title && <Spacing variant="twoSideListSection" />}
          <Box
            // mt={title && isMobile ? 8 : title ? 16 : !title && isMobile ? 8 : 0}
            display="flex"
            flexDirection={textInSameColumn && !isMobile ? 'row' : 'column'}
            gap={isMobile ? 60 : 80}
          >
            {ColumnSectionProps &&
              ColumnSectionProps?.map((row, index) => {
                return (
                  <Box
                    key={`ColumnSection-${index}`}
                    display="flex"
                    flexDirection={textInSameColumn ? 'column' : isMobile ? 'column' : 'row'}
                    gap={textInSameColumn ? 16 : isMobile ? 32 : 64}
                  >
                    <Box className={textInSameColumn ? '' : classes['rb-faq-box']}>
                   { row?.tag && <Typography
                        variant={row.tagVariant || 'textLg'}
                        weight="bold"
                        color={row?.tagColor || theme.palette.primary.blue}
                      >
                        <Markdown>{row.tag}</Markdown>
                      </Typography>}
                      <Spacing variant="betweenSectionsSmall" />
                      <Typography
                        variant={row.titleVariant}
                        weight="bold"
                        color={row?.titleColor || theme.palette.primary.navy}
                      >
                        <Markdown>{row.title}</Markdown>
                      </Typography>
                     {
                      row?.buttonText && <>
                       <Spacing variant="betweenSectionToInputFields"  />
                      <Button
                      fullWidth={!isNotTablet}
                      id={'HalfWidthSectionPrimaryBtnId'}
                      variant={row?.buttonVariant ? row?.buttonVariant : 'filled'}
                      onClick={() => goToUrl(row?.buttonAction)}>{row?.buttonText}</Button>
                      </>
                     }
                    </Box>
                    <Box className={textInSameColumn ? '' : classes['rb-faq-box']}>
                      <Typography variant={row.textVariant} color={row?.textColor}>
                        <Markdown>{row.text}</Markdown>
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
          </Box>
          </>
        )}

        {twoSideListData && (
          <Box>
            {componentType === 'twoSideText'
              ? twoSideListData.map((twoSideListDetail, index) =>
                  isMobile ? (
                    <>
                      <Box className={classes['rb-faq-item']}>
                        <Box className={classes['rb-faq-box']}>
                          <Typography
                            variant="text2XL"
                            weight="bold"
                            color={theme.palette.primary.navy}
                          >
                            <Markdown>
                              {twoSideListDetail.LTitle && twoSideListDetail.LTitle}
                              {twoSideListDetail.RTitle && twoSideListDetail.RTitle}
                            </Markdown>
                          </Typography>
                        </Box>
                        {(twoSideListDetail.LTitle || twoSideListDetail.RTitle) && <Spacing variant="titleToCard" />}
                        <Box className={classes['rb-faq-box']}>
                          <Typography variant="textLg">
                            <Markdown>
                              {twoSideListDetail.LAnswer && twoSideListDetail.LAnswer}
                              {twoSideListDetail.RAnswer && twoSideListDetail.RAnswer}
                            </Markdown>
                          </Typography>
                        </Box>
                      </Box>
                    </>
                  ) : (
                    <Box
                      key={index}
                      gap={72}
                      display="flex"
                      justifyContent="space-between"
                      className={classes['rb-faq-item']}
                    >
                      <Box className={classes['rb-faq-box']}>
                        <Typography
                          variant={
                            twoSideListDetail.titleVariant
                              ? twoSideListDetail.titleVariant
                              : 'displayMd'
                          }
                          weight="bold"
                          color={theme.palette.primary.navy}
                        >
                          <Markdown>
                            {twoSideListDetail.LTitle && twoSideListDetail.LTitle}
                          </Markdown>
                        </Typography>
                        <Spacing variant="betweenCards" />{' '}
                        <Typography variant="textLg">
                          <Markdown>
                            {twoSideListDetail.LAnswer && twoSideListDetail.LAnswer}
                          </Markdown>
                        </Typography>
                      </Box>
                      <Box className={classes['rb-faq-box']}>
                        <Typography
                          variant={
                            twoSideListDetail.titleVariant
                              ? twoSideListDetail.titleVariant
                              : 'displayMd'
                          }
                          weight="bold"
                          color={theme.palette.primary.navy}
                        >
                          <Markdown>
                            {twoSideListDetail.RTitle && twoSideListDetail.RTitle}
                          </Markdown>
                        </Typography>
                        <Spacing variant="betweenCards" />
                        <Typography variant="textLg">
                          <Markdown>
                            {twoSideListDetail.RAnswer && twoSideListDetail.RAnswer}
                          </Markdown>
                        </Typography>
                      </Box>
                    </Box>
                  )
                )
              :   (
                    <Box
                      gap={72}
                      display={"flex"}
                      justifyContent="space-between"
                      className={componentType === 'twoSideList' ? classes['rb-faq-item-list'] :classes['rb-faq-item']}
                    >
                    <Box className={classes['rb-faq-box']} display='flex'>  
  <div className={isBackgroundCard ?  classes['rb-card-container'] : classes['rb-card-container-without-card']}>
    <div className={isBackgroundCard ? classes['rb-card'] : classes['rb-without-card']} style={{background: twoSideCardColor ? twoSideCardColor : '#FFF'}}>
     { leftTitle && <Typography variant='textXl' weight='bold' color={leftTitleColor || theme.palette.primary.blue}>
        <Markdown>{leftTitle}</Markdown>
      </Typography>}
      {leftTitle && (isNotMobile ?  <Spacing variant="titleToCard" /> : <Spacing variant="betweenHeaderToSubheader" />)}
      <Box gap={(isBackgroundCard || isMobile) ? 16 : 24} display='flex' flexDirection='column'>
      {twoSideListData.map((twoSideListDetail, index) => componentType === 'twoSideList' ? (
      twoSideListDetail.LText && <div className={classes['rb-two-side-list']}>
          <div>
            {twoSideListDetail.LIcon ? (
              <img src={twoSideListDetail.LIcon} width={24} height={24} alt={'icon'}/>
            ) : (
              <CheckIconCircled />
            )}
          </div>
          <Typography variant="textMd" >
            <Markdown>{twoSideListDetail.LText}</Markdown>.
          </Typography>
        </div>
      ) : (
        <Typography
          variant="text2XL"
          weight="bold"
          color={theme.palette.primary.navy}
        >
          <Markdown>{twoSideListDetail.question}</Markdown>
        </Typography>
      ))}
      </Box>
    </div>

    <div className={isBackgroundCard ? classes['rb-card'] : classes['rb-without-card']} style={{background: twoSideCardColor ? twoSideCardColor : '#FFF'}}>
    {rightTitle && <Typography variant='textXl' weight='bold' color={rightTitleColor || theme.palette.primary.blue}>
        <Markdown>{rightTitle}</Markdown>
      </Typography>}
      { rightTitle && (isNotMobile ?  <Spacing variant="titleToCard" /> : <Spacing variant="betweenHeaderToSubheader" />)}
       <Box gap={isBackgroundCard ? 16 : 24} display='flex' flexDirection='column'>
       {twoSideListData.map((twoSideListDetail, index) =>componentType === 'twoSideList' ? (
       twoSideListDetail.RText && <div className={classes['rb-two-side-list']}>
          <div>
            {twoSideListDetail.RIcon ? (
              <img src={twoSideListDetail.RIcon} width={24} height={24} alt={"icon"} />
            ) : (
              <CheckIconCircled />
            )}
          </div>
          <Typography variant="textMd" >
            <Markdown>{twoSideListDetail.RText}</Markdown>.
          </Typography>
        </div>
      ) : (
        <Typography
          variant="text2XL"
          weight="bold"
          color={theme.palette.primary.navy}
        >
          <Markdown>{twoSideListDetail.question}</Markdown>
        </Typography>
      ))}
       </Box>
    </div>
  </div>
</Box>
                    </Box>
                  )
                }
          </Box>
        )}
      { mainList &&  <Spacing variant="rateTableSection" />}
        {mainList && (
          <Box
            display="flex"
            flexDirection={!isNotMobile ? 'column' : 'row'}
            justifyContent={(mainListGrid === 2 || mainListGrid === '2' ) && 'space-between'}
            alignItems={'flex-start'}
            flexWrap={'wrap'}
            gap={(mainListGrid === 2 || mainListGrid === '2' ) ? '32px 64px' :"32px 24px"}
            rowGap={isNotMobile ? 32 : 32} 
            columnGap={(mainListGrid === 2 || mainListGrid === '2' ) ? isNotMobile ? 64 : 32 : 24}
          >
            {mainList?.map((item, index) => (
              <Box
                display={!item?.iconInTop && "flex"}
                key={index}
                justifyContent="left"
                alignItems="flex-start"
                gap={isMobile ? 8 : 16}
                className={`
                    ${classes['rb-mainListItems-root']}
                `}
                style={{ width: !isNotMobile ? '100%' : `calc((100% / ${mainListGrid}) - ${(mainListGrid === 2 || mainListGrid === '2' )  ? '5%' : '2%'})` }}
              >
                <Box
                  display={!item?.iconInTop && "flex"}
                  flexDirection="column"
                  className={classes['rb-mainListItems-icon']}
                >
                  {item.icon ? (
                    <img
                      src={item.icon}
                      width={!isNotTablet ? 24 : 36}
                      height={!isNotTablet ? 24 : 36}
                      alt={'main list icon'}
                    />
                  ) : (
                    <Placeholder
                      color={theme.palette.primary.blue}
                      width={!isNotTablet ? 24 : 36}
                      height={!isNotTablet ? 24 : 36}
                    />
                  )}
                </Box>
                {!isNotMobile && <Spacing variant="betweenHeaderToSubheader" />}
                <div className={!item?.iconInTop && classes['rb-mainListItems-text-box']}>
                  <Typography
                    variant="textMd"
                    weight="semibold"
                    color={theme.palette.primary.navy}
                    align="left"
                  >
                    <Markdown children={item.title} />
                  </Typography>
                  <Typography
                    paragraph={true}
                    variant="textSm"
                    align="left"
                    className={(mainListGrid === 2 || mainListGrid === '2' )  ? classes['rb-mainListItems-text'] :classes['rb-mainListItems-text-type2']}
                  >
                    <Markdown children={item.text} />
                  </Typography>
                </div>
              </Box>
            ))}
          </Box>
        )}
        {!(componentType === 'bottomReview' || componentType === 'bottomImage') && bottomList &&  <Spacing variant="rateTableSection" />}
         {bottomList && componentType !== 'bottomReview' && (
          <Box
            display="flex"
            flexDirection={!isNotTablet ? 'column' : 'row'}
            justifyContent={'flex-start'}
            alignItems={'stretch'}
            gap={componentType === 'bottomImage' ? 12 :16}
          >
           {bottomList?.map((item, index) => (
             <Box
                display="flex"
                key={index}
                justifyContent="center"
                alignItems={'flex-start' }
                gap={isNotMobile  ? 16 : 12}
                onClick={() => (item.titleAction ? redirectToAction(item.titleAction) : null)}
                style={{maxWidth: `${item?.cardWidth}px`}}
                className={
                  (item.rating || componentType === 'bottomImage')
                    ? null
                    : `
                    ${classes['rb-bottomListItems-root']}
                    ${item.titleAction && classes['rb-bottomListItems-link']}
                    ${
                      backgroundColor
                        ? classes['rb-bottomListItems-regular']
                        : classes['rb-bottomListItems-light']
                    }
                `
                }
              >
                <div className={classes['rb-bottomListItems-icon']}>
                  {item.icon ? (
                    <img
                      src={item.icon}
                      width={parseInt(item.iconWidth) || 20}
                      height={parseInt(item.iconHeight) || 20}
                      onClick={() => item.imageAction && router.push(item.imageAction)}
                      alt='bottom list icon'
                    />
                  ) : (
                    <CheckIconCircled width={20} height={20} />
                  )}
                </div>
               {componentType !== 'bottomImage' && <Box display="flex" flexDirection="column" gap={4}>
                  {item.rating && (
                    <Box display="flex" gap={8}>
                      <Typography
                        variant="textMd"
                        weight="semibold"
                        color={theme.palette.primary.navy}
                      >
                        {item.rating}
                      </Typography>
                      {Array(parseInt(item.rating))
                        .fill(0)
                        .map((_, index) => (
                          <svg
                            key={index}
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="19"
                            viewBox="0 0 18 19"
                            fill="none"
                          >
                            <path
                              d="M9.35279 0.530828L11.9212 5.98908L17.6625 6.86456C17.7354 6.87589 17.8038 6.90825 17.86 6.95802C17.9162 7.00778 17.9581 7.07297 17.9808 7.14624C18.0035 7.2195 18.0061 7.29794 17.9885 7.37271C17.9708 7.44748 17.9336 7.51561 17.8809 7.56945L13.7272 11.8172L14.7079 17.8162C14.7206 17.8921 14.7126 17.9701 14.685 18.0415C14.6574 18.1128 14.6112 18.1747 14.5517 18.22C14.4922 18.2653 14.4217 18.2923 14.3482 18.2978C14.2748 18.3034 14.2013 18.2874 14.1362 18.2515L9.00059 15.4197L3.86501 18.2524C3.7999 18.2883 3.72643 18.3045 3.65297 18.299C3.5795 18.2935 3.50898 18.2666 3.44942 18.2213C3.38987 18.176 3.34366 18.1141 3.31606 18.0427C3.28845 17.9714 3.28055 17.8933 3.29326 17.8175L4.27396 11.8172L0.119092 7.56945C0.0664096 7.51561 0.0291506 7.44748 0.0115104 7.37271C-0.00612975 7.29794 -0.00345047 7.2195 0.0192466 7.14624C0.0419436 7.07297 0.0837569 7.00778 0.139978 6.95802C0.196198 6.90825 0.264593 6.87589 0.33746 6.86456L6.07883 5.98908L8.64838 0.530828C8.68042 0.46166 8.73046 0.403328 8.79275 0.362512C8.85504 0.321697 8.92707 0.300049 9.00059 0.300049C9.0741 0.300049 9.14613 0.321697 9.20842 0.362512C9.27071 0.403328 9.32075 0.46166 9.35279 0.530828Z"
                              fill="#FAC948"
                            />
                          </svg>
                        ))}
                    </Box>
                  )}
                  {item.title && (
                    <Typography
                      variant="textMd"
                      weight="semibold"
                      color={theme.palette.primary.navy}
                      className={item.titleAction && `${classes['rb-bottomListItems-titleAction']}`}
                      align="left"
                    >
                      <Markdown children={item.title} />
                    </Typography>
                  )}
                  {item.text && (
                    <Typography paragraph={true} variant="textSm" align="left">
                      <Markdown children={item.text} />
                    </Typography>
                  )}
                </Box>}
              </Box>
            ))}
          </Box>
        )}
     </div>
      </Container>
    </div>
  );
};

export default HalfWidthSection;
