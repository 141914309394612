import React, { useEffect, useState } from "react";
import Typography from "../../../../../remitbee/components/typography/Typography";
import theme from "../../../../../remitbee/theme/Theme";
import Box from "../../../../../remitbee/components/box/Box";
import classes from "./BlogSection.module.scss";
import Spacing from "../../../../../remitbee/styles/spacing/Spacing";
import { useMediaQuery } from "@mui/material";
import { useRouter } from "next/router";
import { parseArrFromString } from '../../../../../shared/utility';
import Container from "../../../../../remitbee/components/container/Container";
import strapiQuery from "../../../../../settings/apollo/strapi/query";
import { LATEST_BLOG_ARTICLES } from "../../../apollo/queries/blog/article/article";
import { snackbar } from "../../../../../remitbee/components/snackbar/SnackBar";


interface Props {
    heading?: string;
    articles: {
        title: string;
        description: string;
        imageUrl: string;
        url: string;
        author: string;
        date: string;
    }[];
    numberOfArticles?: number;
    filterByCategory?: string;
    filterByTag?: string;
}

const BlogSection: React.FC<Props> = ({
    heading,
    articles,
    numberOfArticles = 3,
    filterByCategory,
    filterByTag
}) => {
    const [articlesData, setArticlesData] = useState([]);
    const router = useRouter();
    const isTablet = useMediaQuery(`(min-width:${theme.breakpoints.md}px) and (max-width:${theme.breakpoints.lg}px)`);

    const loadLatestArticles = async () => {
        const { success, data, errorMessage } = await strapiQuery(LATEST_BLOG_ARTICLES, { limit: numberOfArticles, category: filterByCategory, tag: filterByTag });

        if (!success || errorMessage) {
            snackbar.error(errorMessage || "Failed to load latest articles.", 3000);
        }

        if (data?.blogArticles && data?.blogArticles?.data) {
            const formattedData = data?.blogArticles?.data?.map(article => ({
                url: `/blog/${article.attributes.slug}`,
                date: new Date(article.attributes.published_datetime).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric'
                }),
                title: article.attributes.title,
                author: "By RemitBee",
                imageUrl: article.attributes.image.data.attributes.url,
                description: article.attributes.excerpt || "No description available"
            }));
            setArticlesData(formattedData);
        }
    }

    useEffect(() => {
        if (!articles) {
            loadLatestArticles();
        } else setArticlesData(typeof articles === 'string' ? parseArrFromString(articles) : articles);
    }, [articles]);

    const navigateToUrl = (url) => {
        router.push(url);
    }

    const card = (article: { url?: any; imageUrl?: any; title?: any; description?: any; author?: any; date?: any; }, imageBoxClass: string, index: number) => {
        return (
            <Box
                display='flex'
                className={`${imageBoxClass} ${classes['rb-blog-section-card']}`}
                onClick={() => navigateToUrl(article.url)}
                key={index}
                flexDirection={isTablet ? 'row' : 'column'}
            >
                <div className={classes['rb-blog-section-image']}>
                    <img className={`${classes['rb-blog-image']}`} src={article.imageUrl} alt="Article image" />
                </div>
                <div>
                    <Box pl={isTablet && 5}>
                        {!isTablet && <Spacing variant="blog" />}
                        <Typography variant="text2XL" weight="semibold" color={theme.palette.primary.navy}>{article.title}</Typography>
                        <Spacing variant="betweenSectionsSmall" />
                        <Typography variant="body1">{article.description}</Typography>
                        <Spacing variant="contentToCtaSmall" />
                        <Box display="flex" alignItems="center" gap={8}>
                            <Typography variant="label2" weight="semibold">{article.author}</Typography>
                            <li className={classes['rb-blog-text']}></li>
                            <Typography variant="label2" >{`${article.date}`}</Typography>
                        </Box>
                    </Box>
                </div>
            </Box>
        )
    }

    return (
        <Container type="large">
            <div className={classes['rb-blog-section-root']}>
                <Typography weight="bold" color={theme.palette.primary.navy} variant="displayMd">{heading}</Typography>
                <Spacing variant="betweenSectionToInputFields" />
                <div className={classes['rb-blog-section-image-box']}>
                    {articlesData?.map((article, index) => (
                        card(article, classes['rb-blog-section-image'], index)
                    ))}
                </div>
            </div>
        </Container>
    );
};

export default BlogSection;