import React, { useEffect, useState } from 'react'
import Box from '../../../../../remitbee/components/box/Box'
import { getCountriesPublicQuery, getOperatorsByServiceAndCountryPublic } from '../../../../../redux/services/actions';
import CountrySelection from '../../../../services-v2/CountrySelection';
import { Skeleton } from '@mui/material';
import OperatorCardPublic from '../../../components/GiftCardPublic/OperatorCardPublic';
import { getImageAlt } from '../../../../../shared/utility';
import Button from '../../../../../remitbee/components/button/Button';
import { MOCK_DATA } from './StorybookMock';
import { snackbar } from '../../../../../remitbee/components/snackbar/SnackBar';

const GiftContainer = ({ classes, storybookMock, isMobile, serviceId, defaultCountry = 'United States', serviceType }) => {
        const [viewMore,setViewMore] = useState(false);
        const [countriesLoading,setCountriesLoading] = useState(false)
        const [operatorsLoading,setOperatorsLoading] = useState(false)
        const [countries,setCountries] = useState([]);
        const [operators,setOperators] = useState([]);
        const [selectedCountry,setSelectedCountry] = useState(0);
        
    
        const handleCountryChange = (newCountry) => {
            setSelectedCountry(newCountry?.countryId)
        }
    
  const loadCountries = async () => {
    try {
      setCountriesLoading(true)
      let selected_country = null;
      if (storybookMock) {
        setCountries(MOCK_DATA.TopUp.countries)
        selected_country = MOCK_DATA.TopUp.countries.filter(country => country.name === 'United States')[0]?.countryId;
        setSelectedCountry(selected_country);
        return;
      }
      let { data, success } = await getCountriesPublicQuery(serviceId);
      if (success && data && data.getCountriesPublic) {
        const countryList = data.getCountriesPublic?.countries;
        if (!countryList) return;

        setCountries(countryList);
        countryList.forEach(country => {
          if (country.name === defaultCountry)
            selected_country = country.countryId;
        });
        if (!selected_country) selected_country = countryList.filter(country => country.name === 'United States')[0]?.countryId || countryList[0]?.countryId;
        setSelectedCountry(selected_country)
      }
    } catch (error) {
      snackbar.error('Failed to load countries', 3000)
    } finally {
      setCountriesLoading(false)
    }
  }
    
         const loadOperatorsByCountry = async (countryId) => {
          if(!selectedCountry) return;
          if(storybookMock) {
            setOperators(MOCK_DATA[serviceType].operators)
            return;
          }
          const { data, success } = await getOperatorsByServiceAndCountryPublic(countryId, serviceId);
          if(success && data && data.getOperatorsByServicesAndCountryPublic) {
            setOperators(data.getOperatorsByServicesAndCountryPublic?.operators)
          }
         }
    
        useEffect(() => {
           loadCountries()
        }, [])
    
        useEffect(() => {
          setOperatorsLoading(true)
          setOperators([])
          if(selectedCountry) loadOperatorsByCountry(selectedCountry)
          setOperatorsLoading(false)
        }, [selectedCountry])

  return (
    <Box className={classes['rb-gift-container']}>
     <section>
            {(!countriesLoading && countries.length) ? <div className={classes['rb-country-dropdown']}>
            <CountrySelection
             countries={countries}
             countryId={selectedCountry}
             handleCountryChange={handleCountryChange}
             label={<span>Available vendors for<span className="field__label-required" /></span>}
             placeholder="Select a country"
            />
            </div> : <div className={classes['rb-country-dropdown']}><Skeleton width={500} height={48} variant='rectangular'></Skeleton></div>}
        </section>

        {(!operatorsLoading && operators.length) ? <div className={classes['rb-operator']}>
            {operators.slice(0,!viewMore ? 12 : operators.length ).map(operator => (
                <OperatorCardPublic
                  key={operator?.label}
                  label={''}
                  image={operator?.imageUrl}
                  altImage={getImageAlt(operator?.imageUrl)}
                />
            ))}
        </div> : <div className={classes['rb-operator']}>{[...new Array(4)].map((each,index) => <Skeleton key={index} variant='rectangular' width={220} height={150} />)}</div>}
        <Button id='show-more' onClick={() => setViewMore(!viewMore)} className={classes['rb-gift-button']} fullWidth variant='text'>{!viewMore ? "View all" : "Show less"}</Button>
    </Box>
  )
}

export default GiftContainer