import React from 'react'
import Box from '../../../../../remitbee/components/box/Box'
import classes from './ColumnSection.module.scss'
import { useMediaQuery } from '@mui/material'
import theme from '../../../../../remitbee/theme/Theme'
import Image from 'next/image';

const ImageList = ({ logosPerRow = '4', logos }) => {
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.lg}px)`);
    const perRow = parseInt(logosPerRow)

  return (
    <Box className={classes['rb-logos-container']} style={{ gridTemplateColumns: `repeat(${isMobile ? perRow / 2 : perRow}, 1fr)`}}>
      {logos.map((logo, index) => {
        return (
        <Box className={classes['rb-logo-container']} style={{padding: `${logo.padding && logo.padding} !important`}} >
          <Image
            key={index}
            src={logo.src}
            alt={logo.alt || 'image'}
            width={ logo?.width ||  (isMobile ? 64 : 150)}
            height={logo?.height || (isMobile ? 64 :  96)}
        />
        </Box>
        )
      })}
    </Box>
  )
}

export default ImageList