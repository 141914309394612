import React, { useEffect, useState } from 'react'
import Box from '../../../../../remitbee/components/box/Box'
import classes from './ColumnSection.module.scss'
import Typography from '../../../../../remitbee/components/typography/Typography'
import Markdown from 'markdown-to-jsx';
import { fetchSystemCountries } from '../../../../../settings/cms-data';
import { useSelector } from 'react-redux';
import Flag from '../../../../../remitbee/components/flag/Flag';
import theme from '../../../../../remitbee/theme/Theme';
import { snackbar } from '../../../../../remitbee/components/snackbar/SnackBar';
import { useMediaQuery } from '@mui/material';
import Button from '../../../../../remitbee/components/button/Button';


const CustomBoxList = ({ type, list = [], actionsAvailable, storyBookMockData }) => {
    const [fullList, setFullList] = useState(list);
    const [shortList, setShortList] = useState([]);
    const [showAll, setShowAll] = useState(false);
    const { countries } = useSelector((state:any) => state?.system);
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.lg}px)`)

    const fecthCountries = async () => {
        try {
        const systemCountries = !countries?.length ? await fetchSystemCountries() : { rates: countries };
        if (systemCountries) {
          const routedAddedList = systemCountries.rates.map((country: any) => {
            const country_to = country?.country_to?.toLowerCase().replaceAll(/ /g, '-');
            return {
              ...country,
              onClick: `/send-money-to-${country_to}`
            }
          })  
          const listData = Array.isArray(routedAddedList) && routedAddedList.length > 0
          ? routedAddedList.filter(item => item?.currency_code !== 'USD')
          : [];
          setFullList((listData));
          setShortList(listData?.slice(0, isMobile ? 8 : 16));
        }   
        } catch (error) {
            snackbar.error('Error fetching countries', 2000)
        }
      }

      const renderCardData = () => {
        if(type === 'countryBoxList' && !storyBookMockData) fecthCountries();
        else if(type === 'countryBoxList' && storyBookMockData) {
            const mockData= storyBookMockData?.filter(item => item?.currency_code !== 'USD')
            setFullList(mockData);
            setShortList(mockData.slice(0, isMobile ? 8 : 16));
        }
        else {
            setFullList(list)
            setShortList(list.slice(0, isMobile ? 8 : 16))
        }
      }

    useEffect(() => {
        renderCardData()
    }, [isMobile]);

    const handleClick = () => {
        setShowAll(!showAll);
        setShortList(showAll ? fullList.slice(0, isMobile ? 8 : 16) : fullList)
    }


    return (
        <>
        <Box className={classes['rb-cards-container']}>
            {type !== 'countryBoxList' ? shortList.map((tag: any, index: number) => (
                <div onClick={tag?.onClick ? () => window.open(tag?.onClick) : null} key={index} className={classes['rb-card-container']} style={{ cursor: tag?.onClick ? 'pointer' : '' }}>
                    <Typography variant={tag?.textVariant} color={tag?.textColor} weight='medium'>
                        <Markdown>{tag?.text}</Markdown>
                    </Typography>
            </div>))
            :
            shortList.map((tag: any, index: number) => (
                <div onClick={tag?.onClick ? () => window.open(tag?.onClick) : null} key={index} className={classes['rb-card-container']} style={{ cursor: tag?.onClick ? 'pointer' : '' }}>
                    <Flag width={36} height={24} code={tag?.iso2} />
                    <Typography variant='textMd' color={theme.palette.primary.navy} weight='medium'>
                        {tag?.country_to}
                    </Typography>
                </div>))
            }
        </Box>
        {!actionsAvailable && fullList.length > 12 && <Box mt={isMobile ? 10 : 12} display='flex' justifyContent='center' alignItems='center'>
          <Button fullWidth={isMobile} className={classes['rb-button-regular-customBoxList']} variant='outlined' onClick={handleClick} id='show-all'>
            {showAll ? 'Show Less' : 'Show All'}
          </Button>
        </Box>}
        </>
    )
}

export default CustomBoxList